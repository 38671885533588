const waitFor = (delay = 0) => new Promise((resolve) => setTimeout(resolve, delay));

const recursiveToZero = (func: () => Promise<any>, attempt: number, delay: number, error: undefined) => {
  if (attempt === 0) {
    throw error || new Error('Fail');
  }

  return func().catch((err) => waitFor(delay).then(() => recursiveToZero(func, attempt - 1, delay, err)));
};

export const recursive = (func: () => Promise<any>, attempts = 5, delay = 3000) =>
  // @ts-ignore
  Promise.resolve().then(() => recursiveToZero(func, attempts, delay));
