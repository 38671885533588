import React, { Suspense, lazy, useEffect } from 'react'
import { addLocaleData, IntlProvider } from 'react-intl'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DmLayoutApp from '@deliverymates/dm-ui/Layout/App'
import ReactGA from 'react-ga'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import it from 'react-intl/locale-data/it'
import { receivedFrontEndChannelSocket as receivedFrontEndChannelSocketAction } from 'Notifications/actions'
import Loading from 'components/atoms/LoadingFull'
import DailyPreoders from 'Pages/Admin/Companies/Detail/Reports/DaylyPreorders'
import { useAuthContext } from 'hooks/auth'
import { useSettingsContext } from 'hooks/settings'
import Snackbar from 'Snackbar'
import messages from 'config/messages'
import Tracking from 'App/Tracking'
import { analytics } from 'lib/fullstory'
import { roleStr } from 'lib/auth'

const AuthPage = lazy(() => import('../Pages/Auth'))
const AdminPage = lazy(() => import('../Pages/Admin'))
const OrderTrackingPage = lazy(() => import('Pages/OrderTracking'))

addLocaleData([...en, ...es, ...it])

const App = ({ receivedFrontEndChannelSocket }) => {
  ReactGA.initialize('UA-105100582-10')
  // return null
  const { me } = useAuthContext()
  const { settings } = useSettingsContext()

  // const connectToFrontendChannel = (token) => {
  //   return null;
  // };

  // const connectToEventsChannel = (token) => {
  //   return null;
  // };

  // const setListener = (channel, token) => {
  //   if (channel === 'events_messages') {
  //     connectToEventsChannel(token);
  //   }

  //   if (channel === 'frontend_updates') {
  //     connectToFrontendChannel(token);
  //   }
  // };

  useEffect(() => {
    if (me) {
      // setListener('events_messages', me.app_token);
      // setListener('frontend_updates', me.app_token);
      if (process.env.NODE_ENV === 'production') {
        ReactGA.set({ user_id: me._id })
        ReactGA.set({ js: new Date() })
        analytics.identify(me.app_token, {
          displayName: `${me.first_name} ${me.last_name}`,
          email: me.email
        })
      }
    }
  }, [me])

  return (
    <IntlProvider locale={settings.language} messages={messages[settings.language]}>
      {/* <DmLayoutApp style={{overflow: 'hidden'}}> */}
      <DmLayoutApp>
        <Suspense fallback={<Loading />}>
          <Snackbar />
          <Tracking />
          <Switch>
            <Route path="/reports/company/:id/daily-preorders" component={DailyPreoders} />
            <Route path="/auth" render={(props: { [key: string]: any }) => <AuthPage {...props} />} />
            <Route path="/admin" render={(props: { [key: string]: any }) => <AdminPage {...props} />} />
            <Route path="/track/:company_id/:external_id" exact render={(props) => <OrderTrackingPage {...props} />} />
            <Route path="/" exact render={() => <Redirect to="/auth" />} />
            <Route render={() => <div>{'Not found!'}</div>} />
          </Switch>
        </Suspense>
      </DmLayoutApp>
    </IntlProvider>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      receivedFrontEndChannelSocket: receivedFrontEndChannelSocketAction
    },
    dispatch
  )

const withRedux = connect(null, mapDispatchToProps)

export default withRedux(App)
