export enum MapFilterTypes {
  SET_COMPANY = 'SET_COMPANY',
  SET_WAY_POINT = 'SET_WAY_POINT',
  SET_DRIVERS = 'SET_DRIVERS',
  SET_ORDERS = 'SET_ORDERS',
  SET_ACTIVE_ORDER_GROUP_NAMES = 'SET_ACTIVE_ORDER_GROUP_NAMES',
  SET_ACTIVE_ORDER_GROUPS = 'SET_ACTIVE_ORDER_GROUPS',
  SET_DRIVERS_FILTER = 'SET_DRIVERS_FILTER'
}

export enum OrderGroupKeys {
  ALL = 'all_orders',
  OPEN = 'open',
  ON_COURSE = 'on-course'
}
