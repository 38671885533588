import updateObject from '../../lib/updateObject';

import * as actionTypes from './types';

const initialState = {
  searching: false,
  items: [],
  searchResults: [],
  pagination: {},
};

const saveSearchResultLocation = (state, { item }) =>
  updateObject(state, {
    searchResults: state.searchResults.concat(item),
  });

const fetchingSearchLocation = (state) =>
  updateObject(state, {
    searching: true,
    searchResults: [],
  });

const fetchSearchLocationOk = (state, { items }) =>
  updateObject(state, {
    searching: false,
    items,
  });

const fetchSearchLocationError = (state) =>
  updateObject(state, {
    searching: false,
  });

const fetchLocationOk = (state, { item }) =>
  updateObject(state, {
    [item._id]: item,
  });

const Locations = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SAVE_SEARCH_RESULT_LOCATION:
      return saveSearchResultLocation(state, payload);
    case actionTypes.FETCHING_SEARCH_LOCATION:
      return fetchingSearchLocation(state);
    case actionTypes.FETCH_SEARCH_LOCATION_OK:
      return fetchSearchLocationOk(state, payload);
    case actionTypes.FETCH_SEARCH_LOCATION_ERROR:
      return fetchSearchLocationError(state);
    case actionTypes.FETCH_LOCATION_OK:
      return fetchLocationOk(state, payload);
    default:
      return state;
  }
};

export default Locations;
