import React from 'react';

import CircularProgress from 'material-ui/CircularProgress';

import { FormattedMessage } from 'react-intl';

const LoadingFull = ():JSX.Element => (
  <div className="loadingPage">
    <FormattedMessage id="loading_loading" tagName="h1" />

    <CircularProgress size={80} thickness={5} />
  </div>
);

export default LoadingFull;
