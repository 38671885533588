// @ts-nocheck
// TODO fix
import React, { CSSProperties } from 'react';

import axios from 'axios';
import config from 'config';

import DmButton from '@deliverymates/dm-ui/Button';
import { MdArrowDownward } from 'react-icons/md';

interface ICompaniesDetailReportsDaylyPreordersProps {
  id: string;
  dmButtonStyles: CSSProperties;
  btnDownloadStyles: CSSProperties;
  refetch: () => void;
}

const CompaniesDetailReportsDaylyPreorders = ({
  id,
  dmButtonStyles,
  btnDownloadStyles,
  refetch,
}: ICompaniesDetailReportsDaylyPreordersProps): JSX.Element => {
  // const [content, setContent] = useState([])

  const downloadCsv = async () => {
    axios.get(`${config.API_URL}${config.VERSION_PATH}/companies/${id}/export?type=pre_orders`).then(async (result) => {
      await axios({
        url: 'data:text/csv;charset=utf-8,' + encodeURI(result.data),
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' },
        responseType: 'blob', // important
      });
      console.log('DATA: ', result.data);
      refetch();
    });
  };

  return (
    <>
      <DmButton size="sm" color="primary" onClick={downloadCsv} style={dmButtonStyles}>
        <MdArrowDownward style={btnDownloadStyles} />
      </DmButton>
    </>
  );
};

export default CompaniesDetailReportsDaylyPreorders;
