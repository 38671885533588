export default {
  'Appbar-Cities': 'città',
  'Appbar-Companies': 'Aziende',
  'Appbar-Customers': 'Clienti',
  'Appbar-Exceptions': 'Eccezioni',
  'Appbar-Exceptions Groups': 'Gruppi di eccezioni',
  'Appbar-Market Sectors': 'Settori di mercato',
  'Appbar-My service areas': 'Le mie aree di servizio',
  'Appbar-Orders': 'Ordini',
  'Appbar-Places': 'Siti',
  'Appbar-Users-dispatchers': 'Utenti / spedizionieri',
  'Appbar-Users-drivers': 'Utenti / autisti',
  'Appbar-Users-observers': 'Utenti / osservatori',
  'button-form_cancel': 'Annulla',
  'button-form_create': 'Crea',
  'button-form_delete': 'Cancella',
  'button-form_update': 'Aggiorna',
  'button-label_back-to-login': 'Torna al login',
  'button-label_lost-password': 'Password smarrita',
  'button-label_new-password': 'Nuova password',
  'button-label_sign-in': 'registrazione',
  button_delete: 'Elimina',
  button_edit: 'Modifica',
  'button_no-cancel': 'No, annulla',
  'button_yes-delete': 'Sì, elimina',
  'chat-hint_write-text': 'Scrivi un testo',
  'chat_no-messages': 'Ancora nessun messaggio',
  cities_companies: 'Aziende',
  cities_customers: 'Clienti',
  'cities_lat-long': 'Coordinate',
  'cities_service-areas': 'Aree di servizio',
  cities_sites: 'Luoghi',
  'cities_update-waypoint': 'Aggiorna Tappa Intermedia',
  cities_users: 'Utenti',
  cities_waypoints: 'Tappe intermedie',
  'city-areas-admin_add-admin': 'Aggiungi amministratore',
  'city-areas-dropdown_select-service-area': "Seleziona un'Area Servizio",
  'city-areas-form-floating-label_area-coordinates': 'Coordinate Area *',
  'city-areas-form-floating-label_name': 'Nome *',
  'city-areas-form-hint_area-coordinates': 'Coordinate Area',
  'city-areas-form-hint_name': 'Nome',
  'city-areas-title_create-service-area': 'Crea Area Servizio',
  'city-areas-title_service-area-info': 'Informazioni area servizio',
  'city-areas-title_update-service-area': 'Aggiorna Area Servizio',
  'city-areas-tooltip_details': 'Dettagli',
  'city-areas-tooltip_places': 'Siti',
  'city-areas-tooltip_waypoints': 'Tappe intermedie',
  'city-areas_action-add': 'Aggiungi',
  'city-areas_action-close': 'Chiudi',
  'city-areas_action-delete': 'Elimina',
  'city-areas_drop-upload': 'Trascina qui i file, oppure clicca per selezionare i file da caricare.',
  'city-counters_companies': 'Aziende',
  'city-counters_customers': 'Clienti',
  'city-counters_places': 'Siti',
  'city-counters_service-areas': 'Aree di servizio',
  'city-counters_users': 'Utenti',
  'city-counters_waypoints': 'Tappe intermedie',
  'city-form-floating-label_location': 'Luogo',
  'city-form-floating-label_name': 'Nome *',
  'city-form-floating-label_search': 'Cerca',
  'city-form-hint_name': 'Nome',
  'city-form-hint_search': 'Cerca',
  'city-header_city-information': 'Informazioni sulla città',
  'city-tabs_areas': 'Aree',
  'city-tabs_edit': 'Modifica',
  'city-tabs_info': 'Informazioni',
  'city-tabs_waypoints': 'Tappe intermedie',
  'city-th_name': 'Nome',
  'city-wp-form-floating-label_description': 'Descrizione waypoint',
  'city-wp-form-floating-label_name': 'Nome *',
  'city-wp-form-hint_description': 'Tappa intermedia',
  'city-wp-form-hint_name': 'Nome',
  'city-wp-info-exception-groups': 'Gruppi di eccezioni',
  'city-wp-info_dedicated-places': 'Siti Dedicati',
  'city-wp-info_exceptions': 'Eccezioni',
  'city-wp-tabs_exceptions': 'Eccezioni',
  'city-wp-tabs_info': 'Informazioni',
  'city-wp-tabs_places': 'Siti',
  'city-wp-tabs_shifts': 'Turni',
  'city-wp_create-shift': 'Crea Shift',
  'city-wp_waypoint-information': 'Informazioni sulla tappa intermedia',
  city_cities: 'Città',
  'city_city-information': 'Informazioni sulla città',
  'city_create-new-city': 'Crea una nuova città',
  'companies-form-floating-label_address-line1': 'Riga Indirizzo 1',
  'companies-form-floating-label_address-line2': 'Informazioni aggiuntive indirizzo (appartamento, numero interno)',
  'companies-form-floating-label_contact-email': 'Email',
  'companies-form-floating-label_contact-name': 'Nome contatto',
  'companies-form-floating-label_cost-per-mile': 'Costo x miglio',
  'companies-form-floating-label_delivery-fee': 'Tariffa per la consegna',
  'companies-form-floating-label_dispatcher_policy': 'Policy mittente',
  'companies-form-floating-label_key': 'Chiave',
  'companies-form-floating-label_market-sector': 'Settore di mercato',
  'companies-form-floating-label_mobile-number': 'Cellulare',
  'companies-form-floating-label_name': 'Nome',
  'companies-form-floating-label_phone-number': 'Numero di telefono',
  'companies-form-floating-label_pick-address': 'Seleziona indirizzo',
  'companies-form-floating-label_postal-code': 'Codice postale *',
  'companies-form-hint_contact-email': 'Email',
  'companies-form-hint_contact-name': 'Nome contatto',
  'companies-form-hint_cost-per-mile': 'Costo x miglio',
  'companies-form-hint_delivery-fee': 'Tariffa per la consegna',
  'companies-form-hint_key': 'Chiave',
  'companies-form-hint_market-sector': 'Settore di mercato',
  'companies-form-hint_mobile-number': 'Cellulare',
  'companies-form-hint_name': 'Nome',
  'companies-form-hint_phone-number': 'Numero di telefono',
  'companies-form-hint_postal-code': 'Codice postale',
  'companies-form_dispatcher-multi': 'Multi',
  'companies-form_dispatcher-single': 'Singola',
  'companies-form_select_one': 'Selezionane uno',
  'companies-keys': 'Impostazioni Azienda',
  'companies-tab_info': 'Informazioni',
  'companies-tab_logo': 'Logo',
  'companies-th_contact': 'Contatti',
  'companies-th_dispatcher_policy_detail': 'Policy mittente',
  'companies-th_name': 'Nome',
  'companies-th_phone': 'Telefono',
  'companies-title_companies': 'Aziende',
  'companies-title_costs': 'Costi',
  'companies-title_info': 'Informazioni azienda',
  'companies-title_new-company': 'Nuova Azienda',
  'companies-title_update-company': 'Aggiorna Azienda',
  'companies-view_address': 'Indirizzo',
  'companies-view_contact': 'Contatti',
  'companies-view_cost-per-mile': 'Costo x miglio',
  'companies-view_delivery-fee': 'Tariffa per la consegna',
  'companies-view_dispatcher_policy_default': 'Policy mittente',
  'companies-view_market': 'Market: ',
  'companies-view_middleware': 'Middleware',
  'companies-view_middleware-port': 'Porta Middleware: ',
  'companies-view_middleware-token': 'Token Middleware: ',
  'companies-view_middleware-url': 'URL Middleware: ',
  companies_tabs_info: 'Informazioni',
  companies_tabs_map: 'Mappa',
  companies_tabs_middleware: 'Middleware',
  companies_tabs_picture: 'Logo',
  companies_tabs_settings: 'Impostazioni',
  'customers-form-floating-label_address-line1': 'Riga Indirizzo 1',
  'customers-form-floating-label_address-line2': 'Informazioni aggiuntive indirizzo (appartamento, numero interno)',
  'customers-form-floating-label_country': 'Paese',
  'customers-form-floating-label_email': 'Email *',
  'customers-form-floating-label_first-name': 'Nome *',
  'customers-form-floating-label_last-name': 'Cognome *',
  'customers-form-floating-label_mobile-number': 'Cellulare *',
  'customers-form-floating-label_payment-mode': 'Modalità di pagamento *',
  'customers-form-floating-label_phone-number': 'Numero di telefono',
  'customers-form-floating-label_pick-address': 'Seleziona indirizzo',
  'customers-form-floating-label_postal-code': 'Codice postale *',
  'customers-form-hint_email': 'Email',
  'customers-form-hint_first-name': 'Nome',
  'customers-form-hint_last-name': 'Cognome',
  'customers-form-hint_mobile-number': 'Cellulare',
  'customers-form-hint_payment-mode': 'Modalità di pagamento',
  'customers-form-hint_phone-number': 'Numero di telefono',
  'customers-form-hint_postal-code': 'Codice postale',
  'customers-form_brasil': 'Brasile',
  'customers-form_cash': 'Contanti',
  'customers-form_deferred': 'Account',
  'customers-form_england': 'Inghilterra',
  'customers-form_españa': 'Spagna',
  'customers-form_select_one': 'Selezionane uno',
  'customers-th_email': 'Email',
  'customers-th_name': 'Nome',
  'customers-title_contact-information': 'Informazioni di contatto',
  'customers-title_customers': 'Clienti',
  'customers-title_new-customer': 'Crea Nuovo Cliente',
  'customers-title_personal-information': 'Informazioni personali',
  'customers-view_address': 'Indirizzo',
  'customers-view_phone-numbers': 'Numeri di telefono',
  'dialog_are-you-sure': 'Sei sicuro? Stai per eliminare questa voce',
  'dialog_confirm-delete': 'Conferma eliminazione',
  'drawer-right-tabs_assigned': 'Assegnato',
  'drawer-right-tabs_assigned_food': 'Assegnato',
  'drawer-right-tabs_assigned_food-informal': 'Assegnato',
  'drawer-right-tabs_assigned_generic': 'Assegnato',
  'drawer-right-tabs_assigned_pharmacy-informal': 'Assegnato',
  'drawer-right-tabs_cancelled': 'Annullato',
  'drawer-right-tabs_delivery-arrived': 'Consegna arrivata',
  'drawer-right-tabs_delivery-arrived_food': 'Consegna arrivata',
  'drawer-right-tabs_delivery-arrived_food-informal': 'Consegna arrivata',
  'drawer-right-tabs_delivery-arrived_generic': 'Consegna arrivata',
  'drawer-right-tabs_delivery-arrived_pharmacy-informal': 'Consegna arrivata',
  'drawer-right-tabs_delivery-done': 'Consegna terminata',
  'drawer-right-tabs_delivery-done_food': 'Consegna terminata',
  'drawer-right-tabs_delivery-done_food-informal': 'Consegna terminata',
  'drawer-right-tabs_delivery-done_generic': 'Consegna terminata',
  'drawer-right-tabs_delivery-done_pharmacy-informal': 'Consegna terminata',
  'drawer-right-tabs_delivery-started': 'Consegna avviata',
  'drawer-right-tabs_delivery-started_food': 'Consegna avviata',
  'drawer-right-tabs_delivery-started_food-informal': 'Consegna avviata',
  'drawer-right-tabs_delivery-started_generic': 'Consegna avviata',
  'drawer-right-tabs_delivery-started_pharmacy-informal': 'Consegna avviata',
  'drawer-right-tabs_on-hold': 'In sospeso',
  'drawer-right-tabs_on-hold_food': 'Azione Richiesta',
  'drawer-right-tabs_on-hold_food-informal': 'In sospeso',
  'drawer-right-tabs_on-hold_generic\r\n': 'Azione Richiesta',
  'drawer-right-tabs_on-hold_pharmacy-informal': 'In sospeso',
  'drawer-right-tabs_open': 'Apri',
  'drawer-right-tabs_open_food': 'Apri',
  'drawer-right-tabs_open_food-informal': 'Apri',
  'drawer-right-tabs_open_generic': 'Apri',
  'drawer-right-tabs_open_pharmacy-informal': 'Apri',
  'drawer-right-tabs_pickup-arrived': 'Ritiro arrivato',
  'drawer-right-tabs_pickup-arrived_food': 'Ritiro arrivato',
  'drawer-right-tabs_pickup-arrived_food-informal': 'Ritiro arrivato',
  'drawer-right-tabs_pickup-arrived_generic': 'Ritiro arrivato',
  'drawer-right-tabs_pickup-arrived_pharmacy-informal': 'Ritiro arrivato',
  'drawer-right-tabs_pickup-done': 'Ritiro terminato',
  'drawer-right-tabs_pickup-done_food': 'Ritiro terminato',
  'drawer-right-tabs_pickup-done_food-informal': 'Ritiro terminato',
  'drawer-right-tabs_pickup-done_generic': 'Ritiro terminato',
  'drawer-right-tabs_pickup-done_pharmacy-informal': 'Ritiro terminato',
  'drawer-right-tabs_pickup-started': 'Ritiro avviato',
  'drawer-right-tabs_pickup-started_food': 'Ritiro avviato',
  'drawer-right-tabs_pickup-started_food-informal': 'Ritiro avviato',
  'drawer-right-tabs_pickup-started_generic': 'Ritiro avviato',
  'drawer-right-tabs_pickup-started_pharmacy-informal': 'Ritiro avviato',
  'drawer-right-title_alerts': 'Avvisi',
  'drawer-right-title_orders': 'Ordini',
  'drawer-right-title_orders-status': "Stato dell'ordine",
  'drawer-right_all': 'Tutto',
  'drawer-right_attached-in': 'Allegato',
  'drawer-right_clocked-in': 'Entrata timbrata',
  'drawer-right_drivers': 'Autisti',
  'drawer-right_filter-drivers-by': 'Filtra autisti per',
  'drawer-right_filtered': 'Filtrato',
  'drawer-right_orders': 'Ordini',
  'drawer-right_shifted-drivers': 'Spostato',
  'drawer-right_total-drivers': 'Autisti totali',
  drawer_cities: 'Città',
  drawer_companies: 'Aziende',
  drawer_customers: 'Clienti',
  drawer_exceptions: 'Eccezioni',
  'drawer_exceptions-group': 'Gruppi',
  'drawer_exceptions-list': 'Elenco',
  'drawer_market-sectors': 'Settori di mercato',
  drawer_orders: 'Ordini',
  drawer_places: 'Siti',
  drawer_routes: 'Percorsi',
  drawer_shifts: 'Turni',
  drawer_tickets: 'Ricevute',
  drawer_users: 'Utenti',
  'drawer_users-dispatchers': 'Spedizionieri',
  'drawer_users-drivers': 'Autisti',
  'drawer_users-observers': 'Osservatori',
  'drivers_clocked-in': 'In ingresso',
  'drivers_clocked-out': 'In uscita',
  'drivers_clocked-scheduled': 'Programmato',
  'drivers_no-shifted': 'Non cambiato al momento',
  error_login: 'Errore login',
  events_messages_message: 'Messaggio',
  events_messages_order: 'Ordine',
  events_messages_sentAt: 'Inviato alle',
  'exception-form-floating-label_description': 'Descrizione',
  'exception-form-floating-label_name': 'Nome *',
  'exception-form-floating-label_type': 'Tipo',
  'exception-form-hint_description': 'Descrizione',
  'exception-form-hint_name': 'Nome',
  'exception-form-hint_type': 'Tipo',
  'exception-group-form-floating-label_description': 'Descrizione *',
  'exception-group-form-floating-label_name': 'Nome *',
  'exception-group-form-hint_description': 'Descrizione',
  'exception-group-form-hint_name': 'Nome',
  'exception-group-tabs_assign': 'Assegna',
  'exception-group-tabs_create': 'Crea',
  'exception-group-tabs_info': 'Informazioni',
  'exception-group-th_description': 'Descrizione',
  'exception-group-th_name': 'Nome',
  'exception-group-title_edit-exception-group': 'Modifica Gruppo di Eccezioni',
  'exception-group-title_exceptions': 'Eccezioni',
  'exception-group-title_exceptions-groups': 'Gruppi di eccezioni',
  'exception-group-title_exceptions-including-in': 'Eccezioni incluse in',
  'exception-group-title_group-information': 'Informazioni gruppo',
  'exception-group-title_group_description': 'Descrizione Gruppo',
  'exception-group-title_new-exception': 'Crea Nuovo Gruppo di Eccezioni',
  'exception-th_description': 'Descrizione',
  'exception-th_name': 'Nome',
  'exception-th_type': 'Tipo',
  'exception-title_edit-exception': 'Modifica Eccezione',
  'exception-title_exception-information': 'Informazioni eccezioni',
  'exception-title_exceptions': 'Eccezioni',
  'exception-title_new-exception': 'Crea Nuova Eccezione',
  'exception-type_badge': 'badge',
  'exception-type_bag': 'borsa',
  'exception-type_box': 'scatola',
  'exception-type_jacket': 'giacca',
  'exception-type_shirt': 'camicia',
  'exception-type_training': 'training',
  'exception-view_description': 'Descrizione',
  'exception-view_type': 'Tipo',
  'form-floating-label_company': 'Azienda',
  'form-floating-label_country': 'Paese',
  'form-floating-label_email': 'Email',
  'form-floating-label_password': 'Password',
  'form-hint_email': 'Email',
  'form-hint_password': 'Password',
  'geo-warning-address_not_found': 'Indirizzo non trovato',
  'geo-warning-cannot_infer_post_code': 'CAP mancante',
  'geo-warning-low_accuracy_coords': 'Coordinate imprecise',
  'geo-warning-no_payload_for_geo': 'Informazioni non sufficienti',
  'geo-warning-postcode_conflict': 'Conflitto CAP',
  'geo-warning-unresolved_coords': 'Impossibile risolvere le coordinate',
  geowarnings_warnings: 'Avvertenze',
  'job-title_edit': 'Modifica',
  'job-title_proof-of-collection': ' Prova di Ritiro',
  'job-title_proof-of-delivery': 'Prova di consegna',
  'job_sent-at': 'Inviata a',
  'job_signature-empty': 'Nessuna firma',
  language_de: 'DE',
  language_en: 'EN',
  language_es: 'ES',
  language_fr: 'FR',
  language_it: 'IT',
  'language_pt-br': 'PT-BR',
  loading_loading: 'Caricamento',
  'location-button_select': 'Seleziona',
  'location-floating-label_filter-by-country': 'Filtra per paese',
  'location-floating-label_search-location': 'Cerca posizione',
  'location-form-floating-label_location': 'Posizione *',
  'location-form-hint_location': 'Posizione',
  'location-hint_filter-by-country': 'Filtra per paese',
  'location-hint_search-location': 'Cerca posizione',
  'location-table-th_class': 'Classe',
  'location-table-th_code': 'Codice',
  'location-table-th_country': 'Paese',
  'location-table-th_name': 'Nome',
  'login-message_session-expired': 'Sessione scaduta, per favore accedi di nuovo',
  'market-sectors-form-floating-label_name': 'Nome *',
  'market-sectors-form-hint_name': 'Nome',
  'market-sectors-th_name': 'Nome',
  'market-sectors-title_edit-market-sector': 'Modifica settore di mercato',
  'market-sectors-title_market-sector-information': 'Informazioni sul settore di mercato',
  'market-sectors-title_market-sectors': 'Settori di mercato',
  'market-sectors-title_new-market-sector': 'Crea nuovo settore di mercato',
  max_dispatch_iterations: 'Iterazioni massime di consegna',
  'modal-order-change-waypoint-title': 'Cambia tappa',
  'modal-reset_order_error': "Errore di ripristino dell'ordine",
  'modal-reset_order-option_automatic': 'Spedizione automatica',
  'modal-reset_order-option_manual': 'Spedizione manuale',
  'modal-reset_order_option_pre-orders': "L'ordine è passato a preordini",
  'modal-reset_order-description':
    "Stai per ripristinare l'ordine attuale.\nSeleziona come desideri spedire quest'ordine dopo averlo ripristinato, oppure annulla l'operazione di ripristino.",
  'modal-reset_order-title': 'Ripristina ordine',
  'modal-update-pickup-ask-for-confirmation-different-post-code':
    'Il codice postale indicato non corrisponde a quello fisso.',
  'modal-update-pickup-ask-for-confirmation-far-away':
    "La distanza tra l'indirizzo attuale e l'indirizzo fornito in origine è",
  'modal-update-pickup-ask-for-confirmation-same-address': "L'indirizzo non è stato cambiato.",
  'modal-update-pickup-ask-for-confirmation-title': 'Vuoi procedere?',
  'msg-th_date-time': 'Data',
  'msg-th_msg': 'Messaggio',
  'msg-th_order_assigned_to': 'Assegnato a',
  'msg-th_order_id': 'ID ordine',
  'msg-th_order_status': 'Status ordine',
  'msg-th_sent_by': 'Inviato da',
  'my-areas': 'Le tue aree di servizio',
  'navbar-menu_log-out': 'Esci',
  'navbar-menu_my-profile': 'Il mio profilo',
  'navbar-menu_settings': 'Impostazioni',
  'navbar-tooltip_notifications': 'Notifiche',
  'notifications-all': 'Tutte le notifiche',
  'notifications-button_view-all': 'Visualizza Tutto',
  'notifications-critical': 'Criticità',
  'notifications-th_alert': 'Avviso',
  'notifications-th_assigned-to': 'Assegnato a',
  'notifications-th_created-at': 'Creato alle',
  'notifications-th_description': 'Descrizione',
  'notifications-th_driver': 'Autista',
  'notifications-th_message': 'Messaggio',
  'notifications-th_order': 'Ordine',
  'notifications-th_sent-at': 'Inviato a',
  'notifications-th_status': 'Stato',
  'notifications-warnings': 'Avvertenze',
  'notifications_already-assigned': 'Già assegnato',
  notifications_attend: 'Partecipa',
  'notifications_see-all': 'Vedi tutte le notifiche',
  'notifications_view-order': 'Visualizza ordine',
  'order-status-archived': 'Archiviato',
  'order-status-archived_food': 'Archiviato',
  'order-status-archived_food-informal': 'Archiviato',
  'order-status-archived_generic': 'Archiviato',
  'order-status-archived_pharmacy-informal': 'Archiviato',
  'order-status-bar-done': 'Fatto',
  'order-status-bar-on_course': 'Sulla rotta',
  'order-status-bar_completed': 'COMPLETATO',
  'order-status-bar_on-route': 'IN TRANSITO',
  'order-status-bar_pending': 'IN SOSPESO',
  'order-status-delivery_arrived': 'Consegna arrivata',
  'order-status-delivery_arrived_food': 'Consegna arrivata',
  'order-status-delivery_arrived_food-informal': 'Consegna arrivata',
  'order-status-delivery_arrived_generic': 'Consegna arrivata',
  'order-status-delivery_arrived_pharmacy-informal': 'Consegna arrivata',
  'order-status-delivery_done': 'Consegna effettuata',
  'order-status-delivery_done_food': 'Consegna effettuata',
  'order-status-delivery_done_food-informal': 'Consegna effettuata',
  'order-status-delivery_done_generic': 'Consegna effettuata',
  'order-status-delivery_done_pharmacy-informal': 'Consegna effettuata',
  'order-status-delivery_started': 'Consegna avviata',
  'order-status-delivery_started_food': 'Consegna avviata',
  'order-status-delivery_started_food-informal': 'Consegna avviata',
  'order-status-delivery_started_generic': 'Consegna avviata',
  'order-status-delivery_started_pharmacy-informal': 'Consegna avviata',
  'order-status-on_hold': 'In attesa',
  'order-status-on_hold_food': 'Azione Richiesta',
  'order-status-on_hold_food-informal': 'In attesa',
  'order-status-on_hold_generic': 'Azione Richiesta',
  'order-status-on_hold_pharmacy-informal': 'In attesa',
  'order-status-order_arrived': 'Ordine arrivato',
  'order-status-order_arrived_food': 'Ordine arrivato',
  'order-status-order_arrived_food-informal': 'Ordine arrivato',
  'order-status-order_arrived_generic': 'Ordine arrivato',
  'order-status-order_arrived_pharmacy-informal': 'Ordine arrivato',
  'order-status-order_assigned': 'Ordine assegnato',
  'order-status-order_assigned_food': 'Ordine assegnato',
  'order-status-order_assigned_food-informal': 'Ordine assegnato',
  'order-status-order_assigned_generic': 'Ordine assegnato',
  'order-status-order_assigned_pharmacy-informal': 'Ordine assegnato',
  'order-status-order_cancelled': 'Annullato',
  'order-status-order_date': 'Data',
  'order-status-order_deadline': 'Scadenza',
  'order-status-order_dispatching': 'Invio ordine in corso',
  'order-status-order_dispatching_food': 'Ordine di spedizione',
  'order-status-order_dispatching_food-informal': 'Ordine di spedizione',
  'order-status-order_dispatching_generic': 'Ordine di spedizione',
  'order-status-order_dispatching_pharmacy-informal': 'Ordine di spedizione',
  'order-status-order_eta': 'ETA',
  'order-status-order_time-window': 'Finestra temporale',
  'order-status-pickup_arrived': 'Ritiro arrivato',
  'order-status-pickup_arrived_food': 'Ritiro arrivato',
  'order-status-pickup_arrived_food-informal': 'Ritiro arrivato',
  'order-status-pickup_arrived_generic': 'Ritiro arrivato',
  'order-status-pickup_arrived_pharmacy-informal': 'Ritiro arrivato',
  'order-status-pickup_done': 'Ritiro effettuato',
  'order-status-pickup_done_food': 'Ritiro effettuato',
  'order-status-pickup_done_food-informal': 'Ritiro effettuato',
  'order-status-pickup_done_generic': 'Ritiro effettuato',
  'order-status-pickup_done_pharmacy-informal': 'Ritiro effettuato',
  'order-status-pickup_started': 'Ritiro avviato',
  'order-status-pickup_started_food': 'Ritiro avviato',
  'order-status-pickup_started_food-informal': 'Ritiro avviato',
  'order-status-pickup_started_generic': 'Ritiro avviato',
  'order-status-pickup_started_pharmacy-informal': 'Ritiro avviato',
  'order-tabs_delivery_food': 'Consegna',
  'order-tabs_delivery_generic': 'Consegna',
  'order-twilio-call-date': 'Data chiamata automatica',
  'order-twilio-call-message': 'Messaggio chiamata automatica',
  'order-warning_distance': 'Limite distanza',
  'orderDetail-error-loading-current-order': 'Ordine non trovato',
  'OrderDetailMap-delivery-place': 'Luogo di Consegna',
  'OrderDetailMap-driver': 'Autista',
  'OrderDetailMap-pickup-place': 'Luogo del Ritiro',
  'orders-actions_cancel-confirm': 'Conferma',
  'orders-actions_completed-confirm': 'Conferma',
  'orders-actions_mark-as-cancelled': 'Contrassegna come annullato',
  'orders-actions_mark-as-completed': 'Contrassegna come completato',
  'orders-actions_reset': 'Reimposta ordine',
  'orders-actions_reset-confirm': 'Conferma prima di reimpostare',
  'orders-assigner-button_assign': 'Assegna',
  'orders-assigner-button_filter-by-name': 'Filtra per nome',
  'orders-assigner-hint_driver-name': 'Nome del fattorino',
  'orders-assigner-th_badge': 'Tesserino',
  'orders-assigner-th_bag': 'Borsa',
  'orders-assigner-th_box': 'Scatola',
  'orders-assigner-th_jaquet': 'Giubbotto',
  'orders-assigner-th_name': 'Nome',
  'orders-assigner-th_shirt': 'Camicia',
  'orders-assigner-th_training': 'Addestramento',
  'orders-assigner_assign-driver': "Assegna autista all'ordine",
  'orders-assigner_no-drivers-assigned': "Nessun autista disponibile in quest'area",
  'orders-basket_final': 'Finale',
  'orders-basket_name': 'Nome',
  'orders-basket_original': 'Originale',
  'orders-basket_quantity': 'Quantità',
  'orders-basket_total-price': 'Prezzo totale',
  'orders-button_waypoint': 'Apri tappa intermedia',
  'orders-deadline': 'Deve essere consegnato entro il',
  'orders-deadline-text': 'Scadenza',
  'orders-deadline-text-list': 'Scadenza',
  'orders-detail_completed': 'Completato',
  'orders-detail_created-at': 'Creato alle',
  'orders-detail_delivery-information': 'Informazioni per la consegna:',
  'orders-detail_estimated': 'Stimato:',
  'orders-detail_on-route': 'In transito',
  'orders-detail_pickup-information': 'Informazioni per il ritiro',
  'orders-driver_assign-driver': 'Assegna conducente',
  'orders-driver_force-manual-selection': 'IMPONI SELEZIONE MANUALE',
  'orders-driver_invitation-expired': 'Invito scaduto',
  'orders-driver_invitation-sent-waiting': 'INVITO INVIATO, IN ATTESA DI ACCETTAZIONE ',
  'orders-driver_invitation-timeout': 'Timeout invito:',
  'orders-driver_unassigned': 'Non assegnato',
  'orders-eta-text': 'ETA',
  'orders-form-floating-label_contact-name': 'Nome contatto',
  'orders-form-floating-label_email': 'Email *',
  'orders-form-floating-label_first-name': 'Nome *',
  'orders-form-floating-label_last-name': 'Cognome *',
  'orders-form-floating-label_mobile': 'Cellulare',
  'orders-form-floating-label_name': 'Nome *',
  'orders-form-floating-label_phone-number': 'Numero di telefono',
  'orders-form-floating-label_place': 'Luogo *',
  'orders-form-hint_contact-name': 'Nome contatto',
  'orders-form-hint_email': 'Email',
  'orders-form-hint_first-name': 'Nome',
  'orders-form-hint_last-name': 'Cognome',
  'orders-form-hint_mobile': 'Cellulare',
  'orders-form-hint_name': 'Nome',
  'orders-form-hint_phone-number': 'Numero di telefono',
  'orders-form-hint_place': 'Luogo',
  'orders-job-form-floating-label_date': 'Data *',
  'orders-job-form-floating-label_time': 'Ora *',
  'orders-job-form-hint_date': 'Data',
  'orders-job-form-hint_time': 'Ora',
  'orders-job_jobs-end-at': 'Il lavoro termina alle',
  'orders-job_jobs-starts-at': 'Il lavoro inizia alle',
  'orders-label_address-errors': 'Errori di indirizzo',
  'orders-label_calls': 'Chiamate Twillio',
  'orders-label_order-is-ready': "L'ordine è pronto",
  'orders-map_delivery': 'Consegna',
  'orders-map_distance': 'Distanza',
  'orders-map_miles': 'Miglia',
  'orders-map_order-ready-for-collection': 'Pronto per il recupero',
  'orders-map_pickup': 'Ritiro',
  'orders-modal_errors': 'Errori',
  'orders-modal_warnings': 'Avvertenze',
  'orders-notif_ready_at': 'Notifica ordine aperto a',
  'orders-push_ready_at': 'Notifica push ordini pronti a',
  'orders-ready': 'Ordine aperto',
  'orders-ready-ack-at': 'Ordine pronto riconosciuto a',
  'orders-ready-at': 'Ordine pronto a',
  'orders-ready_for_pickup': 'Ordine pronto per il ritiro',
  'orders-search-form-btn': 'Cerca',
  'orders-search-form-floating-label_name': 'Cerca per ID o nome ristorante',
  'orders-search-form-hint_name': 'Cerca per ID o nome ristorante',
  'orders-status_accepted': 'Accettato',
  'orders-status_arrived-delivery': 'Consegna arrivata',
  'orders-status_arrived-delivery_current': 'Consegna arrivata',
  'orders-status_arrived-delivery_food': 'Consegna arrivata',
  'orders-status_arrived-delivery_food-informal': 'Consegna arrivata',
  'orders-status_arrived-delivery_generic': 'Consegna arrivata',
  'orders-status_arrived-delivery_pharmacy-informal': 'Consegna arrivata',
  'orders-status_arrived-pickup': 'Ritiro arrivato',
  'orders-status_arrived-pickup_current': 'Ritiro arrivato',
  'orders-status_arrived-pickup_food': 'Ritiro arrivato',
  'orders-status_arrived-pickup_food-informal': 'Ritiro arrivato',
  'orders-status_arrived-pickup_generic': 'Ritiro arrivato',
  'orders-status_arrived-pickup_pharmacy-informal': 'Ritiro arrivato',
  'orders-status_assigned': 'Assegnato',
  'orders-status_assigned_current': 'Assegnato',
  'orders-status_assigned_food': 'Assegnato',
  'orders-status_assigned_food-informal': 'Assegnato',
  'orders-status_assigned_generic': 'Assegnato',
  'orders-status_assigned_pharmacy-informal': 'Assegnato',
  'orders-status_done-delivery': 'Consegna effettuata',
  'orders-status_done-delivery_current': 'Consegna effettuata',
  'orders-status_done-delivery_food': 'Consegna effettuata',
  'orders-status_done-delivery_food-informal': 'Consegna effettuata',
  'orders-status_done-delivery_generic': 'Consegna effettuata',
  'orders-status_done-delivery_pharmacy-informal': 'Consegna effettuata',
  'orders-status_done-pickup': 'Ritiro effettuato',
  'orders-status_done-pickup_current': 'Ritiro effettuato',
  'orders-status_done-pickup_food': 'Ritiro effettuato',
  'orders-status_done-pickup_food-informal': 'Ritiro effettuato',
  'orders-status_done-pickup_generic': 'Ritiro effettuato',
  'orders-status_done-pickup_pharmacy-informal': 'Ritiro effettuato',
  'orders-status_hour': 'Ora',
  'orders-status_open': 'Apri',
  'orders-status_open_current': 'Apri',
  'orders-status_open_food': 'Apri',
  'orders-status_open_food-informal': 'Apri',
  'orders-status_open_generic': 'Apri',
  'orders-status_open_pharmacy-informal': 'Apri',
  'orders-status_start-delivery': 'Avvia consegna',
  'orders-status_start-delivery_current': 'Avvia consegna',
  'orders-status_start-delivery_food': 'Avvia consegna',
  'orders-status_start-delivery_food-informal': 'Avvia consegna',
  'orders-status_start-delivery_generic': 'Avvia consegna',
  'orders-status_start-delivery_pharmacy-informal': 'Avvia consegna',
  'orders-status_start-pickup': 'Avvia ritiro',
  'orders-status_start-pickup_current': 'Avvia ritiro',
  'orders-status_start-pickup_food': 'Avvia ritiro',
  'orders-status_start-pickup_food-informal': 'Avvia ritiro',
  'orders-status_start-pickup_generic': 'Avvia ritiro',
  'orders-status_start-pickup_pharmacy-informal': 'Avvia ritiro',
  'orders-status_status': 'Stato',
  'orders-status_tbs': 'TBS*',
  'orders-status_time-between-status': '* Time between status (tempo tra stati)',
  'orders-status_time-per-event': '** Time per Event (tempo per evento)',
  'orders-status_total-time': 'Tempo totale',
  'orders-status_tpe': 'TPE**',
  'orders-tabs_arrived': 'Arrivato',
  'orders-tabs_arrived_food': 'Arrivato',
  'orders-tabs_arrived_food-informal': 'Arrivato',
  'orders-tabs_arrived_generic': 'Arrivato',
  'orders-tabs_arrived_pharmacy-informal': 'Arrivato',
  'orders-tabs_assigned': 'Assegnato',
  'orders-tabs_assigned_current': 'Assegnato',
  'orders-tabs_assigned_food': 'Assegnato',
  'orders-tabs_assigned_food-informal': 'Assegnato',
  'orders-tabs_assigned_generic': 'Assegnato',
  'orders-tabs_assigned_pharmacy-informal': 'Assegnato',
  'orders-tabs_basket': 'Cesto',
  'orders-tabs_cancelled': 'Annullato',
  'orders-tabs_cancelled_food': 'Annullato',
  'orders-tabs_cancelled_food-informal': 'Annullato',
  'orders-tabs_cancelled_generic': 'Annullato',
  'orders-tabs_cancelled_pharmacy-informal': 'Annullato',
  'orders-tabs_d': 'D. ',
  'orders-tabs_delivery': 'Consegna ',
  'orders-tabs_delivery-arrived': 'Consegna Arrivata',
  'orders-tabs_delivery-arrived_current': 'Consegna Arrivata',
  'orders-tabs_delivery-arrived_food': 'Arrivo Consegna',
  'orders-tabs_delivery-arrived_food-informal': 'Arrivo cliente',
  'orders-tabs_delivery-arrived_generic': 'Arrivo Consegna',
  'orders-tabs_delivery-arrived_pharmacy-informal': 'Arrivo paziente',
  'orders-tabs_delivery-done': 'Consegna Effettuata',
  'orders-tabs_delivery-done_current': 'Consegna Effettuata',
  'orders-tabs_delivery-done_food': 'Consegna Completa',
  'orders-tabs_delivery-done_food-informal': 'Cibo Consegnato',
  'orders-tabs_delivery-done_generic': 'Consegna Completa',
  'orders-tabs_delivery-done_pharmacy-informal': 'Prodotti consegnati',
  'orders-tabs_delivery-started': 'Consegna Iniziata',
  'orders-tabs_delivery-started_current': 'Consegna Iniziata',
  'orders-tabs_delivery-started_food': 'Consegna Iniziata',
  'orders-tabs_delivery-started_food-informal': 'Cliente sul percorso',
  'orders-tabs_delivery-started_generic': 'Consegna Iniziata',
  'orders-tabs_delivery-started_pharmacy-informal': 'Paziente sul percorso',
  'orders-tabs_delivery_food': 'Consegna',
  'orders-tabs_delivery_food-informal': 'Cliente',
  'orders-tabs_delivery_generic': 'Consegna',
  'orders-tabs_delivery_pharmacy-informal': 'Paziente',
  'orders-tabs_done': 'Fatto',
  'orders-tabs_done _food': 'Fatto',
  'orders-tabs_done _generic': 'Fatto',
  'orders-tabs_done-delivery _food-informal': 'Fatto',
  'orders-tabs_done-delivery_food': 'Completo',
  'orders-tabs_done-delivery_food-informal': 'Consegnato',
  'orders-tabs_done-delivery_generic': 'Completo',
  'orders-tabs_done-delivery_pharmacy-informal': 'Fatto',
  'orders-tabs_done-pickup_food': 'Completo',
  'orders-tabs_done-pickup_food-informal': 'Fatto',
  'orders-tabs_done-pickup_generic': 'Completo',
  'orders-tabs_done-pickup_pharmacy-informal': 'Fatto',
  'orders-tabs_driver': 'Autista',
  'orders-tabs_driver-deadline': 'Scadenza',
  'orders-tabs_driver-drops': 'Consegne',
  'orders-tabs_driver-eta': 'ETA',
  'orders-tabs_driver-gps': 'GPS',
  'orders-tabs_driver-gps-off': 'OFF',
  'orders-tabs_driver-gps-on': 'ON',
  'orders-tabs_driver-job-route': 'Itinerario di lavoro',
  'orders-tabs_driver-orders': 'Ordini',
  'orders-tabs_driver-shifts': 'Turno',
  'orders-tabs_log': 'Registro',
  'orders-tabs_messages': 'MSG',
  'orders-tabs_on-hold': 'In sospeso',
  'orders-tabs_on-hold_current': 'In sospeso',
  'orders-tabs_on-hold_food': 'Azione Richiesta',
  'orders-tabs_on-hold_food-informal': 'In sospeso',
  'orders-tabs_on-hold_generic': 'Azione Richiesta',
  'orders-tabs_on-hold_pharmacy-informal': 'In sospeso',
  'orders-tabs_open': 'Apri',
  'orders-tabs_open_current': 'Apri',
  'orders-tabs_open_food': 'Apri',
  'orders-tabs_open_food-informal': 'Apri',
  'orders-tabs_open_generic': 'Apri',
  'orders-tabs_open_pharmacy-informal': 'Apri',
  'orders-tabs_p': 'P. ',
  'orders-tabs_pickup': 'Ritiro ',
  'orders-tabs_pickup-arrived': 'Raccolta Arrivata',
  'orders-tabs_pickup-arrived_current': 'Raccolta Arrivata',
  'orders-tabs_pickup-arrived_food': 'Arrivo Ritiro',
  'orders-tabs_pickup-arrived_food-informal': 'Arrivo ristorante',
  'orders-tabs_pickup-arrived_generic': 'Arrivo Ritiro',
  'orders-tabs_pickup-arrived_pharmacy-informal': 'Arrivo Farmacia',
  'orders-tabs_pickup-done': 'Raccolta Effettuata',
  'orders-tabs_pickup-done_current': 'Raccolta Effettuata',
  'orders-tabs_pickup-done_food': 'Ritiro Completo',
  'orders-tabs_pickup-done_food-informal': 'Cibo ritirato',
  'orders-tabs_pickup-done_generic': 'Ritiro Completo',
  'orders-tabs_pickup-done_pharmacy-informal': 'Prodotti Ritirati',
  'orders-tabs_pickup-started': 'Raccolta Iniziata',
  'orders-tabs_pickup-started_current': 'Raccolta Iniziata',
  'orders-tabs_pickup-started_food': 'Ritiro Iniziato',
  'orders-tabs_pickup-started_food-informal': 'Ristorante lungo il percorso',
  'orders-tabs_pickup-started_generic': 'Ritiro Iniziato',
  'orders-tabs_pickup-started_pharmacy-informal': 'Farmacia lungo il percorso',
  'orders-tabs_pickup_food': 'Ritiro ',
  'orders-tabs_pickup_food-informal': 'Ritiro ',
  'orders-tabs_pickup_generic': 'Ritiro ',
  'orders-tabs_pickup_pharmacy-informal': 'Ritiro ',
  'orders-tabs_positions': 'Posizioni',
  'orders-tabs_search': 'Cerca',
  'orders-tabs_started': 'Avviato',
  'orders-tabs_started_food': 'Avviato',
  'orders-tabs_started_food-informal': 'Avviato',
  'orders-tabs_started_generic': 'Avviato',
  'orders-tabs_started_pharmacy-informal': 'Avviato',
  'orders-tabs_status': 'Stato',
  'orders-tabs_timeline': 'Tabella di marcia',
  'orders-tabs_waypoint': 'Tappa intermedia',
  'orders-th_delay': 'Ritardo',
  'orders-th_delivery-at': 'Consegna alle',
  'orders-th_dispatch_at': 'Spedizione a',
  'orders-th_distance': 'Distanza',
  'orders-th_driver': 'Fattorino',
  'orders-th_id-order': "Dettagli dell'Ordine",
  'orders-th_open-time': 'Orario di Apertura',
  'orders-th_pickup-at': 'Raccolta alle',
  'orders-th_time': 'Ora',
  'orders-time-window-text-list': 'Finestra temporale',
  'orders-time_expected-delivery-at': 'Consegna stimata alle',
  'orders-time_expected-pickup-at': 'Raccolta stimata alle',
  'orders-time_paid-at': 'Pagato alle',
  'orders-title_address': 'Indirizzo',
  'orders-title_address-line1': 'Indirizzo',
  'orders-title_address-line2': 'Numero civico',
  'orders-title_contact-name': 'Nome contatto',
  'orders-title_customer-information': 'Informazioni cliente',
  'orders-title_edit-order': 'Modifica Ordine',
  'orders-title_email': 'Email',
  'orders-title_mobile-phone-number': 'Numero di cellulare',
  'orders-title_name': 'Nome',
  'orders-title_new-order': 'Crea Nuovo Ordine',
  'orders-title_order-information': "Informazioni sull'Ordine",
  'orders-title_phone-number': 'Numero di telefono',
  'orders-title_place-information': 'Informazioni sito',
  'orders-title_postal-code': 'Codice postale',
  orders_late: 'In ritardo',
  'orders_on-time': 'In Orario',
  'order_go-to-driver': 'Vedi Autista',
  'outes-road-th-driver': 'Conducente',
  'page-title_countries': 'Paesi',
  'page-title_dashboard': 'Dashboard',
  'page-title_edit-city-information': 'Modifica informazioni sulla città',
  'page-title_forgot-your-password': 'Password dimenticata',
  'page-title_login': 'Accedi',
  'page-title_map': 'Mappa',
  'page-title_new-order': 'Nuovo ordine',
  pagination_first: 'Primo',
  pagination_last: 'Ultimo',
  'payment-mode-form-floating-label_name': 'Nome *',
  'payment-mode-form-hint_name': 'Nome',
  'payment-mode-th_name': 'Nome',
  'payment-mode-title_edit-payment-mode': 'Modifica metodi di pagamento',
  'payment-mode-title_new-payment-mode': 'Crea nuovo metodo di pagamento',
  'payment-mode-title_payment-modes': 'Metodi di pagamento',
  'places-form-floating-label_address-line1': 'Riga Indirizzo 1',
  'places-form-floating-label_address-line2': 'Informazioni aggiuntive indirizzo (appartamento, numero interno...)',
  'places-form-floating-label_contact-name': 'Nome contatto',
  'places-form-floating-label_dispatcher_policy': 'Policy mittente',
  'places-form-floating-label_email': 'Email *',
  'places-form-floating-label_external_id': 'ID esterno',
  'places-form-floating-label_mobile': 'Cellulare',
  'places-form-floating-label_name': 'Nome *',
  'places-form-floating-label_phone-number': 'Numero di telefono *',
  'places-form-floating-label_pick-address': 'Seleziona indirizzo',
  'places-form-floating-label_postal-code': 'Codice postale *',
  'places-form-hint_contact-name': 'Nome contatto',
  'places-form-hint_email': 'Email',
  'places-form-hint_external_id': 'ID esterno',
  'places-form-hint_mobile': 'Cellulare',
  'places-form-hint_name': 'Nome',
  'places-form-hint_phone-number': 'Numero di telefono',
  'places-form-hint_postal-code': 'Codice postale',
  'places-form_brasil': 'Brasile',
  'places-form_dispatcher-multi': 'Multi',
  'places-form_dispatcher-single': 'Singola',
  'places-form_england': 'Inghilterra',
  'places-form_españa': 'Spagna',
  'places-form_select_one': 'Selezionane una',
  'places-map_no_location': 'Nessuna posizione disponibile...',
  'places-qr_code-disable': 'Disattiva Codice QR',
  'places-qr_code-enable': 'Attiva Codice QR',
  'places-qr_code-none': 'No CodiciQR',
  'places-qr_code-title': 'CodiciQR',
  'places-th_dispatcher_policy': 'Policy mittente',
  'places-th_email': 'Email',
  'places-th_external_id': 'ID esterno',
  'places-th_name': 'Nome',
  'places-th_qrcode': 'Codice QR',
  'places-title_address': 'Indirizzo',
  'places-title_contact': 'Contatti',
  'places-title_dispatcher_policy': 'Policy mittente',
  'places-title_edit-delivery-place': 'Modifica Luogo di Consegna',
  'places-title_edit-pickup-place': 'Modifica Luogo del Ritiro',
  'places-title_edit-place': 'Modifica sito',
  'places-title_new-place': 'Crea nuovo sito',
  'places-title_place-contact-information': 'Informazioni di contatto sito',
  'places-title_place-external_id': 'ID esterno',
  'places-title_place-information': 'Informazioni sito',
  'places-title_places': 'Siti',
  'places_qrcode-none': 'Off',
  'places_qrcode-yes': 'On',
  pod_barcode: 'Codice a barre',
  pod_picture_generic: 'Immagine',
  pod_signature: 'Firma',
  pod_text: 'Testo',
  poc_picture_generic: 'Immagine',
  poc_signature: 'Firma',
  poc_text: 'Testo',
  'position-picker-button_cancel': 'Cancella',
  'position-picker-button_select-this-position': 'Seleziona questa posizione',
  'position-picker-dialog_select-location': 'Seleziona un luogo',
  'position-picker-form-floating-label_address-line1': 'Indirizzo *',
  'position-picker-form-floating-label_address-line2': 'Numero civico *',
  'position-picker-form-floating-label_search-location': 'Cerca posizione',
  'position-picker-form-hint_address-line1': 'Indirizzo',
  'position-picker-form-hint_address-line2': 'Numero civico',
  'position-picker-form-hint_search-location': 'Cerca posizione',
  'pre-orders-title': 'Preordini',
  'route-queue-th_id': 'ID ordine',
  'route-queue-th_remaining-time': 'Ora di iniziare',
  'routes-assign': 'Assegna manualmente',
  'routes-cancelled': 'Annullato',
  'routes-completed': 'Completato',
  'routes-completed-th-completed-delivered': 'Completato/totale',
  'routes-completed-th-driver': 'Conducente',
  'routes-completed-th-not-delivered': 'Ordini non consegnati',
  'routes-completed-th-partial-delivered': 'Consegna parziale',
  'routes-completed-th-pdr': 'PDR',
  'routes-completed-th-removed-parcels': 'Collo rimosso',
  'routes-completed-th-time-window': 'Finestra temporale',
  'routes-completed-th-total-time': 'Tempo totale',
  'routes-completed-th-warehouse': 'Magazzino',
  'routes-completed-th_route-id': 'ID percorso',
  'routes-completed-th-creation-date': 'Creation Date',
  'historical-routes-time': 'Tempo',
  'historical-routes-id': 'ID',
  'historical-routes-plus_route': '+ Itinerari',
  'historical-routes-driver': 'Conducente',
  'historical-routes-attempts': 'Tentativi',
  'historical-routes-route': 'Percorso',
  'routes-drivers': 'Conducenti',
  'routes-on-route': 'OOR',
  'routes-picking': 'UTR',
  'routes-picking-orders': 'Ordini',
  'routes-picking-parcels': 'Colli',
  'routes-pickup-th-alerts': 'Avvisi',
  'routes-pickup-th-driver': 'Conducente',
  'routes-pickup-th-progress': 'Progresso',
  'routes-pickup-th-remaining-time': 'Ora di iniziare',
  'routes-pickup-th-removed': 'Rimosso',
  'routes-pickup-th-warehouse': 'Magazzino',
  'routes-pickup-th_route-id': 'ID percorso',
  'routes-queue': 'Coda',
  'routes-road-th-alerts': 'Parziale',
  'routes-road-th-driver': 'Conducente',
  'routes-road-th-progress': 'Progresso',
  'routes-road-th-removed': 'Non consegnato',
  'routes-road-th-time-window': 'Finestra temporale',
  'routes-road-th-warehouse': 'Magazzino',
  'routes-road-th_route-id': 'ID percorso',
  'routes-title_routes': 'Percorsi',
  routes_tabs_cancelled: 'Annullato',
  routes_tabs_completed: 'Completato',
  'routes_tabs_driver-assignment': 'Assegnazione conducente',
  routes_tabs_drivers: 'Conducenti',
  'routes_tabs_on-queue': 'Ordine in coda',
  routes_tabs_oor: 'OOR(Fuori in viaggio)',
  routes_tabs_utr: 'UTR(Sotto il tetto)',
  'Scheduled now at': 'Programmato ora per',
  search_search: 'Cerca',
  'settings-broadcast_radius-description':
    'Raggio (in metri) per trovare guidatori disponibili in modalità selezione di trasmissione',
  'settings-broadcast_radius-title': 'Raggio di trasmissione',
  'settings-driver_assignment_strategy-description': 'Come assegnare un guidatore ad un ordine',
  'settings-driver_assignment_strategy-option-direct': 'Diretto',
  'settings-driver_assignment_strategy-option-invite': 'Invita',
  'settings-driver_assignment_strategy-title': 'Strategia di assegnazione del guidatore',
  'settings-driver_auto_delivery_arrived_radius-description': 'Metri',
  'settings-driver_auto_delivery_arrived_radius-title': 'Raggio per consegna-arrivato automatico',
  'settings-driver_auto_geo_event_threshold-description': 'Minuti',
  'settings-driver_auto_geo_event_threshold-title': 'Soglia per cambio di stato automatico (minuti)',
  'settings-driver_auto_pickup_arrived_radius-description': 'Metri',
  'settings-driver_auto_pickup_arrived_radius-title': 'Raggio per raccolta-arrivato automatico',
  'settings-driver_selection_strategy-description': 'Strategia di selezione dei guidatori',
  'settings-driver_selection_strategy-option-radius_broadcast': 'raggio_trasmissione',
  'settings-driver_selection_strategy-option-v1_ranking': 'v1_classifica',
  'settings-driver_selection_strategy-option-v2_ranking': 'v2_classifica',
  'settings-driver_selection_strategy-option-way_point_broadcast': 'punti_trasmissione',
  'settings-driver_selection_strategy-title': 'Come selezionare i guidatori disponibili per un ordine',
  'settings-fe_show_preorders_general-description':
    'Nuova opzione nel menù principale: i controllori posono vedere i preordini già esistenti nel sistema ',
  'settings-fe_show_preorders_general-title': 'Mostra preordini',
  'settings-fe_warehouse-description':
    'Nuova opzione nel menu principale dove i controllori possono visualizzare i magazzini e i percorsi',
  'settings-fe_warehouse-title': 'Mostra magazzini e percorsi',
  'settings-invite_timeout-description': 'Tempo (in secondi) per la scadenza di un invito',
  'settings-invite_timeout-title': 'Timeout invito',
  'settings-max_dispatch_iterations-description': 'Somma massima di iterazioni automatiche di consegna',
  'settings-max_dispatch_iterations-title': 'Iterazioni massime di consegna',
  'settings-max_invites-description': 'Numero massimo di inviti in automatico',
  'settings-max_invites-title': 'Massimo inviti',
  'settings-max_orders_per_driver-description': 'Ordini massimi per guidatore',
  'settings-max_orders_per_driver-title': 'Ordini massimi per guidatore',
  'settings-no': 'No',
  'settings-option_mandatory': 'Obbligatoria',
  'settings-option_none': 'Nessuna',
  'settings-option_optional': 'Opzionale',
  'settings-order_status_change_geofencing-description': 'Limite del geofence per cambi di stato',
  'settings-order_status_change_geofencing-option-disabled': 'Disabilitato',
  'settings-order_status_change_geofencing-option-mandatory': 'Obbligatorio',
  'settings-order_status_change_geofencing-option-warning': 'Avviso',
  'settings-order_status_change_geofencing-title': 'Limite del geofence per cambi di stato',
  'settings-order_status_change_geofencing_radius-description': 'Raggio del geofence per cambi di stato',
  'settings-order_status_change_geofencing_radius-title': 'Raggio del geofence per cambi di stato',
  'settings-pod_barcode-description': 'Prova di consegna codice a barre',
  'settings-pod_barcode-option-disabled': 'Disabilitato',
  'settings-pod_barcode-option-mandatory': 'Obbligatorio',
  'settings-pod_barcode-option-optional': 'Facoltativo',
  'settings-pod_barcode-title': 'Codice a barre POD',
  'settings-pod_picture_generic-description': 'Prova di consegna foto generica',
  'settings-pod_picture_generic-option-disabled': 'Disabilitato',
  'settings-pod_picture_generic-option-mandatory': 'Obbligatorio',
  'settings-pod_picture_generic-option-optional': 'Facoltativo',
  'settings-pod_picture_generic-title': 'Foto POD',
  'settings-pod_signature-description': 'Prova di consegna firma',
  'settings-pod_signature-option-disabled': 'Disabilitato',
  'settings-pod_signature-option-mandatory': 'Obbligatorio',
  'settings-pod_signature-option-optional': 'Facoltativo',
  'settings-pod_signature-title': 'Firma POD',
  'settings-pod_text-description': 'Prova di consegna testo',
  'settings-pod_text-option-disabled': 'Disabilitato',
  'settings-pod_text-option-mandatory': 'Obbligatorio',
  'settings-pod_text-option-optional': 'Facoltativo',
  'settings-pod_text-title': 'Testo POD',
  'settings-poc_barcode-description': 'Prova di raccolta codice a barre',
  'settings-poc_barcode-option-disabled': 'Disabilitato',
  'settings-poc_barcode-option-mandatory': 'Obbligatorio',
  'settings-poc_barcode-option-optional': 'Facoltativo',
  'settings-poc_barcode-title': 'Codice a barre POB',
  'settings-poc_picture_generic-description': 'Prova di raccolta foto generica',
  'settings-poc_picture_generic-option-disabled': 'disattivato',
  'settings-poc_picture_generic-option-mandatory': 'obbligatorio',
  'settings-poc_picture_generic-option-optional': 'facoltativo',
  'settings-poc_picture_generic-title': 'POB - Foto generica',
  'settings-poc_signature-description': 'Prova di raccolta firma',
  'settings-poc_signature-option-disabled': 'Disabilitato',
  'settings-poc_signature-option-mandatory': 'Obbligatorio',
  'settings-poc_signature-option-optional': 'facoltativo',
  'settings-poc_signature-title': 'Firma POB',
  'settings-poc_text-description': 'Prova di raccolta testo',
  'settings-poc_text-option-disabled': 'Disabilitato',
  'settings-poc_text-option-mandatory': 'Obbligatorio',
  'settings-poc_text-option-optional': 'Facoltativo',
  'settings-poc_text-title': 'Testo POB',
  'settings-profile_edit': 'Modifica profilo',
  'settings-profile_your-email': 'La tua email',
  'settings-profile_your-name': 'Il tuo nome',
  'settings-profile_your-phone': 'Il tuo telefono',
  'settings-profile_your-role': 'Il tuo ruolo',
  'settings-support_phone_number-description': "Numero di telefono d'assistenza",
  'settings-support_phone_number-title': "Numero di telefono dell'assistenza",
  'settings-tab-general': 'Impostazioni del fornitore di servizio',
  'settings-tab-profile': 'Profilo della scheda delle impostazioni',
  'settings-tab-terms-conditions': 'Termini e condizioni',
  'settings-title_choose-language': 'Seleziona una lingua',
  'settings-title_currency': 'Valuta',
  'settings-title_current-currency': 'Valuta attuale',
  'settings-title_current-language': 'Lingua attuale',
  'settings-title_delivery-type': 'Tipo di consegna',
  'settings-title_dispatching-policy': 'Policy di spedizione',
  'settings-title_distance': 'Distanza',
  'settings-title_distance-type': 'Unità di misura distanza',
  'settings-title_invitation-time': 'Orario di invito',
  'settings-title_invitation-time-text': 'Quanti secondi hanno gli autisti per accettare un ordine?',
  'settings-title_language': 'Lingua',
  'settings-title_profile': 'Il tuo profilo',
  'settings-title_proof-of-collection': 'Prova di ritiro',
  'settings-title_proof-of-delivery': 'Prova di consegna',
  'settings-title_settings': 'Impostazioni',
  'settings-title_time': 'Orario',
  'settings-yes': 'Sì',
  'settings_currency-dollars': 'Dollari',
  'settings_currency-euros': 'Euro',
  'settings_currency-pounds': 'Sterline',
  'settings_dispatching-multi': 'Multi',
  'settings_dispatching-single': 'Singola',
  'settings_distance-km': 'Km',
  'settings_distance-miles': 'Miglia',
  settings_photo: 'Foto',
  settings_signature: 'Firma',
  'shifts-action_delete-shift': 'Cancella turno',
  'shifts-action_edit-shift': 'Modifica turno',
  'shifts-button_search-driver': 'Ricerca fattorino',
  'shifts-form-floating-label_end-date': 'Data di fine *',
  'shifts-form-floating-label_start-date': 'Data di inizio *',
  'shifts-form-floating-label_time': 'Ora *',
  'shifts-form-hint_driver-name': 'Nome del fattorino',
  'shifts-form-hint_end-date': 'Data di fine',
  'shifts-form-hint_start-date': 'Data di inizio',
  'shifts-form-hint_time': 'Ora',
  'shifts-message_no-drivers-assigned': "Nessun fattorino assegnato a quest'area",
  'shifts-switch_current': 'Attuale',
  'shifts-th_assigned': 'Assegnato',
  'shifts-th_clock': 'Stato',
  'shifts-th_clock-in-out': 'Timbratura in uscita/entrata turno',
  'shifts-th_current': 'Attuale',
  'shifts-th_distance': 'Distanza',
  'shifts-th_driver': 'Fattorino',
  'shifts-th_drops': 'Consegne',
  'shifts-th_end': 'Fine',
  'shifts-th_gps': 'GPS',
  'shifts-th_queued': 'In coda',
  'shifts-th_shift': 'Turno',
  'shifts-th_shift-accomplished': 'Turno eseguito',
  'shifts-th_shift_status': 'Cambia status',
  'shifts-th_start': 'Inizio',
  'shifts-th_status': 'Stato',
  'shifts-th_transport': 'Trasporto',
  'shifts-title_new-shift': 'Crea nuovo turno',
  'shifts-title_shifts': 'Turni',
  'shifts-title_update-shift': 'Aggiorna turno',
  shifts_off: 'OFF',
  shifts_on: 'ON',
  'tickets-button_attend': 'Partecipa',
  'tickets-button_view-order': 'Visualizza ordine',
  'tickets-tab_all-tickets': 'Tutti i biglietti',
  'tickets-tab_messages': 'Messaggi',
  'tickets-tab_my-tickets': 'I miei biglietti',
  'tickets-tab_orders-on-risk': 'Ordini sul rischio',
  'tickets-th_assigned-to': 'Assegnato a',
  'tickets-th_created-at': 'Creato alle',
  'tickets-th_description': 'Descrizione',
  'tickets_already-assigned': 'Già assegnato',
  'user-form-floating-label_address-line1': 'Riga Indirizzo 1',
  'user-form-floating-label_address-line2': 'Informazioni aggiuntive indirizzo (appartamento, numero interno)',
  'user-role_admin': 'Amministratore',
  'user-role_controller': 'Controllore',
  'user-role_manager': 'Dirigente',
  'user-role_observer': 'Osservatore',
  'users-button_assign': 'Assegna',
  'users-form-floating-label_address-line1': 'Riga Indirizzo 1',
  'users-form-floating-label_address-line2': 'Informazioni aggiuntive indirizzo (appartamento, numero interno)',
  'users-form-floating-label_country': 'Paese',
  'users-form-floating-label_email': 'Email *',
  'users-form-floating-label_first-name': 'Nome *',
  'users-form-floating-label_last-name': 'Cognome *',
  'users-form-floating-label_mobile': 'Cellulare *',
  'users-form-floating-label_password': 'Password *',
  'users-form-floating-label_password-confirmation': 'Conferma password *',
  'users-form-floating-label_phone-number': 'Numero di telefono',
  'users-form-floating-label_postal-code': 'Codice postale *',
  'users-form-floating-label_search-city': 'Ricerca città',
  'users-form-floating-label_service-area': 'Area di servizio',
  'users-form-floating-label_user-role': 'Ruolo utente *',
  'users-form-floating-label_user-id': 'User ID',
  'users-form-hint_country': 'Paese',
  'users-form-hint_email': 'Email',
  'users-form-hint_first-name': 'Nome',
  'users-form-hint_last-name': 'Cognome',
  'users-form-hint_mobile': 'Cellulare',
  'users-form-hint_password': 'Password',
  'users-form-hint_password-confirmation': 'Conferma password',
  'users-form-hint_phone-number': 'Numero di telefono',
  'users-form-hint_postal-code': 'Codice postale',
  'users-form-hint_search-city': 'Ricerca città',
  'users-form-hint_service-area': 'Area di servizio',
  'users-form-label_is-admin': 'È amministratore',
  'users-form-hint_user-type': 'User type',
  'users-message_exceptions': 'Eccezioni',
  'users-message_exceptions-groups': 'Gruppi di eccezioni',
  'users-message_no-exception-assigned': 'Nessuna eccezione assegnata a questo utente',
  'users-message_no-exception-groups-assigned': 'Nessun gruppo di eccezioni assegnato a questo utente',
  'users-message_no-provided-today-exceptions': 'Nessuna informazione fornita sulle eccezioni attuali',
  'users-message_no-service-area-assigned': "Non assegnato a un'area di servizio",
  'users-message_no-shifts': 'Nessun turno',
  'users-message_no-way-point-assigned': 'Non assegnato a una tappa intermedia',
  'users-message_today-exceptions': 'Eccezioni odierne',
  'users-tabs_area': 'Area',
  'users-tabs_area-city': 'Città',
  'users-tabs_area-current': 'Al momento questo utente è assegnato a',
  'users-tabs_area-current-selected': 'Attuale',
  'users-tabs_companies': 'Aziende',
  'users-tabs_roles': 'Roles',
  'users-tabs_controller': 'Controllore',
  'users-tabs_exception-groups': 'Gruppi di eccezione',
  'users-tabs_exceptions': 'Eccezioni',
  'users-tabs_info': 'Informazioni',
  'users-tabs_manager': 'Dirigente',
  'users-tabs_password': 'Password',
  'users-tabs_shifts': 'Turni',
  'users-tabs_today': 'Oggi',
  'users-tabs_access-log': 'Access Log',
  'users-tabs_warnings': 'Warnings',
  'users-th_admin': 'Amministratore',
  'users-th_clock': 'Timbratura',
  'users-th_clock-status': 'Stato orologio timbratura',
  'users-th_email': 'Email',
  'users-th_name': 'Nome',
  'users-th_service-area': 'Area di servizio',
  'users-th_way-point': 'Tappa intermedia',
  'users-title_address': 'Indirizzo',
  'users-title_admin-access': 'Accesso amministratore',
  'users-title_edit-password': 'Modifica password',
  'users-title_edit-user': 'Modifica utente',
  'users-title_exceptions': 'Eccezioni',
  'users-title_location': 'Posizione',
  'users-title_new-user': 'Crea nuovo utente',
  'users-title_password': 'Password',
  'users-title_phone-numbers': 'Numeri di telefono',
  'users-title_service-area': 'Area di servizio',
  'users-title_service-area-controller': 'Controllore area di servizio',
  'users-title_service-area-manager': 'Manager area di servizio',
  'users-title_user-contact-information': 'Informazioni di contatto utente',
  'users-title_user-information': 'Informazioni utente',
  'users-title_user-personal-information': 'Informazioni personali utente',
  users_admin: 'Amministratore',
  view_waypoint: 'Mostra',
  'warehouse-th_contact-info': 'Info contatto',
  'warehouse-th_company-name': 'Nome della ditta',
  'warehouse-th_email': 'E-mail',
  'warehouse-th_rest-tw': 'FT resto della settimana',
  'warehouse-th_today-tw': 'FT oggi',
  'warehouse-th_tomorrow-tw': 'FT domani',
  'warehouse-th_warehouse-info': 'Info magazzino',
  'warehouses-form-floating-label_address-line1': 'Indirizzo linea 1',
  'warehouses-form-floating-label_address-line2':
    "Informazioni aggiuntive sull'indirizzo (appartamento, numero della porta)",
  'warehouses-form-floating-label_contact_name': 'Nome del contatto',
  'warehouses-form-floating-label_email': 'E-mail',
  'warehouses-form-floating-label_external_id': 'ID esterno',
  'warehouses-form-floating-label_mobile': 'Numero di cellulare',
  'warehouses-form-floating-label_name': 'Numero del deposito',
  'warehouses-form-floating-label_phone-number': 'Numer',
  'warehouses-form-floating-label_pick-address': 'Scegli indirizzo',
  'warehouses-form-floating-label_pick_address': 'Scegli indirizzo',
  'warehouses-form-floating-label_postal-code': 'Codice postale',
  'warehouses-th_address': 'Magazzino',
  'warehouses-th_cancelled': 'Annullato',
  'warehouses-th_completed': 'Completato',
  'warehouses-th_manual-assignment': 'Assegnazione manuale',
  'warehouses-th_n-1st-delivery': '1ª consegna',
  'warehouses-th_n-last-delivery': 'Ultima consegna',
  'warehouses-th_n-order': 'Ordini',
  'warehouses-th_n-parcels': 'Colli',
  'warehouses-th_n-time-on-the-road': 'Tempo totale',
  'warehouses-th_name': 'Magazzino',
  'warehouses-th_otr': 'OTR',
  'warehouses-th_packed': 'Imballato',
  'warehouses-th_parcels': 'Colli',
  'warehouses-th_preordrers': 'Preordini odierni',
  'warehouses-th_route-id': 'ID percorso',
  'warehouses-th_utr': 'UTR',
  'warehouses-th_warehouse': 'Magazzino',
  'warehouses-title_edit-warehouse': 'Modifica magazzino',
  'warehouses-title_new-warehouse': 'Crea nuovo deposito',
  'warehouses-title_warehouse-contact-information': 'Informazioni di contatto',
  'warehouses-title_warehouse-information': 'Info magazzino',
  'warehouses-title_warehouses': 'Depositi',
  warehouses_tabs_info: 'Info',
  warehouses_tabs_paning: 'Pianificazione magazzino',
  warehouses_tabs_settings: 'Impostazioni',
  warning_proof_of_collection_required: 'Prova di ritiro richiesta',
  warning_proof_of_delivery_required: 'Prova di consegna richiesta',
  share_tracking: 'Share tracker',
  view_basket: 'View Basket',
  hide_basket: 'Hide Basket',
  time_arrival: 'Estimated Time arrival',
  time_zone: 'Time zone'
}
