import React, { memo, useState, useEffect } from 'react';

import pubsub from 'pubsub';

import SnackbarComponent from 'Snackbar/SnackbarComponent';

type TOpenSnackbar = (arg0?: string | JSX.Element, arg1?: string, arg2?: string, arg3?: () => void) => void;

export const openSnackbar: TOpenSnackbar = (message, details = '', type = 'success', handleClick) => {
  pubsub.emit('snackbar', {
    open: true,
    type,
    message,
    details,
    handleClick,
  });
};

interface IState {
  open: boolean;
  type: string;
  message: string;
  details: string;
  handleClick: () => void;
}

const defaultState = {
  open: false,
  type: '',
  message: '',
  details: '',
  handleClick: null,
};

const Snackbar = () => {
  const [state, setState] = useState<IState>(defaultState);

  useEffect(() => {
    const handleOpen = (payload: React.SetStateAction<IState>) => {
      setState(payload);
    };

    pubsub.on('snackbar', handleOpen);

    return () => {
      pubsub.off('snackbar', handleOpen);
    };
  }, []);

  const handleClose = () => {
    setState(defaultState);
  };

  const { open, type, message, details, handleClick } = state;

  return (
    <SnackbarComponent
      open={open}
      type={type}
      message={message}
      details={details}
      onClose={handleClose}
      onClick={handleClick}
    />
  );
};

export default memo(Snackbar, (prev: { open: boolean }, next: { open: boolean }) => prev.open !== next.open);
