import axios from 'axios';
import { contains } from 'ramda';

export function setAuthToken(token) {
  axios.defaults.headers.common['Authorization'] = '';
  delete axios.defaults.headers.common['Authorization'];

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token token=${token}`;
  }
}

// not using this function anywhere
// function canReadAreasSocket(controlledAreas, id) {
//   const areas = controlledAreas.map((item) => item._id);
//   if (contains(id, areas)) return true;
//   return false;
// }

function canReadCompaniesSocket(controlledCompanies, id) {
  const companies = controlledCompanies.map((item) => item._id);
  if (contains(id, companies)) return true;
  return false;
}

export function canReadEventSocket(msg, user) {
  // console.log('can read event socket', msg, user)
  const { admin, observer, managed_company_ids } = user;
  let canCompanies = false;
  if (admin) return true;
  if (observer) {
    canCompanies = canReadCompaniesSocket(managed_company_ids, msg.company_id);
  } else {
    canCompanies = canReadCompaniesSocket(managed_company_ids, msg.company_id);
  }
  return !!canCompanies;
}

export function accessToFeature(user, feature) {
  if (user.user_type_cd === 'admin') {
    return adminFeatures(feature);
  } else if (user.permissions) {
    if (user.permissions.find((el) => el.role_cd === 'manager')) {
      return managerFeatures(feature);
    } else if (user.permissions.find((el) => el.role_cd === 'observer')) {
      return observerFeatures(feature);
    } else if (user.permissions.find((el) => el.role_cd === 'controller')) {
      return controllerFeatures(feature);
    }
  }
}

function adminFeatures(feature) {
  return true;
}

function managerFeatures(feature) {
  switch (feature) {
    case 'show_market_sectors':
      return false;
    default:
      return true;
  }
}

function controllerFeatures(feature) {
  // console.log('admin', feature)

  switch (feature) {
    case 'show_shifts':
    case 'show_companies':
    case 'show_users':
    case 'show_users_drivers':
    case 'show_users_dispatchers':
    case 'show_users_observers':
    case 'show_market_sectors':
    case 'show_warehouse':
    case 'show_places':
    case 'show_customers':
    case 'show_order_resolutions':
    case 'show_more':
    case 'show_app_settings':
    case 'show_map_view':
      return false;
    default:
      return true;
  }
}

function observerFeatures(feature) {
  // console.log('observer', feature)

  switch (feature) {
    case 'show_shifts':
    case 'show_customers':
    case 'show_order_resolutions':
    case 'show_places':
    case 'show_warehouse':
    case 'show_warehouse_warehouse':
    case 'show_warehouse_time-window':
    case 'show_companies':
    case 'show_market_sectors':
    case 'show_users':
    case 'show_users_drivers':
    case 'show_users_dispatchers':
    case 'show_users_observers':
    case 'show_notifications':
    case 'show_counters':
    case 'show_settings':
    case 'show_driver_information':
    case 'can_edit_delivery':
    case 'can_edit_pickup':
    case 'can_see_order_tab_messages':
    case 'can_see_order_tab_waypoint':
    case 'can_see_order_tab_logs':
    case 'can_see_order_tab_driver':
    case 'can_see_distance_alerts':
    case 'can_flush_preorders':
    case 'can_cancel_preorders':
    case 'can_cancel_route':
    case 'can_delete_parcel':
    case 'show_more':
    case 'show_app_settings':
    case 'optimise_routes':
    case 'show_map_view':
    case 'show_update_status':
      // case 'show_in_risk_button':
      return false;
    default:
      return true;
  }
}

export const accessToRoutes = (user, route) => {
  if (user.user_type_cd === 'admin') {
    return true;
  } else if (user.permissions) {
    if (user.permissions.find((el) => el.role_cd === 'manager')) {
      return true;
    } else if (user.permissions.find((el) => el.role_cd === 'observer')) {
      return observerRoutes(route);
    } else if (user.permissions.find((el) => el.role_cd === 'controller')) {
      return dispatcherRoutes(route);
    }
  }
};
export const roleStr = (user) => {
  if (user.user_type_cd === 'admin') {
    return 'admin';
  } else if (user.permissions) {
    if (user.permissions.find((el) => el.role_cd === 'observer')) {
      return 'observer';
    } else if (user.permissions.find((el) => el.role_cd === 'controller')) {
      return 'dispatcher';
    }
  }
};

const dispatcherRoutes = (route) => {
  const disallowed = [
    'cities',
    'companies',
    'drivers',
    'users',
    'dispatchers',
    'observers',
    'market-sectors',
    'warehouses',
    'places',
    'customers',
    'order-resolutions',
  ];

  return disallowed.findIndex((el) => el === route.split('/')[2]) === -1;
};

const observerRoutes = (route) => {
  const disallowed = [
    'shifts',
    'deputy-integration',
    'cities',
    'companies',
    'drivers',
    'users',
    'dispatchers',
    'observers',
    'market-sectors',
    'warehouses',
    'places',
    'customers',
    'order-resolutions',
    'settings',
  ];

  return disallowed.findIndex((el) => el === route.split('/')[2]) === -1;
};

export const isLogged = () => !!localStorage.getItem('token');

export function isDispatcher(user) {
  // console.log('is dispatcher', user)
  if (user.user_type_cd === 'admin' || user.observer) return false;
  if (user.user_role_cd === 1) return true;
  return false;
}
