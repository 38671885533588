import theme from '.';

export default theme({
  palette: {
    primary: {
      light: '#27a0b7',
      main: '#27a0b7',
      dark: '#27a0b7',
      contrastText: '#fff',
    },
    secondary: {
      light: '#d55083',
      main: '#d55083',
      dark: '#d55083',
      contrastText: '#fff',
    },
    success: {
      light: '#2abb9d',
      main: '#2abb9d',
      dark: '#2abb9d',
      contrastText: '#fff',
    },
    info: {
      light: '#31a0db',
      main: '#31a0db',
      dark: '#31a0db',
      contrastText: '#fff',
    },
    warning: {
      light: '#f7a22e',
      main: '#f7a22e',
      dark: '#f7a22e',
      contrastText: '#fff',
    },
    danger: {
      light: '#e55454',
      main: '#e55454',
      dark: '#e55454',
      contrastText: '#fff',
    },
    scheduled: {
      light: '#1F1F1F',
      main: '#1F1F1F',
      dark: '#1F1F1F',
      contrastText: '#fff',
    },
  },
});
