import updateObject from '../../lib/updateObject';

import * as actionTypes from './types';

const initialState = {
  shouldRefresh: false,
};

const shouldRefreshWarehouse = (state, { item }) =>
  updateObject(state, {
    shouldRefresh: item,
  });

const warehouses = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.REFRESH_WAREHOUSES:
      return shouldRefreshWarehouse(state, payload);

    default:
      return state;
  }
};

export default warehouses;
