import React, { createContext, useContext } from 'react'

import * as R from 'ramda'

import { withApollo } from '@apollo/client/react/hoc'
import { ApolloClient } from '@apollo/client'

const defaultSettings = {
  language: 'en',
  delivery_time_window: 'deadline',
  currency: {
    symbol: '£',
    id: 'gbp'
  },
  fe_distance_unit: 'miles'
}
// @ts-ignore
export const SettingsContext = createContext()

type TUseSettingsContext = () => { [key: string]: any }

export const useSettingsContext: TUseSettingsContext = () => useContext(SettingsContext)

interface ISettingsContextProvider {
  client: ApolloClient<any>
  children: React.ReactNode
}

export const SettingsContextProvider = withApollo(
  ({ /* client, */ children }: ISettingsContextProvider): JSX.Element => {
    const getSetting = R.prop(R.__, defaultSettings)

    const parseSettings = R.compose(
      // @ts-ignore
      R.reduce((acc, item) => R.assoc(item.code, item.value, acc), {}),
      R.map(
        R.when(
          R.allPass([R.propEq('value_type', 'BOOLEAN'), R.compose(R.is(String), R.prop('value'))]),
          R.ifElse(R.propEq('value', 'true'), R.assoc('value', true), R.assoc('value', false))
        )
      ),
      // @ts-ignore
      R.map((item) => item.node)
    )

    const value = {
      settings: defaultSettings,
      getSetting,
      parseSettings
    }

    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
  }
)
