import {
  blueGrey600,
  // red600,
} from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

export default getMuiTheme({
  borderRadius: 0,
  palette: {
    primary1Color: '#27A0B7',
    accent1Color: blueGrey600,
  },
  appBar: {
    color: '#ffffff',
    textColor: '#2E384E',
    margin: '10px 0px',
  },
  button: {
    boxShadow: 'rgb(0,0,0,0)',
    height: 50,
  },
  raisedButton: {
    primaryColor: '#27A0B7',
    secondaryColor: '#D55083',
    boxShadow: 'rgb(0,0,0,0)',
  },
  floatingActionButton: {
    color: '#27A0B7',
  },
  textField: {
    textColor: '#2E384E',
  },
  tabs: {
    backgroundColor: '#27A0B7',
  },
  snackbar: {
    backgroundColor: '#27A0B7',
  },
  menuItem: {
    hoverColor: '#D8DDE1',
    selectedTextColor: '#27A0B7',
  },
  dropDownMenu: {
    accentColor: '#27A0B7',
  },
  listItem: {
    secondaryTextColor: '#97A6AE',
    leftIconColor: '#97A6AE',
  },
  tableRow: {
    selectedColor: '#F6F7F8',
    borderColor: '#CFD8DC',
  },
  checkbox: {
    boxColor: '#27A0B7',
  },
  toggle: {
    labelColor: '#27A0B7',
  },
  toolbar: {
    backgroundColor: 'transparent',
    iconColor: '#27A0B7',
  },
  tooltip: {
    rippleBackgroundColor: '#27A0B7',
  },
  timePicker: {
    headerColor: '#27A0B7',
  },
  datePicker: {
    calendarTextColor: '#27A0B7',
    selectColor: '#27A0B7',
    headerColor: '#27A0B7',
  },
});
