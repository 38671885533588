import mitt from 'mitt';

import { curry } from 'ramda';

// @ts-ignore
const emitter = new mitt();

export default {
  ...emitter,
  emit: curry(emitter.emit),
};
