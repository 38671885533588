import axios from 'axios'

import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { ApolloClient } from '@apollo/client/core'
import { ApolloLink, InMemoryCache, HttpLink, split, concat, from } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { openSnackbar } from 'Snackbar'

import config from '../config'
import { setAuthToken } from '../lib/auth'
// import { logAuthOrLoginErrorToRollbar } from 'lib/rollbar'

const token = localStorage.getItem('token')

if (token) {
  setAuthToken(token)
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')

  operation.setContext({
    headers: {
      Authorization: token ? `Bearer token=${token}` : ''
    }
  })

  if (forward) {
    return forward(operation)
  }

  return null
})

const authAfterware = onError((stack) => {
  if (stack && stack.graphQLErrors) {
    const isAuthError = stack.graphQLErrors.some(({ message }) => message.toLowerCase() === 'unauthenticated')

    if (isAuthError) {
      if (window && window.localStorage) {
        // logAuthOrLoginErrorToRollbar()
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('user')
        localStorage.removeItem('isAuthed')
      }

      // remove axios token
      delete axios.defaults.headers.common['Authorization']

      if (window.location.pathname !== '/auth/login' && window.location.pathname.indexOf('/track') === -1) {
        openSnackbar('Session expired, please log in again', null, 'error')
        setTimeout(() => {
          // @ts-ignore
          window.location = '/auth/login'
        }, 3000)
      }
    }
  }
})

const authLink = concat(authMiddleware, authAfterware)

const httpLink = new HttpLink({
  uri: config.GQL_API_URL
})

const wsLink = new WebSocketLink({
  uri: config.GQL_SUBSCRIPTIONS_URL,
  options: {
    reconnect: true,
    connectionParams: {
      app_token: token
    }
  }
})

const splitLink = split(
  ({ query }) => {
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query)

    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
})

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        orders: {
          merge: false
        },
        ordersConnection: {
          merge: false
        },
        runsConnection: {
          merge: false
        },
        user: {
          merge: true
        },
        run: {
          merge: true
        },
        warehouse: {
          merge: true
        },
        place: {
          merge: true
        }
      }
    }
  }
})

export const client = new ApolloClient({
  connectToDevTools: process.env.REACT_APP_DISPATCH_API_URL === 'https://api-devel.dmates.io',
  link: from([authLink, retryLink, splitLink]),
  cache,
  name: 'sabrina-frontend-[web]',
  version: config.VERSION
})
