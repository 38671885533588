import React from 'react';
import styled from 'styled-components';

import DmSnackbar from '@deliverymates/dm-ui/Snackbar';

import { MdInfo, MdClose, MdError } from 'react-icons/md';

const StyledSnackbar = styled(DmSnackbar)`
  && {
    left: 50%;
    right: auto;
    top: 20%;
    bottom: auto;

    & .dm-snackbar__content {
      min-height: 30px;
      padding: 0.25rem 0.5rem;
    }
  }
`;
const StyledSpan = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
`;
type TShackbarComponentProps = {
  open: boolean;
  type: string;
  message: string;
  details: any;
  onClose: () => void;
  onClick: () => void;
};
const SnackbarComponent: React.FC<TShackbarComponentProps> = ({
  open,
  type,
  message,
  details,
  onClose,
  onClick = null,
}): JSX.Element => {
  if (type === 'map-view') {
    return (
      <div onClick={onClick}>
        <StyledSnackbar
          key={message}
          isOpen={open}
          disabledAutoHide={true}
          onClose={onClose}
          actions={[<MdClose key={`${Math.random()}-close`} onClick={onClose} />]}
          color="info"
          position="right"
        >
          <div>
            <StyledSpan className="ml-2 float-left">{message}</StyledSpan>
          </div>
        </StyledSnackbar>
      </div>
    );
  }
  return (
    <div onClick={onClick}>
      <DmSnackbar
        key={message}
        isOpen={open}
        disabledAutoHide={false}
        onClose={onClose}
        actions={[<MdClose key={`${Math.random()}-close`} onClick={onClose} />]}
        color={type === 'success' ? 'success' : 'danger'}
        position="right"
      >
        <div className="mb-4">
          {type === 'success' ? <MdInfo className="ml-2 float-left" /> : <MdError className="ml-2 float-left" />}
          <span className="ml-2 float-left" style={{ lineHeight: '25px', textTransform: 'uppercase' }}>
            {message}
          </span>
        </div>
        <div className="ml-2">{details}</div>
      </DmSnackbar>
    </div>
  );
};

export default SnackbarComponent;
