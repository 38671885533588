export default {
  /// /////////////////////////////////////
  // GENERAL
  /// /////////////////////////////////////

  // BUTTONS
  'button-form_update': 'Update',
  'button-form_create': 'Create',
  'button-form_delete': 'Delete',

  // USER ROLES
  'user-role_admin': 'Admin',
  'user-role_manager': 'Manager',
  'user-role_controller': 'Controller',
  'user-role_observer': 'Observer',

  // DRAWER MENU
  drawer_orders: 'Orders',
  drawer_tickets: 'Tickets',
  'drawer_market-sectors': 'Market Sectors',
  drawer_companies: 'Companies',
  drawer_places: 'Sites',
  drawer_customers: 'Customers',
  drawer_exceptions: 'Exceptions',
  'drawer_exceptions-list': 'List',
  'drawer_exceptions-group': 'Groups',
  drawer_cities: 'Cities',
  drawer_users: 'Users',
  'drawer_users-drivers': 'Drivers',
  'drawer_users-dispatchers': 'Dispatchers',
  'drawer_users-observers': 'Observers',

  // DRAWER RIGHT
  'drawer-right_orders': 'Orders',
  'drawer-right_drivers': 'Drivers',
  'drawer-right_filtered': 'Filtered',
  'drawer-right_all': 'All',
  'drawer-right_shifted-drivers': 'Shifted',
  'drawer-right_attached-in': 'Attached',
  'drawer-right-tabs_on-hold': 'On Hold',
  'drawer-right-tabs_open': 'Open',
  'drawer-right-tabs_assigned': 'Assigned',
  'drawer-right-tabs_pickup-started': 'Pickup Started',
  'drawer-right-tabs_pickup-arrived': 'Pickup Arrived',
  'drawer-right-tabs_pickup-done': 'Pickup Done',
  'drawer-right-tabs_delivery-started': 'Delivery Started',
  'drawer-right-tabs_delivery-arrived': 'Delivery Arrived',
  'drawer-right-tabs_delivery-done': 'Delivery Done',
  'drawer-right_total-drivers': 'Total Drivers',
  'drawer-right_clocked-in': 'Clocked in',
  'drawer-right_filter-drivers-by': 'Filter drivers by',
  'drawer-right-tabs_cancelled': 'Cancelled',
  'drawer-right-title_orders': 'Orders',
  'drawer-right-title_alerts': 'Alerts',

  // PAGINATION

  pagination_first: 'First',
  pagination_last: 'Last',

  // FOOTER BUTTONS & DIALOGS

  'button_no-cancel': 'No, Cancel',
  'button_yes-delete': 'Yes, Delete',
  button_edit: 'Edit',
  button_delete: 'Delete',
  'dialog_confirm-delete': 'Confirm delete',
  'dialog_are-you-sure': 'Are you sure? You are going to delete this record',

  // NAVBAR

  'navbar-tooltip_notifications': 'Notifications',
  'navbar-menu_my-profile': 'My profile',
  'navbar-menu_settings': 'Settings',
  'navbar-menu_log-out': 'Log out',

  // Loading

  loading_loading: 'Loading',

  // Search
  search_search: 'Search',

  ///  /////////////////////////////////////
  // PAGES
  /// /////////////////////////////////////
  'page-title_edit-city-information': 'Edit city information',
  'page-title_countries': 'Countries',
  'page-title_dashboard': 'Dashboard',
  'page-title_login': 'Login',
  'page-title_forgot-your-password': 'Forgot your Password',
  'page-title_new-order': 'New Order',
  'page-title_map': 'Map',

  /// /////////////////////////////////////
  // LOGIN & PASSWORD
  /// /////////////////////////////////////

  // FORM
  'form-hint_email': 'Email',
  'form-floating-label_email': 'Email',
  'form-hint_password': 'Password',
  'form-floating-label_password': 'Password',

  // BUTTONS
  'button-label_sign-in': 'sign in',
  'button-label_lost-password': 'Lost Password',
  'button-label_new-password': 'New password',
  'button-label_back-to-login': 'Back to login',

  // ERRORS
  error_login: 'Login Error',

  // LOGOUT
  'login-message_session-expired': 'Session expired, please log in again',

  /// /////////////////////////////////////
  // CHAT
  /// /////////////////////////////////////

  'chat-hint_write-text': 'Write a text',
  'chat_no-messages': 'No Messages yet',

  /// /////////////////////////////////////
  // CITIES
  /// /////////////////////////////////////

  'city_create-new-city': 'Create New City',
  'city_city-information': 'City information',
  'city-form-hint_name': 'Name',
  'city-form-floating-label_name': 'Name',
  'city-form-floating-label_location': 'Location',
  city_cities: 'Cities',
  'city-th_name': 'Name',
  'city-form-hint_search': 'Search',
  'city-form-floating-label_search': 'Search',
  'city-counters_service-areas': 'Sevice Areas',
  'city-counters_waypoints': 'Waypoints',
  'city-counters_companies': 'Companies',
  'city-counters_places': 'Sites',
  'city-counters_users': 'Users',
  'city-counters_customers': 'Customers',
  'city-tabs_info': 'Info',
  'city-tabs_areas': 'Areas',
  'city-tabs_waypoints': 'Waypoints',
  'city-tabs_edit': 'Edit',
  'city-header_city-information': 'City information',

  /// /////////////////////////////////////
  // CITIES WP
  /// /////////////////////////////////////

  'city-wp-tabs_info': 'Info',
  'city-wp-tabs_shifts': 'Shifts',
  'city-wp-tabs_places': 'Sites',
  'city-wp-tabs_exceptions': 'Exceptions',
  'city-wp_waypoint-information': 'Waypoint information',
  'city-wp-form-hint_name': 'Name',
  'city-wp-form-floating-label_name': 'Name',
  'city-wp_create-shift': 'Create Shift',
  'city-wp-form-floating-label_description': 'Waypoint description',
  'city-wp-form-floating-label_location': 'Waypoint location',
  'city-wp-form-hint_description': 'Waypoint',
  // 'city-wp-info_dedicated-places': 'Dedicated Sites',
  // 'city-wp-info_exceptions': 'Exceptions',
  // 'city-wp-info-exception-groups': 'Exception groups',

  /// /////////////////////////////////////
  // CITIES AREAS
  /// /////////////////////////////////////

  'city-areas_action-add': 'Add',
  'city-areas_action-delete': 'Delete',
  'city-areas_action-close': 'Close',
  'city-areas-title_create-service-area': 'Create Service Area',
  'city-areas-title_update-service-area': 'Update Service Area',
  'city-areas-form-hint_name': 'Name',
  'city-areas-form-floating-label_name': 'Name',
  'city-areas-form-hint_area-coordinates': 'Area Coordinates',
  'city-areas-form-floating-label_area-coordinates': 'Area Coordinates',
  'city-areas_drop-upload': 'Try dropping some files here, or click to select files to upload.',
  'city-areas-dropdown_select-service-area': 'Select a Service Area',
  'city-areas-tooltip_details': 'Details',
  'city-areas-tooltip_places': 'Sites',
  'city-areas-tooltip_waypoints': 'Waypoints',
  'city-areas-admin_add-admin': 'Add administrator',
  'city-areas-title_service-area-info': 'Service area information',

  /// /////////////////////////////////////
  // Companies
  /// /////////////////////////////////////

  'companies-title_companies': 'Companies',
  'companies-title_new-company': 'New Company',
  'companies-title_info': 'Company information',
  'companies-title_costs': 'Costs',
  'companies-title_update-company': 'Update Company',
  'companies-form-hint_name': 'Name',
  'companies-form-floating-label_name': 'Name',
  'companies-form-hint_market-sector': 'Market sector',
  'companies-form-floating-label_market-sector': 'Market sector',
  'companies-form-hint_phone-number': 'Phone number',
  'companies-form-floating-label_phone-number': 'Phone number',
  'companies-form-hint_mobile-number': 'Mobile',
  'companies-form-floating-label_mobile-number': 'Mobile',
  'companies-form-hint_contact-name': 'Contact name',
  'companies-form-floating-label_contact-name': 'Contact name',
  'companies-form-hint_contact-email': 'Email',
  'companies-form-floating-label_contact-email': 'Email',
  'companies-form-hint_postal-code': 'Postal code',
  'companies-form-floating-label_postal-code': 'Postal code',
  'companies-form-hint_key': 'Key',
  'companies-form-floating-label_key': 'Key',
  'companies-form-hint_cost-per-mile': 'Cost x mile',
  'companies-form-floating-label_cost-per-mile': 'Cost x mile',
  'companies-form-hint_delivery-fee': 'Delivery fee',
  'companies-form-floating-label_delivery-fee': 'Delivery fee',
  'companies-tab_info': 'Info',
  'companies-tab_logo': 'Logo',
  'companies-th_name': 'Name',
  'companies-th_contact': 'Contact',
  'companies-th_phone': 'Phone',
  'companies-view_market': 'Market: ',
  'companies-view_cost-per-mile': 'Cost x mile',
  'companies-view_delivery-fee': 'Delivery fee',
  'companies-view_address': 'Address',
  'companies-view_contact': 'Contact',
  'companies-view_middleware': 'Middleware',
  'companies-view_middleware-port': 'Middleware Port: ',
  'companies-view_middleware-token': 'Middleware Token: ',
  'companies-view_middleware-url': 'Middleware URL: ',

  /// /////////////////////////////////////
  // CONSTRAINTS // EXCEPTIONS
  /// /////////////////////////////////////

  'exception-title_exceptions': 'Exceptions',
  'exception-title_new-exception': 'Create New Exception',
  'exception-title_exception-information': 'Exception information',
  'exception-title_edit-exception': 'Edit Exception',
  'exception-form-hint_name': 'Name',
  'exception-form-floating-label_name': 'Name',
  'exception-form-hint_description': 'Description',
  'exception-form-floating-label_description': 'Description',
  'exception-form-hint_type': 'Type',
  'exception-form-floating-label_type': 'Type',
  'exception-type_shirt': 'shirt',
  'exception-type_jacket': 'jacket',
  'exception-type_box': 'box',
  'exception-type_badge': 'badge',
  'exception-type_bag': 'bag',
  'exception-type_training': 'training',
  'exception-th_name': 'Name',
  'exception-th_type': 'Type',
  'exception-th_description': 'Description',
  'exception-view_type': 'Type',
  'exception-view_description': 'Description',

  /// /////////////////////////////////////
  // CONSTRAINTS GROUPS // EXCEPTIONS GROUPS
  /// /////////////////////////////////////

  'exception-group-title_new-exception': 'Create New Exception Group',
  'exception-group-title_group-information': 'Group information',
  'exception-group-title_exceptions': 'Exceptions',
  'exception-group-title_edit-exception-group': 'Edit Exception Group',
  'exception-group-title_exceptions-groups': 'Exceptions Groups',
  'exception-group-title_group_description': 'Group Description',
  'exception-group-title_exceptions-including-in': 'Exceptions included in',
  'exception-group-form-hint_name': 'Name',
  'exception-group-form-floating-label_name': 'Name',
  'exception-group-form-hint_description': 'Description',
  'exception-group-form-floating-label_description': 'Description',
  'exception-group-tabs_info': 'Info',
  'exception-group-tabs_assign': 'Assign',
  'exception-group-tabs_create': 'Create',
  'exception-group-th_name': 'Name',
  'exception-group-th_description': 'Description',

  /// /////////////////////////////////////
  // CUSTOMERS
  /// /////////////////////////////////////

  'customers-title_new-customer': 'Create New Customer',
  'customers-title_personal-information': 'Personal information',
  'customers-title_contact-information': 'Contact information',
  'customers-title_customers': 'Customers',
  'customers-form-hint_first-name': 'First Name',
  'customers-form-floating-label_first-name': 'First Name',
  'customers-form-hint_last-name': 'Last Name',
  'customers-form-floating-label_last-name': 'Last Name',
  'customers-form-hint_email': 'Email',
  'customers-form-floating-label_email': 'Email',
  'customers-form-hint_payment-mode': 'Payment mode',
  'customers-form-floating-label_payment-mode': 'Payment mode',
  'customers-form-hint_postal-code': 'Postal code',
  'customers-form-floating-label_postal-code': 'Postal code',
  'customers-form-hint_phone-number': 'Phone number',
  'customers-form-floating-label_phone-number': 'Phone number',
  'customers-form-hint_mobile-number': 'Mobile',
  'customers-form-floating-label_mobile-number': 'Mobile',
  'customers-th_name': 'Name',
  'customers-th_email': 'Email',
  'customers-view_address': 'Address',
  'customers-view_phone-numbers': 'Phone numbers',

  /// /////////////////////////////////////
  // MARKET SECTORS
  /// /////////////////////////////////////

  'market-sectors-title_new-market-sector': 'Create New Market Sector',
  'market-sectors-title_market-sector-information': 'Market Sector information',
  'market-sectors-title_edit-market-sector': 'Edit Market Sector',
  'market-sectors-title_market-sectors': 'Market Sectors',
  'market-sectors-form-hint_name': 'Name',
  'market-sectors-form-floating-label_name': 'Name',
  'market-sectors-th_name': 'Name',

  /// /////////////////////////////////////
  // NOTIFICATIONS / TICKETS
  /// /////////////////////////////////////

  'notifications-button_view-all': 'View All',
  'notifications-th_created-at': 'Created at',
  'notifications-th_description': 'Description',
  'notifications-th_assigned-to': 'Assigned to',
  notifications_attend: 'Attend',
  'notifications_already-assigned': 'Already assigned',
  'notifications_view-order': 'View Order',

  /// /////////////////////////////////////
  // ORDERS
  /// /////////////////////////////////////

  'orders-title_new-order': 'Create New Order',
  'orders-title_order-information': 'Order information',
  'orders-title_address': 'Address',
  'orders-title_edit-order': 'Edit Order',
  'orders-form-hint_name': 'Name',
  'orders-form-floating-label_name': 'Name',
  'orders-form-hint_contact-name': 'Contact name',
  'orders-form-floating-label_contact-name': 'Contact name',
  'orders-form-hint_email': 'Email',
  'orders-form-floating-label_email': 'Email',
  'orders-form-hint_phone-number': 'Phone number',
  'orders-form-floating-label_phone-number': 'Phone number',
  'orders-form-hint_mobile': 'Mobile',
  'orders-form-floating-label_mobile': 'Mobile',
  'orders-assigner_assign-driver': 'Assign driver to order',
  'orders-assigner-hint_driver-name': 'Driver name',
  'orders-assigner-button_filter-by-name': 'Filter by name',
  'orders-assigner-button_assign': 'Assign',
  'orders-assigner-th_name': 'Name',
  'orders-assigner-th_shirt': 'Shirt',
  'orders-assigner-th_jaquet': 'Jacket',
  'orders-assigner-th_box': 'Box',
  'orders-assigner-th_badge': 'Badge',
  'orders-assigner-th_bag': 'Bag',
  'orders-assigner-th_training': 'Training',
  'orders-assigner_no-drivers-assigned': 'No drivers available to this area',
  'orders-job_jobs-starts-at': 'Jobs starts at',
  'orders-job_jobs-end-at': 'Job ends at',
  'orders-job-form-hint_date': 'Date',
  'orders-job-form-floating-label_date': 'Date',
  'orders-job-form-hint_time': 'Time',
  'orders-job-form-floating-label_time': 'Time',
  'orders-th_open-time': 'Open Time',
  'orders-th_id-order': 'Order Details',
  'orders-th_pickup-at': 'Pickup at',
  'orders-th_delivery-at': 'Delivery at',
  'orders-th_driver': 'Driver',
  'orders-th_time': 'Time',
  'orders-th_delay': 'Delay',
  'orders-th_distance': 'Distance',
  'orders-time_paid-at': 'Paid at',
  'orders-time_expected-pickup-at': 'Expected pickup at',
  'orders-time_expected-delivery-at': 'Expected delivery at',
  'orders-driver_unassigned': 'Unassigned',
  'orders-modal_errors': 'Errors',
  'orders-modal_warnings': 'Warnings',
  'orders-label_order-is-ready': 'Order is ready',
  'orders-label_calls': 'Twillio calls',
  'orders-label_address-errors': 'Address errors',
  'orders-driver_invitation-timeout': 'Invitation timeout:',
  'orders-driver_invitation-expired': 'Invitation Expired',

  // ORDER TABS
  'orders-tabs_on-hold': 'On Hold',
  'orders-tabs_open': 'Open',
  'orders-tabs_assigned': 'Assigned',
  'orders-tabs_pickup': 'Pickup ',
  'orders-tabs_p': 'P. ',
  'orders-tabs_started': 'Started',
  'orders-tabs_arrived': 'Arrived',
  'orders-tabs_done': 'Done',
  'orders-tabs_delivery': 'Delivery ',
  'orders-tabs_d': 'D. ',
  'orders-tabs_cancelled': 'Cancelled',
  'orders-tabs_search': 'Search',

  // ORDERS STATUS
  'order-status-on_hold': 'On hold',
  'order-status-order_arrived': 'Order Arrived',
  'order-status-order_assigned': 'Order assigned',
  'order-status-pickup_started': 'Pickup started',
  'order-status-pickup_arrived': 'Pickup arrived',
  'order-status-pickup_done': 'Pickup done',
  'order-status-delivery_started': 'Delivery started',
  'order-status-delivery_arrived': 'Delivery arrived',
  'order-status-delivery_done': 'Delivery done',
  'order-status-order_cancelled': 'Cancelled',
  'order-status-order_dispatching': 'Dispatching order',

  // ORDER SEARCH
  'orders-search-form-btn': 'Search',
  'orders-search-form-hint_name': 'Search by id or restaurant name',
  'orders-search-form-floating-label_name': 'Search by id or restaurant name',

  // DETAIL
  'orders-driver_assign-driver': 'Assign a driver',
  'orders-driver_force-manual-selection': 'FORCE MANUAL SELECTION',
  'orders-driver_invitation-sent-waiting': 'INVITATION SENT, WAITING FOR ACCEPTATION ',
  'orders-detail_on-route': 'On route',
  'orders-detail_completed': 'Completed',
  'orders-detail_created-at': 'Created at',
  'orders-detail_pickup-information': 'Pickup information',
  'orders-detail_delivery-information': 'Delivery information:',
  'orders-detail_estimated': 'Estimated:',
  'orders-map_order-ready-for-collection': 'Ready for Collection',
  'orders-map_pickup': 'Pickup',
  'orders-map_delivery': 'Delivery',
  'orders-map_distance': 'Distance',
  'orders-map_miles': 'Miles',
  'order-status-bar_pending': 'PENDING',
  'order-status-bar_on-route': 'ON ROUTE',
  'order-status-bar_completed': 'COMPLETED',

  // BASKET
  'orders-basket_name': 'Name',
  'orders-basket_quantity': 'Quantity',
  'orders-basket_original': 'Original',
  'orders-basket_final': 'Final',
  'orders-basket_total-price': 'Total price',

  // DETAIL TABS
  'orders-tabs_status': 'Status',
  'orders-tabs_basket': 'Basket',
  'orders-tabs_messages': 'MSG',
  'orders-tabs_waypoint': 'Waypoint',
  'orders-tabs_log': 'Log',

  // DETAIL STATUS
  'orders-status_status': 'Status',
  'orders-status_hour': 'Hour',
  'orders-status_tbs': 'TBS*',
  'orders-status_tpe': 'TPE**',
  'orders-status_open': 'Open',
  'orders-status_assigned': 'Assigned',
  'orders-status_accepted': 'Accepted',
  'orders-status_start-pickup': 'Start Pickup',
  'orders-status_arrived-pickup': 'Arrived Pickup',
  'orders-status_done-pickup': 'Done Pickup',
  'orders-status_start-delivery': 'Start Delivery',
  'orders-status_arrived-delivery': 'Arrived Delivery',
  'orders-status_done-delivery': 'Done Delivery',
  'orders-status_total-time': 'Total Time',
  'orders-status_time-between-status': '* Time between status',
  'orders-status_time-per-event': '** Time per Event',

  // WAYPOINT
  'orders-button_waypoint': 'Open Waypoint',

  // ACTION BUTTONS
  'orders-actions_reset': 'Reset Order',
  'orders-actions_reset-confirm': 'Please confirm before reset',
  'orders-actions_mark-as-completed': 'Mark as completed',
  'orders-actions_completed-confirm': 'Please confirm',
  'orders-actions_mark-as-cancelled': 'Mark as cancelled',
  'orders-actions_cancel-confirm': 'Please confirm',

  // NEW ORDER
  'orders-title_place-information': 'Site information',
  'orders-title_customer-information': 'Customer information',
  'orders-form-hint_place': 'Place',
  'orders-form-floating-label_place': 'Place',
  'orders-form-hint_first-name': 'First Name',
  'orders-form-floating-label_first-name': 'First Name',
  'orders-form-hint_last-name': 'Last Name',
  'orders-form-floating-label_last-name': 'Last Name',
  'orders-title_address-line1': 'Address',
  'orders-title_address-line2': 'Address number',
  'orders-title_postal-code': 'Postal code',
  'orders-title_contact-name': 'Contact name',
  'orders-title_email': 'Email',
  'orders-title_name': 'Name',
  'orders-title_phone-number': 'Phone Number',
  'orders-title_mobile-phone-number': 'Mobile Phone Number',

  /// /////////////////////////////////////
  // PAYMENT MODES
  /// /////////////////////////////////////

  'payment-mode-title_new-payment-mode': 'Create New Payment Mode',
  'payment-mode-title_edit-payment-mode': 'Edit Payment Modes',
  'payment-mode-title_payment-modes': 'Payment Modes',
  'payment-mode-form-hint_name': 'Name',
  'payment-mode-form-floating-label_name': 'Name',
  'payment-mode-th_name': 'Name',

  /// /////////////////////////////////////
  // Places / SITES
  /// /////////////////////////////////////

  'places-title_new-place': 'Create New Site',
  'places-title_place-external_id': 'External ID',
  'places-title_place-information': 'Site information',
  'places-title_address': 'Address',
  'places-title_contact': 'Contact',
  'places-title_edit-place': 'Edit Site',
  'places-title_places': 'Sites',
  'places-title_place-contact-information': 'Site Contact information',
  'places-form-hint_name': 'Name',
  'places-form-floating-label_name': 'Name',
  'places-form-hint_contact-name': 'Contact name',
  'places-form-floating-label_contact-name': 'Contact name',
  'places-form-hint_email': 'Email',
  'places-form-floating-label_email': 'Email',
  'places-form-hint_phone-number': 'Phone number',
  'places-form-floating-label_phone-number': 'Phone number',
  'places-form-hint_mobile': 'Mobile',
  'places-form-floating-label_mobile': 'Mobile',
  'places-form-hint_postal-code': 'Postal code',
  'places-form-hint_external_id': 'External ID',
  'places-form-floating-label_external_id': 'External ID',
  'places-form-floating-label_postal-code': 'Postal code',
  'places-th_name': 'Name',
  'places-th_email': 'Email',
  'places-th_external_id': 'External ID',
  'places-map_no_location': 'No location available...',
  'places-qr_code-enable': 'Enable QRCode',
  'places-qr_code-disable': 'Disable QRCode',
  'places-qr_code-title': 'QRCodes',
  'places-qr_code-none': 'No QRCodes',

  /// /////////////////////////////////////
  // POSITION PICKER
  /// /////////////////////////////////////

  'position-picker-form-hint_address-line1': 'Address',
  'position-picker-form-floating-label_address-line1': 'Address',
  'position-picker-form-hint_address-line2': 'Address number',
  'position-picker-form-floating-label_address-line2': 'Address number',
  'position-picker-button_cancel': 'Cancel',
  'position-picker-button_select-this-position': 'Select this position',
  'position-picker-dialog_select-location': 'Select a location',
  'position-picker-form-hint_search-location': 'Search location',
  'position-picker-form-floating-label_search-location': 'Search location',

  /// /////////////////////////////////////
  // SHIFTS
  /// /////////////////////////////////////

  'shifts-title_new-shift': 'Create new shift',
  'shifts-title_update-shift': 'Update shift',
  'shifts-title_shifts': 'Shifts',
  'shifts-form-hint_driver-name': 'Driver name',
  'shifts-button_search-driver': 'Search Driver',
  'shifts-form-hint_start-date': 'Start date',
  'shifts-form-floating-label_start-date': 'Start date',
  'shifts-form-hint_time': 'Time',
  'shifts-form-floating-label_time': 'Time',
  'shifts-form-hint_end-date': 'End date',
  'shifts-form-floating-label_end-date': 'End date',
  'shifts-th_driver': 'Driver',
  'shifts-th_start': 'Start',
  'shifts-th_end': 'End',
  'shifts-th_drops': 'Drops',
  'shifts-action_edit-shift': 'Edit Shift',
  'shifts-action_delete-shift': 'Delete Shift',
  'shifts-message_no-drivers-assigned': 'No drivers assigned to this area',
  'shifts-switch_current': 'Current',

  /// /////////////////////////////////////
  // TICKETS
  /// /////////////////////////////////////

  'tickets-tab_all-tickets': 'All Tickets',
  'tickets-tab_my-tickets': 'My Tickets',
  'tickets-th_created-at': 'Created at',
  'tickets-th_description': 'Description',
  'tickets-th_assigned-to': 'Assigned to',
  'tickets_already-assigned': 'Already assigned',
  'tickets-button_attend': 'Attend',
  'tickets-button_view-order': 'View Order',
  'tickets-tab_messages': 'Messages',

  /// /////////////////////////////////////
  // USERS
  /// /////////////////////////////////////

  'users-title_new-user': 'Create New User',
  'users-title_user-information': 'User information',
  'users-title_user-personal-information': 'User personal information',
  'users-title_user-contact-information': 'User contact information',
  'users-title_service-area': 'Service Area',
  'users-title_service-area-manager': 'Service Area manager',
  'users-title_service-area-controller': 'Service Area controller',
  'users-title_address': 'Address',
  'users-title_phone-numbers': 'Phone numbers',
  'users-title_location': 'Location',
  'users-title_password': 'Password',
  'users-title_edit-password': 'Edit password',
  'users-title_edit-user': 'Edit User',
  'users-title_admin-access': 'Admin access',
  'users-form-hint_first-name': 'First Name',
  'users-form-floating-label_first-name': 'First Name',
  'users-form-hint_last-name': 'Last Name',
  'users-form-floating-label_last-name': 'Last Name',
  'users-form-hint_email': 'Email',
  'users-form-floating-label_email': 'Email',
  'users-form-floating-label_user-role': 'User role',
  'users-form-hint_phone-number': 'Phone number',
  'users-form-floating-label_phone-number': 'Phone number',
  'users-form-hint_mobile': 'Mobile',
  'users-form-floating-label_mobile': 'Mobile',
  'users-form-hint_postal-code': 'Postal code',
  'users-form-floating-label_postal-code': 'Postal code',
  'users-form-hint_password': 'Password',
  'users-form-floating-label_password': 'Password',
  'users-form-hint_password-confirmation': 'Password confirmation',
  'users-form-floating-label_password-confirmation': 'Password confirmation',
  'users-form-label_is-admin': 'Is admin',
  'users-form-hint_country': 'Country',
  'users-form-floating-label_country': 'Country',
  'users-form-hint_user-type': 'User type',
  'users-form-floating-label_user-id': 'User ID',
  // SA
  'users-button_assign': 'Assign',
  'users-form-hint_search-city': 'Search city',
  'users-form-floating-label_search-city': 'Search city',
  'users-form-hint_service-area': 'Service area',
  'users-form-floating-label_service-area': 'Service area',

  // TABS
  'users-tabs_info': 'Info',
  'users-tabs_roles': 'Roles',
  'users-tabs_area': 'Area',
  'users-tabs_exceptions': 'Exceptions',
  'users-tabs_exception-groups': 'Exc Groups',
  'users-tabs_manager': 'Manager',
  'users-tabs_controller': 'Controller',
  'users-tabs_password': 'Password',
  'users-tabs_today': 'Today',
  'users-tabs_shifts': 'Shifts',
  'users-tabs_access-log': 'Access Log',
  'users-tabs_warnings': 'Warnings',

  // EXCEPTIONS
  'users-title_exceptions': 'Exceptions',

  // LIST
  'users-th_name': 'Name',
  'users-th_email': 'Email',
  'users-th_admin': 'Admin',
  users_admin: 'Admin',

  // ERRORS AND TITLES FOR NO INFO

  'users-message_no-exception-groups-assigned': 'No exception groups assigned to this user',
  'users-message_no-exception-assigned': 'No exceptions assigned to this user',
  'users-message_no-service-area-assigned': 'Not assigned to a service area',
  'users-message_no-way-point-assigned': 'Not assigned to a way point',
  'users-message_no-provided-today-exceptions': 'No provided info about current exceptions',
  'users-message_today-exceptions': 'Today exceptions',
  'users-message_exceptions': 'Exceptions',
  'users-message_exceptions-groups': 'Exceptions Groups',
  'users-message_no-shifts': 'No shifts',

  /// /////////////////////////////////////
  // WARNINGS
  /// /////////////////////////////////////

  geowarnings_warnings: 'Warnings',

  // GEO WARNING
  'geo-warning-address_not_found': 'Address not found',
  'geo-warning-cannot_infer_post_code': 'Postcode is missing',
  'geo-warning-low_accuracy_coords': 'Coordinates are imprecise',
  'geo-warning-unresolved_coords': 'Could not resolve the coordinates',
  'geo-warning-no_payload_for_geo': 'Not enough information',

  /// /////////////////////////////////////
  // LANGUAGE
  /// /////////////////////////////////////

  language_en: 'EN',
  language_es: 'ES',
  language_it: 'IT',
  'language_pt-br': 'PT-BR',
  language_fr: 'FR',
  language_de: 'DE',

  /// /////////////////////////////////////
  // LOCATION PICKER
  /// /////////////////////////////////////
  'location-form-hint_location': 'Location',
  'location-form-floating-label_location': 'Location',
  'location-button_select': 'Select',
  'location-hint_search-location': 'Search location',
  'location-floating-label_search-location': 'Search location',
  'location-hint_filter-by-country': 'Filter by country',
  'location-floating-label_filter-by-country': 'Filter by country',
  'location-table-th_name': 'Name',
  'location-table-th_country': 'Country',
  'location-table-th_class': 'Class',
  'location-table-th_code': 'Code',

  share_tracking: 'Share tracker',
  view_basket: 'View Basket',
  hide_basket: 'Hide Basket',
  /// ///
  /// My Areas
  /// ///
  'my-areas': 'My Service Areas',
  time_arrival: 'Estimated Time arrival',
  time_zone: 'Time zone'
}
