export const OPEN_ADD_MODE_COUNTRIES = 'OPEN_ADD_MODE_COUNTRIES';
export const OPEN_EDIT_MODE_COUNTRIES = 'OPEN_EDIT_MODE_COUNTRIES';
export const OPEN_VIEW_MODE_COUNTRIES = 'OPEN_VIEW_MODE_COUNTRIES';
export const CLOSE_PANELS_COUNTRIES = 'CLOSE_PANELS_COUNTRIES';
export const DELETING_COUNTRY = 'DELETING_COUNTRY';
export const DELETE_COUNTRY_OK = 'DELETE_COUNTRY_OK';
export const DELETE_COUNTRY_ERROR = 'DELETE_COUNTRY_ERROR';
export const SHOW_FORM_ERRORS_COUNTRY = 'SHOW_FORM_ERRORS_COUNTRY';
export const CREATING_COUNTRY = 'CREATING_COUNTRY';
export const CREATE_COUNTRY_OK = 'CREATE_COUNTRY_OK';
export const CREATE_COUNTRY_ERROR = 'CREATE_COUNTRY_ERROR';
export const UPDATING_COUNTRY = 'UPDATING_COUNTRY';
export const UPDATE_COUNTRY_OK = 'UPDATE_COUNTRY_OK';
export const FETCH_SINGLE_COUNTRY_OK = 'FETCH_SINGLE_COUNTRY_OK';
export const SET_CURRENT_COUNTRY = 'SET_CURRENT_COUNTRY';
export const CREATE_EMPTY_COUNTRY_NODE = 'CREATE_EMPTY_COUNTRY_NODE';
export const STORE_SINGLE_COUNTRY = 'STORE_SINGLE_COUNTRY';

const initialState = {
  items: [],
  loading: false,
  deleting: false,
  updating: false,
  current: {},
  error: '',
  page: 1,
  pagination: {},
  isAddMode: false,
  isEditMode: false,
  isViewMode: false,
  currentItem: {},
  showFormErrors: false,
};

export default function Countries(state = initialState, action) {
  switch (action.type) {
    case STORE_SINGLE_COUNTRY:
      return {
        ...state,
        [action.item._id]: action.item,
      };
    case OPEN_VIEW_MODE_COUNTRIES:
      return {
        ...state,
        isAddMode: false,
        isEditMode: false,
        isViewMode: true,
        currentItem: action.item,
      };
    case OPEN_ADD_MODE_COUNTRIES:
      return {
        ...state,
        isAddMode: true,
        isEditMode: false,
        isViewMode: false,
        showFormErrors: false,
        currentItem: action.item,
      };
    case OPEN_EDIT_MODE_COUNTRIES:
      return {
        ...state,
        isAddMode: false,
        isEditMode: true,
        isViewMode: false,
      };
    case CLOSE_PANELS_COUNTRIES:
      return {
        ...state,
        isAddMode: false,
        isEditMode: false,
        isViewMode: false,
        currentItem: {},
      };
    case DELETING_COUNTRY:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_COUNTRY_OK:
      return {
        ...state,
        items: state.items.filter((item) => {
          if (item._id !== action.item._id) return item;
          else return null;
        }),
      };
    case CREATE_COUNTRY_OK:
      return {
        ...state,
        items: state.items.concat(action.item),
        showFormErrors: false,
        isAddMode: false,
        isViewMode: true,
        currentItem: action.item,
      };
    case UPDATING_COUNTRY:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_COUNTRY_OK:
      return {
        ...state,
        updating: false,
        items: state.items.map((item) => {
          if (item._id === action.item._id) return action.item;
          return item;
        }),
        isEditMode: false,
        isViewMode: true,
        currentItem: action.item,
      };
    case SHOW_FORM_ERRORS_COUNTRY:
      return {
        ...state,
        showFormErrors: true,
      };
    case FETCH_SINGLE_COUNTRY_OK:
      return {
        ...state,
        currentItem: action.currentItem,
        [action.currentItem._id]: action.currentItem,
      };
    case SET_CURRENT_COUNTRY:
      return {
        ...state,
        currentItem: action.currentItem,
      };
    case CREATE_EMPTY_COUNTRY_NODE:
      return {
        ...state,
        [action.payload]: {
          loading: true,
        },
      };
    default:
      return state;
  }
}
