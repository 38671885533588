import React, { createContext, useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { gql } from '@apollo/client'
import { withRouter } from 'react-router'
import { useQuery } from '@apollo/client'
import DmLayoutApp from '@deliverymates/dm-ui/Layout/App'
import Loading from 'components/atoms/Loading'
import { authMe } from 'api/index'
import { openSnackbar } from 'Snackbar'
// import { logAuthOrLoginErrorToRollbar } from 'lib/rollbar'

// @ts-ignore
export const AuthContext = createContext()

export const useAuthContext: () => any = () => useContext(AuthContext)

export const PERMISSIONS = gql`
  query permissions($user_id: ID) {
    result: permissions(where: { user_id: $user_id }) {
      user_id
      company_id
      role_cd
    }
  }
`

export const AuthContextProvider = withRouter(({ history, location, children }) => {
  const token = localStorage.getItem('token')

  const [me, setMe] = useState<{ [key: string]: unknown }>()
  const [permissions, setPermissions] = useState()
  const [isLoading, setIsLoading] = useState(!!token)

  const { data: permissionsData, loading: permissionsLoading } = useQuery(PERMISSIONS, {
    variables: {
      user_id: me && me._id
    },
    skip: !me
  })

  useEffect(() => {
    if (permissionsData) {
      setPermissions(permissionsData.result)
    }
  }, [permissionsData])

  const getMe = async () => {
    try {
      const { data } = await authMe()

      setMeHandle(data)
    } catch (e) {
      logout(true)
    } finally {
      setIsLoading(false)
    }
  }

  const setMeHandle = (data) => {
    setMe(data)
    if (data.user_role_cd === 0) {
      history.push('/auth/login')

      return
    }

    if (data && window && window.localStorage) {
      localStorage.setItem('token', data.app_token)
      localStorage.setItem('refreshToken', data.app_refresh_token)
      localStorage.setItem('user', JSON.stringify(data))
      // @ts-ignore
      localStorage.setItem('isAuthed', true)
    }

    // set axios token
    axios.defaults.headers.common['Authorization'] = `Token token=${data.app_token}`

    if (location.pathname.indexOf('/auth') > -1) {
      window.location.href = '/admin'
    }
  }

  const logout = (showMessage = false) => {
    if (window && window.localStorage) {
      // showMessage && logAuthOrLoginErrorToRollbar()

      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
      localStorage.removeItem('isAuthed')
    }

    // remove axios token
    delete axios.defaults.headers.common['Authorization']

    const params = new URLSearchParams(location.search).get('token')

    if (params !== null || location.pathname === '/auth/forgot') {
      return
    } else if (location.pathname !== '/auth/login' && location.pathname.indexOf('/track') === -1) {
      if (showMessage) {
        openSnackbar('Session expired, please log in again', null, 'error')
        setTimeout(() => {
          // @ts-ignore
          window.location = '/auth/login'
        }, 3000)
      } else {
        // @ts-ignore
        window.location = '/auth/login'
      }
    }

    setMe(null)
  }

  useEffect(() => {
    if (token) {
      getMe()
    }
    // else {
    //   logout()
    // }
  }, [token])

  const value = {
    me: { ...me, permissions },
    getMe,
    setMe: setMeHandle,
    logout
  }

  if (isLoading || permissionsLoading) {
    return (
      <DmLayoutApp>
        <div className="loadingPage">
          <Loading />
        </div>
      </DmLayoutApp>
    )
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
})
