export default {
  'Appbar-Cities': 'Cities',
  'Appbar-Companies': 'Companies',
  'Appbar-Customers': 'Customers',
  'Appbar-Exceptions': 'Exceptions',
  'Appbar-Exceptions Groups': 'Exceptions Groups',
  'Appbar-Market Sectors': 'Market Sectors',
  'Appbar-My service areas': 'My service areas',
  'Appbar-Orders': 'Orders',
  'Appbar-Places': 'Sites',
  'Appbar-Users-dispatchers': 'Users / dispatchers',
  'Appbar-Users-drivers': 'Users / Drivers',
  'Appbar-Users-observers': 'Users / observers',
  'button-form_cancel': 'Cancel',
  'button-form_create': 'Create',
  'button-form_delete': 'Delete',
  'button-form_update': 'Update',
  'button-form_please_wait': 'Please Wait...',
  'button-label_back-to-login': 'Back to login',
  'button-label_lost-password': 'Lost Password',
  'button-label_new-password': 'New password',
  'button-label_sign-in': 'sign in',
  'button-label_forgot-password': 'Forgot Password?',
  button_delete: 'Delete',
  delete_in_info: 'Delete',
  after_merge_in_info: 'after merge',
  merge_warehouse: 'Merge Warehouse',
  button_edit: 'Edit',
  'button_no-cancel': 'No, Cancel',
  button_proceed: 'Proceed',
  button_cancel: 'Cancel',
  'checkbox-label': 'Merge a Warehouse will dump its routes into the destination Warehouse.',
  button_merge: 'Merge',
  'button_confirm-delete': 'Yes, confirm delete',
  button_close: 'Close',
  button_save_changes: 'Save Changes',
  applying_merge: 'Applying Merge...',
  'button_yes-delete': 'Yes, Delete',
  'chat-hint_write-text': 'Write a text',
  'chat_no-messages': 'No Messages yet',
  cities_companies: 'Companies',
  cities_customers: 'Customers',
  'cities_lat-long': 'Coordinates',
  'cities_service-areas': 'Service areas',
  cities_sites: 'Sites',
  'cities_update-waypoint': 'Update Waypoint',
  cities_users: 'Users',
  cities_waypoints: 'Waypoints',
  notes: 'Notes',
  friendly_order_reference: 'Friendly order reference',
  order_reference: 'Order reference',
  'city-areas-admin_add-admin': 'Add administrator',
  'city-areas-dropdown_select-service-area': 'Select a Service Area',
  'city-areas-form-floating-label_area-coordinates': 'Area Coordinates *',
  'city-areas-form-floating-label_name': 'Name *',
  'city-areas-form-hint_area-coordinates': 'Area Coordinates',
  'city-areas-form-hint_name': 'Name',
  'city-areas-title_create-service-area': 'Create Service Area',
  'city-areas-title_service-area-info': 'Service area information',
  'city-areas-title_update-service-area': 'Update Service Area',
  'city-areas-tooltip_details': 'Details',
  'city-areas-tooltip_places': 'Sites',
  'city-areas-tooltip_waypoints': 'Waypoints',
  'city-areas_action-add': 'Add',
  'city-areas_action-close': 'Close',
  'city-areas_action-delete': 'Delete',
  'city-areas_drop-upload': 'Try dropping some files here, or click to select files to upload.',
  'city-counters_companies': 'Companies',
  'city-counters_customers': 'Customers',
  'city-counters_places': 'Sites',
  'city-counters_service-areas': 'Service Areas',
  'city-counters_users': 'Users',
  'city-counters_waypoints': 'Waypoints',
  'city-form-floating-label_location': 'Location',
  'city-form-floating-label_name': 'Name',
  'city-form-floating-label_search': 'Search',
  'city-form-hint_name': 'Name',
  'city-form-hint_search': 'Search',
  'city-header_city-information': 'City information',
  'city-tabs_areas': 'Areas',
  'city-tabs_edit': 'Edit',
  'city-tabs_info': 'Info',
  'city-tabs_waypoints': 'Waypoints',
  'city-th_name': 'Name',
  'city-wp-form-floating-label_description': 'Waypoint description',
  'city-wp-form-floating-label_name': 'Name',
  'city-wp-form-hint_description': 'Waypoint',
  'city-wp-form-hint_name': 'Name',
  'city-wp-info-exception-groups': 'Exception groups',
  'city-wp-info_dedicated-places': 'Dedicated Sites',
  'city-wp-info_exceptions': 'Exceptions',
  'city-wp-tabs_exceptions': 'Exceptions',
  'city-wp-tabs_info': 'Info',
  'city-wp-tabs_places': 'Sites',
  'city-wp-tabs_shifts': 'Shifts',
  'city-wp_create-shift': 'Create Shift',
  'city-wp_waypoint-information': 'Waypoint information',
  city_cities: 'Cities',
  'city_city-information': 'City information',
  'city_create-new-city': 'Create New City',
  'companies-form-floating-label_address-line1': 'Address line 1',
  'companies-form-floating-label_address-line2': 'Additional address information (flat, door number…)',
  'companies-form-floating-label_contact-email': 'Email',
  'companies-form-floating-label_contact-name': 'Contact name',
  'companies-form-floating-label_cost-per-mile': 'Cost x mile',
  'companies-form-floating-label_delivery-fee': 'Delivery fee',
  'companies-form-floating-label_dispatcher_policy': 'Dispatcher policy',
  'companies-form-floating-label_contact-dispatcher_base_url': 'Dispatcher base url',
  'companies-form-floating-label_key': 'Key',
  'companies-form-floating-label_market-sector': 'Market sector',
  'companies-form-floating-label_mobile-number': 'Mobile number',
  'companies-form-floating-label_name': 'Name',
  'companies-form-floating-label_phone-number': 'Phone number',
  'companies-form-floating-label_pick-address': 'Pick address',
  'companies-form-floating-label_delivery-address': 'Delivery address',
  'companies-form-floating-label_postal-code': 'Postal code',
  'companies-form-hint_contact-email': 'Email',
  'companies-form-hint_contact-name': 'Contact name',
  'companies-form-hint_cost-per-mile': 'Cost x mile',
  'companies-form-hint_delivery-fee': 'Delivery fee',
  'companies-form-hint_key': 'Key',
  'companies-form-hint_market-sector': 'Market sector',
  'companies-form-hint_mobile-number': 'Mobile number',
  'companies-form-hint_name': 'Name',
  'companies-form-hint_phone-number': 'Phone number',
  'companies-form-hint_postal-code': 'Postal code',
  'companies-form_dispatcher-multi': 'Multi',
  'companies-form_dispatcher-single': 'Single',
  'companies-form_select_one': 'Select one',
  'companies-keys': 'Company Settings',
  'companies-logo': 'Logo Settings',
  'companies-view_picture': 'Logo Picture',
  'companies-view_tracking_picture': 'Logo Tracking Picture',
  'companies-view_picture_loading': 'Uploading a new logo',
  'companies-view_picture_upload_success': 'Logo uploaded',
  'companies-view_picture_upload_error': 'Logo upload error',
  'companies-view_picture_upload_button': 'Upload File',
  'companies-tab_info': 'Info',
  'companies-tab_logo': 'Logo',
  'companies-th_contact': 'Contact',
  'companies-th_dispatcher_policy_detail': 'Dispatcher policy',
  'companies-th_name': 'Name',
  'companies-th_phone': 'Phone',
  'companies-title_companies': 'Companies',
  'companies-title_costs': 'Costs',
  'companies-title_info': 'Company information',
  'companies-title_new-company': 'New Company',
  'companies-title_update-company': 'Update Company',
  'companies-view_address': 'Address',
  'companies-view_contact': 'Contact',
  'companies-view_cost-per-mile': 'Cost x mile',
  'companies-view_delivery-fee': 'Delivery fee',
  'companies-view_dispatcher_policy_default': 'Dispatcher policy',
  'companies-view_market': 'Market: ',
  'companies-view_middleware': 'Middleware',
  'companies-view_middleware-port': 'Middleware Port: ',
  'companies-view_middleware-token': 'Middleware Token: ',
  'companies-view_middleware-url': 'Middleware URL: ',
  companies_tabs_info: 'Info',
  companies_tabs_map: 'Map',
  companies_tabs_middleware: 'Middleware',
  companies_tabs_picture: 'Logo',
  companies_tabs_reports: 'Reports',
  companies_tabs_settings: 'Settings',
  companies_tabs_order_resolution: 'Order resolution',
  companies_tabs_users: 'Users',
  'customers-form-floating-label_address-line1': 'Address line 1',
  'customers-form-floating-label_address-line2': 'Additional address information (flat, door number…)',
  'customers-form-floating-label_country': 'Country',
  'customers-form-floating-label_email': 'Email',
  'customers-form-floating-label_first-name': 'First Name',
  'customers-form-floating-label_last-name': 'Last Name',
  'customers-form-floating-label_mobile-number': 'Mobile number',
  'customers-form-floating-label_payment-mode': 'Payment mode',
  'customers-form-floating-label_phone-number': 'Phone number',
  'customers-form-floating-label_pick-address': 'Pick address',
  'customers-form-floating-label_postal-code': 'Postal code',
  'customers-form-hint_email': 'Email',
  'customers-form-hint_first-name': 'First Name',
  'customers-form-hint_last-name': 'Last Name',
  'customers-form-hint_mobile-number': 'Mobile number',
  'customers-form-hint_payment-mode': 'Payment mode',
  'customers-form-hint_phone-number': 'Phone number',
  'customers-form-hint_postal-code': 'Postal code',
  'customers-form_brasil': 'Brasil',
  'customers-form_cash': 'Cash',
  'customers-form_currency': 'Currency',
  'customers-form_friendly_order_reference': 'Friendly order reference',
  destination_policy: 'Destination policy',
  'customers-form_deferred': 'Account',
  'customers-form_england': 'England',
  'customers-form_españa': 'España',
  'customers-form_select_one': 'Select one',
  'customers-th_email': 'Email',
  'customers-th_name': 'Name',
  'customers-th_company': 'Company',
  'customers-th_address': 'Address',
  'customers-title_contact-information': 'Contact information',
  'customers-title_customers': 'Customers',
  'customers-title_new-customer': 'Create New Customer',
  'customers-title_personal-information': 'Personal information',
  'customers-view_address': 'Address',
  'customers-view_phone-numbers': 'Phone numbers',
  'customers-create_success': 'New customer created',
  'customers-create_error': 'Error creating new customer',
  'customers-update_success': 'Customer updated',
  'customers-update_error': 'Error updating customer',
  'customers-delete_success': 'Customer deleted',
  'customers-delete_error': 'Error deleting customer',
  'dialog_are-you-sure': 'Are you sure? You are going to delete this record',
  'dialog_confirm-delete': 'Confirm delete',
  'drawer-right-tabs_assigned': 'Assigned',
  'drawer-right-tabs_assigned_food': 'Order Accepted',
  'drawer-right-tabs_assigned_food-informal': 'Accepted',
  'drawer-right-tabs_assigned_generic': 'Order Accepted',
  'drawer-right-tabs_assigned_pharmacy-informal': 'Accepted',
  'drawer-right-tabs_cancelled': 'Cancelled',
  'drawer-right-tabs_delivery-arrived': 'Delivery Arrived',
  'drawer-right-tabs_delivery-arrived_food': 'Delivery Arrival',
  'drawer-right-tabs_delivery-arrived_food-informal': 'Customer arrival',
  'drawer-right-tabs_delivery-arrived_generic': 'Delivery Arrival',
  'drawer-right-tabs_delivery-arrived_pharmacy-informal': 'Patient arrival',
  'drawer-right-tabs_delivery-done': 'Delivery Done',
  'drawer-right-tabs_delivery-done_food': 'Delivery Complete',
  'drawer-right-tabs_delivery-done_food-informal': 'Food Delivered',
  'drawer-right-tabs_delivery-done_generic': 'Delivery Complete',
  'drawer-right-tabs_delivery-done_pharmacy-informal': 'Products delivered',
  'drawer-right-tabs_delivery-started': 'Heading to drop off',
  'drawer-right-tabs_delivery-started_food': 'Heading to drop off',
  'drawer-right-tabs_delivery-started_food-informal': 'Customer on the way',
  'drawer-right-tabs_delivery-started_generic': 'Heading to drop off',
  'drawer-right-tabs_delivery-started_pharmacy-informal': 'Patient on the way',
  'drawer-right-tabs_on-hold': 'On Hold',
  'drawer-right-tabs_on-hold_food': 'Action Required',
  'drawer-right-tabs_on-hold_food-informal': 'Action',
  'drawer-right-tabs_on-hold_generic\r\n': 'Action Required',
  'drawer-right-tabs_on-hold_pharmacy-informal': 'Action',
  'drawer-right-tabs_open': 'Open',
  'drawer-right-tabs_open_food': 'Locating Driver',
  'drawer-right-tabs_open_food-informal': 'Locating',
  'drawer-right-tabs_open_generic': 'Locating Driver',
  'drawer-right-tabs_open_pharmacy-informal': 'Locating',
  'drawer-right-tabs_pickup-arrived': 'At pickup',
  'drawer-right-tabs_pickup-arrived_food': 'Collection Arrival',
  'drawer-right-tabs_pickup-arrived_food-informal': 'Restaurant arrival',
  'drawer-right-tabs_pickup-arrived_generic': 'Collection Arrival',
  'drawer-right-tabs_pickup-arrived_pharmacy-informal': 'Pharmacy Arrival',
  'drawer-right-tabs_pickup-done': 'Pickup Done',
  'drawer-right-tabs_pickup-done_food': 'Collection Complete',
  'drawer-right-tabs_pickup-done_food-informal': 'Food collected',
  'drawer-right-tabs_pickup-done_generic': 'Collection Complete',
  'drawer-right-tabs_pickup-done_pharmacy-informal': 'Products Collected',
  'drawer-right-tabs_pickup-started': 'Pickup Started',
  'drawer-right-tabs_pickup-started_food': 'Collection Started',
  'drawer-right-tabs_pickup-started_food-informal': 'Restaurant on the way',
  'drawer-right-tabs_pickup-started_generic': 'Collection Started',
  'drawer-right-tabs_pickup-started_pharmacy-informal': 'Pharmacy on the way',
  'drawer-right-title_alerts': 'Alerts',
  'drawer-right-title_orders': 'Orders',
  'drawer-right-title_orders-status': 'Order status',
  'drawer-right-title_no_warning': 'No warning available',
  'drawer-right_all': 'All',
  'drawer-right_attached-in': 'Parcel onboard',
  'drawer-right_clocked-in': 'On Shift',
  'drawer-right_drivers': 'Drivers',
  'drawer-right_filter-drivers-by': 'Filter drivers by',
  'drawer-right_filtered': 'Filtered',
  'drawer-right_orders': 'Orders',
  'drawer-right_shifted-drivers': 'Scheduled',
  'drawer-right_total-drivers': 'Active Drivers',
  'drawer-right_doing-orders': 'Doing orders',
  drawer_cities: 'Cities',
  drawer_companies: 'Companies',
  drawer_customers: 'Customers',
  drawer_exceptions: 'Exceptions',
  'drawer_exceptions-group': 'Groups',
  'drawer_exceptions-list': 'List',
  'drawer_market-sectors': 'Market Sectors',
  drawer_orders: 'Orders',
  drawer_places: 'Sites',
  drawer_routes: 'Routes',
  drawer_shifts: 'Shifts',
  drawer_tickets: 'Tickets',
  drawer_users: 'Users',
  drawer_map: 'Map View',
  'drawer_users-dispatchers': 'Dispatchers',
  'drawer_users-drivers': 'Drivers',
  'drawer_users-observers': 'Observers',
  'drivers_clocked-in': 'On-shift',
  'drivers_clocked-out': 'Late',
  'drivers_clocked-scheduled': 'Scheduled',
  'drivers_no-shifted': 'Not scheduled now',
  error_login: 'Login Error',
  events_messages_message: 'Message',
  events_messages_order: 'Order',
  events_messages_sentAt: 'Sent at',
  'exception-form-floating-label_description': 'Description',
  'exception-form-floating-label_name': 'Name *',
  'exception-form-floating-label_type': 'Type',
  'exception-form-hint_description': 'Description',
  'exception-form-hint_name': 'Name',
  'exception-form-hint_type': 'Type',
  'exception-group-form-floating-label_description': 'Description *',
  'exception-group-form-floating-label_name': 'Name',
  'exception-group-form-hint_description': 'Description',
  'exception-group-form-hint_name': 'Name',
  'exception-group-tabs_assign': 'Assign',
  'exception-group-tabs_create': 'Create',
  'exception-group-tabs_info': 'Info',
  'exception-group-th_description': 'Description',
  'exception-group-th_name': 'Name',
  'exception-group-title_edit-exception-group': 'Edit Exception Group',
  'exception-group-title_exceptions': 'Exceptions',
  'exception-group-title_exceptions-groups': 'Exceptions Groups',
  'exception-group-title_exceptions-including-in': 'Exceptions included in',
  'exception-group-title_group-information': 'Group information',
  'exception-group-title_group_description': 'Group Description',
  'exception-group-title_new-exception': 'Create New Exception Group',
  'exception-th_description': 'Description',
  'exception-th_name': 'Name',
  'exception-th_type': 'Type',
  'exception-title_edit-exception': 'Edit Exception',
  'exception-title_exception-information': 'Exception information',
  'exception-title_exceptions': 'Exceptions',
  'exception-title_new-exception': 'Create New Exception',
  'exception-type_badge': 'badge',
  'exception-type_bag': 'bag',
  'exception-type_box': 'box',
  'exception-type_jacket': 'jacket',
  'exception-type_shirt': 'shirt',
  'exception-type_training': 'training',
  'exception-view_description': 'Description',
  'exception-view_type': 'Type',
  'form-floating-label_company': 'Company',
  'form-floating-label_country': 'Country',
  'form-floating-label_email': 'Email',
  'form-floating-label_password': 'Password',
  'form-hint_email': 'Email',
  'form-hint_password': 'Password',
  'geo-warning-address_not_found': 'Address not found',
  'geo-warning-cannot_infer_post_code': 'Postcode is missing',
  'geo-warning-low_accuracy_coords': 'Coordinates are imprecise',
  'geo-warning-no_payload_for_geo': 'Not enough information',
  'geo-warning-postcode_conflict': 'Postcode conflict',
  'geo-warning-unresolved_coords': 'Could not resolve the coordinates',
  geowarnings_warnings: 'Warnings',
  'job-title_edit': 'Edit',
  'job-title_proof-of-collection': ' Proof of Collection',
  'job-title_proof-of-delivery': 'Proof of delivery',
  'job_sent-at': 'Sent at',
  'job_signature-empty': 'No signature',
  language_de: 'DE',
  language_en: 'EN',
  language_es: 'ES',
  language_fr: 'FR',
  language_it: 'IT',
  'language_pt-br': 'PT-BR',
  loading_loading: 'Loading',
  'location-button_select': 'Select',
  'location-floating-label_filter-by-country': 'Filter by country',
  'location-floating-label_search-location': 'Search location',
  'location-form-floating-label_location': 'Location *',
  'location-form-hint_location': 'Location',
  'location-hint_filter-by-country': 'Filter by country',
  'location-hint_search-location': 'Search location',
  'location-table-th_class': 'Class',
  'location-table-th_code': 'Code',
  'location-table-th_country': 'Country',
  'location-table-th_name': 'Name',
  'login-message_session-expired': 'Session expired, please log in again',
  'market-sectors-form-floating-label_name': 'Name',
  'market-sectors-form-hint_name': 'Name',
  'market-sectors-th_name': 'Name',
  'market-sectors-title_edit-market-sector': 'Edit Market sector',
  'market-sectors-title_market-sector-information': 'Market sector information',
  'market-sectors-title_market-sectors': 'Market Sectors',
  'market-sectors-title_new-market-sector': 'Create New Market sector',
  max_dispatch_iterations: 'Max dispatch iterations',
  'modal-order-change-waypoint-title': 'Change waypoint',
  'modal-reset_order_error': 'Resetting order error',
  'modal-reset_order-option_automatic': 'Automatic dispatch',
  'modal-reset_order-option_manual': 'Manual dispatch',
  'modal-reset_order_option_pre-orders': 'Order moved to pre-orders',
  'modal-reset_order-description':
    'You are going to reset the current order.  \nPlease, select how do you want to dispatch this order after reset it or cancel the reset operation.',
  'modal-reset_order-title': 'Reset Order',
  'modal-update-pickup-ask-for-confirmation-different-post-code':
    'The given postal code does not match with the fixed one.',
  'modal-update-pickup-ask-for-confirmation-far-away':
    'The distance between the current address and the address provided originally is',
  'modal-update-pickup-ask-for-confirmation-same-address': 'The address has not been changed.',
  'modal-update-pickup-ask-for-confirmation-title': 'Do you want to proceed?',
  'modal-order-route-ask-qr-code': 'Ask the driver to scan QR codes to create stops, when doing the pickup',
  'msg-th_date-time': 'Date',
  'msg-th_msg': 'Message',
  'msg-th_order_assigned_to': 'Assigned to',
  'msg-th_order_id': 'Order ID',
  'msg-th_order_status': 'Order status',
  'msg-th_sent_by': 'Sent by',
  'my-areas': 'My Service Areas',
  'navbar-menu_log-out': 'Log out',
  'navbar-menu_my-profile': 'My profile',
  'navbar-menu_settings': 'Settings',
  'navbar-tooltip_notifications': 'Notifications',
  'notifications-all': 'All notifications',
  'notifications-bamboo': 'Bamboo',
  'notifications-button_view-all': 'View All',
  'notifications-critical': 'Critical issues',
  'notifications-info': 'Info',
  'notifications-success': 'Success',
  'notifications-th_alert': 'Alert',
  'notifications-th_assigned-to': 'Assigned to',
  'notifications-th_created-at': 'Created at',
  'notifications-th_description': 'Description',
  'notifications-th_driver': 'Driver',
  'notifications-th_message': 'Message',
  'notifications-th_order': 'Order',
  'notifications-th_sent-at': 'Sent at',
  'notifications-th_status': 'Status',
  'notifications-th-marketplace': 'Marketplace',
  'notifications-warnings': 'Warnings',
  'notifications_already-assigned': 'Already assigned',
  notifications_attend: 'Attend',
  'notifications_see-all': 'See all notifications',
  'notifications_view-order': 'View Order',
  'order-status-archived': 'Archived',
  'order-status-archived_food': 'Archived',
  'order-status-archived_food-informal': 'Archived',
  'order-status-archived_generic': 'Archived',
  'order-status-archived_pharmacy-informal': 'Archived',
  'order-status-archived_driver-activity': 'Archived',
  'order-status-bar-done': 'Done',
  'order-status-bar-on_course': 'On course',
  'order-status-bar_completed': 'Completed',
  'order-status-bar_on-route': 'On route',
  'order-status-bar_pending': 'Pending',
  'order-status-delivery_arrived': 'Delivery arrived',
  'order-status-delivery_arrived_food': 'Delivery Arrival',
  'order-status-delivery_arrived_food-informal': 'Customer arrival',
  'order-status-delivery_arrived_generic': 'Delivery Arrival',
  'order-status-delivery_arrived_pharmacy-informal': 'Patient arrival',
  'order-status-delivery_arrived_driver-activity': 'Delivery Arrived',
  'order-status-delivery_done': 'Delivery done',
  'order-status-delivery_done_food': 'Delivery Complete',
  'order-status-delivery_done_food-informal': 'Food Delivered',
  'order-status-delivery_done_generic': 'Delivery Complete',
  'order-status-delivery_done_pharmacy-informal': 'Products delivered',
  'order-status-delivery_done_driver-activity': 'Delivery Done',
  'order-status-delivery_started': 'Heading to drop off',
  'order-status-delivery_started_food': 'Heading to drop off',
  'order-status-delivery_started_food-informal': 'Customer on the way',
  'order-status-delivery_started_generic': 'Heading to drop off',
  'order-status-delivery_started_pharmacy-informal': 'Patient on the way',
  'order-status-delivery_started_driver-activity': 'Delivery Started',
  'order-status-on_hold': 'On hold',
  'order-status-on_hold_food': 'Action Required',
  'order-status-on_hold_food-informal': 'Action',
  'order-status-on_hold_generic': 'Action Required',
  'order-status-on_hold_pharmacy-informal': 'Action',
  'order-status-order_arrived': 'Order Arrived',
  'order-status-order_arrived_food': 'Order Arrived',
  'order-status-order_arrived_food-informal': 'Order Arrived',
  'order-status-order_arrived_generic': 'Order Arrived',
  'order-status-order_arrived_pharmacy-informal': 'Order Arrived',
  'order-status-order_arrived_driver-activity': 'Order Arrived',
  'order-status-order_assigned': 'Order assigned',
  'order-status-order_assigned_food': 'Order Accepted',
  'order-status-order_assigned_food-informal': 'Accepted',
  'order-status-order_assigned_generic': 'Order Accepted',
  'order-status-order_assigned_pharmacy-informal': 'Accepted',
  'order-status-order_assigned_driver-activity': 'Order Assigned',
  'order-status-order_cancelled': 'Cancelled',
  'order-status-order_date': 'Date',
  'order-status-order_deadline': 'Deadline',
  'order-status-order_dispatching': 'Dispatching order',
  'order-status-order_dispatching_food': 'Dispatching order',
  'order-status-order_dispatching_food-informal': 'Dispatching order',
  'order-status-order_dispatching_generic': 'Dispatching order',
  'order-status-order_dispatching_pharmacy-informal': 'Dispatching order',
  'order-status-order_eta': 'ETA',
  'order-status-order_time-window': 'Time window',
  'order-status-pickup_arrived': 'At pickup',
  'order-status-pickup_arrived_food': 'Collection Arrival',
  'order-status-pickup_arrived_food-informal': 'Restaurant arrival',
  'order-status-pickup_arrived_generic': 'Collection Arrival',
  'order-status-pickup_arrived_pharmacy-informal': 'Pharmacy Arrival',
  'order-status-pickup_arrived_driver-activity': 'Pickup Arrived',
  'order-status-pickup_done': 'Pickup done',
  'order-status-pickup_done_food': 'Collection Complete',
  'order-status-pickup_done_food-informal': 'Food collected',
  'order-status-pickup_done_generic': 'Collection Complete',
  'order-status-pickup_done_pharmacy-informal': 'Products Collected',
  'order-status-pickup_done_driver-activity': 'Pickup Done',
  'order-status-pickup_started': 'Pickup started',
  'order-status-pickup_started_food': 'Collection Started',
  'order-status-pickup_started_food-informal': 'Restaurant on the way',
  'order-status-pickup_started_generic': 'Collection Started',
  'order-status-pickup_started_pharmacy-informal': 'Pharmacy on the way',
  'order-status-pickup_started_driver-activity': 'Pickup Started',
  'order-status-order-status-queued_driver-activity': 'Order Added',
  'order-status-order_cancelled_driver-activity': 'Order Cancelled',
  'order-tabs_delivery_food': 'Delivery',
  'order-tabs_delivery_generic': 'Delivery',

  'order-item_name': 'Item',
  'order-item_quantity': 'Qty.',
  'order-twilio-call-date': 'Automatic call date',
  'order-twilio-call-message': 'Automatic call message',
  'order-warning_distance': 'Distance limit',
  'orderDetail-error-loading-current-order': 'Order not found',
  'OrderDetailMap-delivery-place': 'Delivery Place',
  'OrderDetailMap-driver': 'Driver',
  'OrderDetailMap-pickup-place': 'Pickup Place',
  'orders-actions_cancel-confirm': 'Please confirm',
  'orders-actions_completed-confirm': 'Please confirm',
  'orders-actions_mark-as-cancelled': 'Mark as cancelled',
  'orders-actions_duplicate-order': 'Split order',
  'orders-success_duplicate_message': 'ORDER SPLIT',
  'orders-failure_duplicate_message': 'ERROR IN SPLITING ORDER',
  'orders-actions_create_duplicate': 'Create split',
  orders_question_duplicate_order: 'Who to assign the new split(duplicate)?',
  orders_confirmation_duplicate_order: 'This will add a new stop at the end of the route.Are you sure?',
  'order-detail_edit_company_price_title': 'Edit company price',
  'order-detail_edit_driver_price_title': 'Edit driver price',
  'order-detail_edit_company_button_title': 'Submit',
  'order-price_update_success': 'Price updated',
  'order-price_update_failure': 'Error in price update',
  'orders-actions_mark-as-completed': 'Mark as completed',
  'orders-actions_reset': 'Reset Order',
  'orders-actions_reset-confirm': 'Please confirm before reset',
  'orders-assigner-button_assign': 'Assign',
  'orders-assigner-button_filter-by-name': 'Filter by name',
  'orders-assigner-hint_driver-name': 'Driver name',
  'orders-assigner-th_badge': 'Badge',
  'orders-assigner-th_bag': 'Bag',
  'orders-assigner-th_box': 'Box',
  'orders-assigner-th_jaquet': 'Jacket',
  'orders-assigner-th_name': 'Name',
  'orders-assigner-th_shirt': 'Shirt',
  'orders-assigner-th_training': 'Training',
  'orders-assigner_assign-driver': 'Assign driver to order',
  'orders-assigner_no-drivers-assigned': 'No drivers available to this area',
  'orders-basket_final': 'Final',
  'orders-basket_name': 'Name',
  'orders-basket_original': 'Original',
  'orders-basket_quantity': 'Quantity',
  'orders-basket_total-price': 'Total price',
  'orders-button_waypoint': 'Open Waypoint',
  'orders-deadline': 'Must be done before',
  'orders-deadline-text': 'Deadline',
  'orders-deadline-text-list': 'Deadline',
  'orders-detail_completed': 'Completed',
  'orders-detail_created-at': 'Created at',
  'orders-detail_delivery-information': 'Delivery information:',
  'orders-detail_estimated': 'Estimated:',
  'orders-detail_on-route': 'On route',
  'orders-detail_pickup-information': 'Pickup information',
  'orders-driver_assign-driver': 'Assign driver',
  'orders-driver_force-manual-selection': 'FORCE MANUAL SELECTION',
  'orders-driver_invitation-expired': 'Invitation Expired',
  'orders-driver_invitation-sent-waiting': 'INVITATION SENT, WAITING FOR ACCEPTATION ',
  'orders-driver_invitation-timeout': 'Invitation timeout:',
  'orders-driver_unassigned': 'Unassigned',
  'orders-eta-text': 'ETA',
  'orders-form-floating-label_contact-name': 'Contact name',
  'orders-form-floating-label_email': 'Email *',
  'orders-form-floating-label_first-name': 'First Name',
  'orders-form-floating-label_last-name': 'Last Name',
  'orders-form-floating-label_mobile': 'Mobile number',
  'orders-form-floating-label_name': 'Name',
  'orders-form-floating-label_phone-number': 'Phone number',
  'orders-form-floating-label_place': 'Place *',
  'orders-form-hint_contact-name': 'Contact name',
  'orders-form-hint_email': 'Email',
  'orders-form-hint_first-name': 'First Name',
  'orders-form-hint_last-name': 'Last Name',
  'orders-form-hint_mobile': 'Mobile number',
  'orders-form-hint_name': 'Name',
  'orders-form-hint_phone-number': 'Phone number',
  'orders-form-hint_place': 'Place',
  'orders-job-form-floating-label_date': 'Date *',
  'orders-job-form-floating-label_time': 'Time *',
  'orders-job-form-hint_date': 'Date',
  'orders-job-form-hint_time': 'Time',
  'orders-job_jobs-end-at': 'Job ends at',
  'orders-job_jobs-starts-at': 'Jobs starts at',
  'orders-label_address-errors': 'Address errors',
  'orders-label_calls': 'Automatic calls',
  'orders-label_order-is-ready': 'Order is ready',
  'orders-map_delivery': 'Delivery',
  'orders-map_distance': 'Distance',
  'orders-map_miles': 'Distance',
  'orders-map_order-ready-for-collection': 'Ready for Collection',
  'orders-map_pickup': 'Pickup',
  'orders-modal_errors': 'Errors',
  'orders-modal_warnings': 'Warnings',
  'orders-notif_ready_at': 'Order open notification at',
  'orders-push_ready_at': 'Orders ready push notification at',
  'orders-ready': 'Order open',
  'orders-ready-ack-at': 'Order ready acknowledged at',
  'orders-ready-at': 'Order ready at',
  'orders-ready_for_pickup': 'Order ready for pickup',
  'orders-search-form-btn': 'Search',
  'orders-search-form-floating-label_name': 'Search by id or place name',
  'orders-search-form-hint_name': 'Search by id or place name',
  'orders-status_accepted': 'Accepted',
  'orders-status_arrived-delivery': 'Arrived Delivery',
  'orders-status_arrived-delivery_current': 'Arrived Delivery',
  'orders-status_arrived-delivery_food': 'Delivery Arrival',
  'orders-status_arrived-delivery_food-informal': 'Customer arrival',
  'orders-status_arrived-delivery_generic': 'Delivery Arrival',
  'orders-status_arrived-delivery_pharmacy-informal': 'Patient arrival',
  'orders-status_arrived-pickup': 'At pickup',
  'orders-status_arrived-pickup_current': 'Arrived Pickup',
  'orders-status_arrived-pickup_food': 'Collection Arrival',
  'orders-status_arrived-pickup_food-informal': 'Restaurant arrival',
  'orders-status_arrived-pickup_generic': 'Collection Arrival',
  'orders-status_arrived-pickup_pharmacy-informal': 'Pharmacy Arrival',
  'orders-status_assigned': 'Assigned',
  'orders-status_assigned_current': 'Assigned',
  'orders-status_assigned_food': 'Order Accepted',
  'orders-status_assigned_food-informal': 'Accepted',
  'orders-status_assigned_generic': 'Order Accepted',
  'orders-status_assigned_pharmacy-informal': 'Accepted',
  'orders-status_done-delivery': 'Done Delivery',
  'orders-status_done-delivery_current': 'Done Delivery',
  'orders-status_done-delivery_food': 'Delivery Complete',
  'orders-status_done-delivery_food-informal': 'Food Delivered',
  'orders-status_done-delivery_generic': 'Delivery Complete',
  'orders-status_done-delivery_pharmacy-informal': 'Products delivered',
  'orders-status_done-pickup': 'Done Pickup',
  'orders-status_done-pickup_current': 'Done Pickup',
  'orders-status_done-pickup_food': 'Collection Complete',
  'orders-status_done-pickup_food-informal': 'Food collected',
  'orders-status_done-pickup_generic': 'Collection Complete',
  'orders-status_done-pickup_pharmacy-informal': 'Products Collected',
  'orders-status_hour': 'Hour',
  'orders-status_open': 'Open',
  'orders-status_open_current': 'Open',
  'orders-status_open_food': 'Locating Driver',
  'orders-status_open_food-informal': 'Locating',
  'orders-status_open_generic': 'Locating Driver',
  'orders-status_open_pharmacy-informal': 'Locating',
  'orders-status_start-delivery': 'Start Delivery',
  'orders-status_start-delivery_current': 'Start Delivery',
  'orders-status_start-delivery_food': 'Heading to drop off',
  'orders-status_start-delivery_food-informal': 'Customer on the way',
  'orders-status_start-delivery_generic': 'Heading to drop off',
  'orders-status_start-delivery_pharmacy-informal': 'Patient on the way',
  'orders-status_start-pickup': 'Start Pickup',
  'orders-status_start-pickup_current': 'Start Pickup',
  'orders-status_start-pickup_food': 'Collection Started',
  'orders-status_start-pickup_food-informal': 'Restaurant on the way',
  'orders-status_start-pickup_generic': 'Collection Started',
  'orders-status_start-pickup_pharmacy-informal': 'Pharmacy on the way',
  'orders-status_status': 'Status',
  'orders-status_tbs': 'TBS*',
  'orders-status_time-between-status': '* Time between status',
  'orders-status_time-per-event': '** Time per Event',
  'orders-status_total-time': 'Total Time',
  'orders-status_tpe': 'TPE**',
  'orders-tabs_arrived': 'Arrived',
  'orders-tabs_arrived_food': 'Arrival',
  'orders-tabs_arrived_food-informal': 'Arrival',
  'orders-tabs_arrived_generic': 'Arrival',
  'orders-tabs_arrived_pharmacy-informal': 'Arrival',
  'orders-tabs_assigned': 'Assigned',
  'orders-tabs_assigned_current': 'Assigned',
  'orders-tabs_assigned_food': 'Order Accepted',
  'orders-tabs_assigned_food-informal': 'Accepted',
  'orders-tabs_assigned_generic': 'Order Accepted',
  'orders-tabs_assigned_pharmacy-informal': 'Accepted',
  'orders-tabs_basket': 'Basket',
  'orders-tabs_cancelled': 'Cancelled',
  'orders-tabs_cancelled_food': 'Cancelled',
  'orders-tabs_cancelled_food-informal': 'Cancelled',
  'orders-tabs_cancelled_generic': 'Cancelled',
  'orders-tabs_cancelled_pharmacy-informal': 'Cancelled',
  'orders-tabs_d': 'D. ',
  'orders-tabs_delivery': 'Delivery ',
  'orders-tabs_delivery-arrived': 'Delivery Arrived',
  'orders-tabs_delivery-arrived_current': 'Delivery Arrived',
  'orders-tabs_delivery-arrived_food': 'Delivery Arrival',
  'orders-tabs_delivery-arrived_food-informal': 'Customer arrival',
  'orders-tabs_delivery-arrived_generic': 'Delivery Arrival',
  'orders-tabs_delivery-arrived_pharmacy-informal': 'Patient arrival',
  'orders-tabs_delivery-done': 'Delivery Done',
  'orders-tabs_delivery-done_current': 'Delivery Done',
  'orders-tabs_delivery-done_food': 'Delivery Complete',
  'orders-tabs_delivery-done_food-informal': 'Food Delivered',
  'orders-tabs_delivery-done_generic': 'Delivery Complete',
  'orders-tabs_delivery-done_pharmacy-informal': 'Products delivered',
  'orders-tabs_delivery-started': 'Heading to drop of',
  'orders-tabs_delivery-started_current': 'Heading to drop off',
  'orders-tabs_delivery-started_food': 'Heading to drop off',
  'orders-tabs_delivery-started_food-informal': 'Customer on the way',
  'orders-tabs_delivery-started_generic': 'Heading to drop off',
  'orders-tabs_delivery-started_pharmacy-informal': 'Patient on the way',
  'orders-tabs_delivery_food': 'Delivery',
  'orders-tabs_delivery_food-informal': 'Customer',
  'orders-tabs_delivery_generic': 'Delivery',
  'orders-tabs_delivery_pharmacy-informal': 'Patient',
  'orders-tabs_done': 'Done',
  'orders-tabs_done _food': 'Complete',
  'orders-tabs_done _generic': 'Complete',
  'orders-tabs_done-delivery _food-informal': 'Delivered',
  'orders-tabs_done-delivery_food': 'Complete',
  'orders-tabs_done-delivery_food-informal': 'Delivered',
  'orders-tabs_done-delivery_generic': 'Complete',
  'orders-tabs_done-delivery_pharmacy-informal': 'Delivered',
  'orders-tabs_done-pickup_food': 'Complete',
  'orders-tabs_done-pickup_food-informal': 'Collected',
  'orders-tabs_done-pickup_generic': 'Complete',
  'orders-tabs_done-pickup_pharmacy-informal': 'Collected',
  'orders-tabs_driver': 'Driver',
  'orders-tabs_driver-deadline': 'Deadline',
  'orders-tabs_driver-drops': 'Drops',
  'orders-tabs_driver-eta': 'ETA',
  'orders-tabs_driver-gps': 'GPS',
  'orders-tabs_driver-gps-off': 'OFF',
  'orders-tabs_driver-gps-on': 'ON',
  'orders-tabs_driver-job-route': 'Job route',
  'orders-tabs_driver-orders': 'Orders',
  'orders-tabs_driver-shifts': 'Shift',
  'orders-tabs_log': 'Log',
  'orders-tabs_messages': 'MSG',
  'orders-tabs_on-hold': 'On Hold',
  'orders-tabs_on-hold_current': 'On Hold',
  'orders-tabs_on-hold_food': 'Action Required',
  'orders-tabs_on-hold_food-informal': 'Action',
  'orders-tabs_on-hold_generic': 'Action Required',
  'orders-tabs_on-hold_pharmacy-informal': 'Action',
  'orders-tabs_open': 'Open',
  'orders-tabs_open_current': 'Open',
  'orders-tabs_open_food': 'Locating Driver',
  'orders-tabs_open_food-informal': 'Locating',
  'orders-tabs_open_generic': 'Locating Driver',
  'orders-tabs_open_pharmacy-informal': 'Locating',
  'orders-tabs_p': 'P. ',
  'orders-tabs_pickup': 'Pickup ',
  'orders-tabs_pickup-arrived': 'At pickup',
  'orders-tabs_pickup-arrived_current': 'At pickup',
  'orders-tabs_pickup-arrived_food': 'Collection Arrival',
  'orders-tabs_pickup-arrived_food-informal': 'Restaurant arrival',
  'orders-tabs_pickup-arrived_generic': 'Collection Arrival',
  'orders-tabs_pickup-arrived_pharmacy-informal': 'Pharmacy Arrival',
  'orders-tabs_pickup-done': 'Pickup Done',
  'orders-tabs_pickup-done_current': 'Pickup Done',
  'orders-tabs_pickup-done_food': 'Collection Complete',
  'orders-tabs_pickup-done_food-informal': 'Food collected',
  'orders-tabs_pickup-done_generic': 'Collection Complete',
  'orders-tabs_pickup-done_pharmacy-informal': 'Products Collected',
  'orders-tabs_pickup-started': 'Pickup Started',
  'orders-tabs_pickup-started_current': 'Pickup Started',
  'orders-tabs_pickup-started_food': 'Collection Started',
  'orders-tabs_pickup-started_food-informal': 'Restaurant on the way',
  'orders-tabs_pickup-started_generic': 'Collection Started',
  'orders-tabs_pickup-started_pharmacy-informal': 'Pharmacy on the way',
  'orders-tabs_pickup_food': 'Collection',
  'orders-tabs_pickup_food-informal': 'Restaurant',
  'orders-tabs_pickup_generic': 'Collection',
  'orders-tabs_pickup_pharmacy-informal': 'Pharmacy',
  'orders-tabs_positions': 'Positions',
  'orders-tabs_search': 'Search',
  'orders-tabs_started': 'Started',
  'orders-tabs_started_food': 'Started',
  'orders-tabs_started_food-informal': 'on the way',
  'orders-tabs_started_generic': 'Started',
  'orders-tabs_started_pharmacy-informal': 'on the way',
  'orders-tabs_status': 'Status',
  'orders-tabs_timeline': 'Timeline',
  'orders-tabs_waypoint': 'Waypoint',
  'orders-th_delay': 'Delay',
  'orders-th_delivery-at': 'Delivery at',
  'orders-th_deliver-at': 'Deliver at',
  'orders-th_dispatch_at': 'Dispatch at',
  'orders-th_distance': 'Distance',
  'orders-th_driver': 'Driver',
  'orders-th_id-order': 'Order Details',
  'orders-th_open-time': 'Open Time',
  'orders-th_pickup-at': 'Pickup at',
  'orders-th_time': 'Time',
  'orders-th_marketplace': 'Mkt',
  'orders-th_waypoint': 'WP',
  'orders-th_pickup': 'Pickup',
  'orders-th_delivery': 'Delivery',
  'orders-th_date': 'Date',
  orders_cash: 'Cash',
  'orders-time-window-text-list': 'Time Window',
  'orders-time_expected-delivery-at': 'Expected delivery at',
  'orders-time_expected-pickup-at': 'Expected pickup at',
  'orders-time_paid-at': 'Paid at',
  'orders-title_address': 'Address',
  'orders-title_address-line1': 'Address',
  'orders-title_address-line2': 'Address number',
  'orders-title_contact-name': 'Contact name',
  'orders-title_customer-information': 'Customer information',
  'orders-title_edit-order': 'Edit Order',
  'orders-title_email': 'Email',
  'orders-title_mobile-phone-number': 'Mobile number',
  'orders-title_name': 'Name',
  'orders-title_new-order': 'Create New Order',
  'orders-title_order-information': 'Order information',
  'orders-title_phone-number': 'Phone Number',
  'orders-title_place-information': 'Site information',
  'orders-title_postal-code': 'Postal code',
  'orders_history-title': 'Orders history',
  orders_late: 'Late',
  'orders_on-time': 'On Time',
  'order_go-to-driver': 'View Driver',
  'outes-road-th-driver': 'Driver',
  'page-title_countries': 'Countries',
  'page-title_dashboard': 'Dashboard',
  'page-title_edit-city-information': 'Edit city information',
  'page-title_forgot-your-password': 'Forgot your Password',
  'page-title_login': 'Login',
  'page-title_map': 'Map',
  'page-title_new-order': 'New Order',
  pagination_first: 'First',
  pagination_last: 'Last',
  'payment-mode-form-floating-label_name': 'Name',
  'payment-mode-form-hint_name': 'Name',
  'payment-mode-th_name': 'Name',
  'payment-mode-title_edit-payment-mode': 'Edit Payment Modes',
  'payment-mode-title_new-payment-mode': 'Create New Payment Mode',
  'payment-mode-title_payment-modes': 'Payment Modes',
  'places-form-floating-label_address-line1': 'Address line 1',
  'places-form-floating-label_address-line2': 'Additional address information (flat, door number…)',
  'places-form-floating-label_contact-name': 'Contact name',
  'places-form-floating-label_dispatcher_policy': 'Dispatcher policy',
  'places-form-floating-label_email': 'Email',
  'places-form-floating-label_external_id': 'External ID',
  'places-form-floating-label_mobile': 'Mobile number',
  'places-form-floating-label_company': 'Company',
  'places-form-floating-label_name': 'Name',
  'places-form-floating-label_phone-number': 'Phone number',
  'places-form-floating-label_pick-address': 'Pick address',
  'places-form-floating-label_postal-code': 'Postal code',
  'places-form-hint_contact-name': 'Contact name',
  'places-form-hint_email': 'Email',
  'places-form-hint_external_id': 'External ID',
  'places-form-hint_mobile': 'Mobile number',
  'places-form-hint_name': 'Name',
  'places-form-hint_phone-number': 'Phone number',
  'places-form-hint_postal-code': 'Postal code',
  'places-form_brasil': 'Brasil',
  'places-form_dispatcher-multi': 'Multi',
  'places-form_dispatcher-single': 'Single',
  'places-form_england': 'England',
  'places-form_españa': 'España',
  'places-form_select_one': 'Select one',
  'places-map_no_location': 'No location available...',
  'places-qr_code-disable': 'Disable QRCode',
  'places-qr_code-enable': 'Enable QRCode',
  'places-qr_code-none': 'No QRCodes',
  'places-qr_code-title': 'QRCodes',
  'places-th_dispatcher_policy': 'Dispatcher policy',
  'places-th_email': 'Email',
  'places-th_external_id': 'External ID',
  'places-th_name': 'Name',
  'places-th_company': 'Company',
  'places-th_qrcode': 'QR Code',
  'places-th_custom_settings': 'Custom Settings',
  'places-th_warehouse': 'Is Warehouse',
  'places-title_address': 'Address',
  'places-title_contact': 'Contact',
  'places-title_dispatcher_policy': 'Dispatcher policy',
  'places-title_edit-delivery-place': 'Edit Delivery Place',
  'places-title_edit-pickup-place': 'Edit Pickup Place',
  'places-title_edit-place': 'Edit Site',
  'places-title_new-place': 'Create New Site',
  'places-title_place-contact-information': 'Site Contact information',
  'places-title_place-external_id': 'External ID',
  'places-title_place-information': 'Site information',
  'places-title_places': 'Sites',
  'places_qrcode-none': 'Off',
  'places_qrcode-yes': 'On',
  'places_warehouse-yes': 'Yes',
  'places_warehouse-no': 'No',
  'places-enable_warehouse': 'Enable Warehouse',
  'places-disable_warehouse': 'Disable Warehouse',
  'places-site_warehouse': 'This Site is a Warehouse',
  'places-enter_warehouse_name': 'Enter Warehouse name',
  'places-enter_warehouse_placeholder': 'Warehouse 1',
  'places-warehouse_creation_error': 'Error while creating warehouse',
  'places-warehouse_creation_success': 'Warehouse is created',
  'places-place_deletion_success': 'Place deleted',
  'places-place_deletion_error': 'Error deleting place',
  'places-place_update_success': 'Place updated',
  'places-place_update_error': 'Error updating place',
  'create-status-dispatcher-information': 'Create the status change',
  'create-status-dispatcher-information-status': 'Update order status to:',
  'create-status-dispatcher-information-update': 'Update the status change with the data',
  'edit-job-constraints': 'Edit:',
  'modal-merge-warehouse-title': 'Merge warehouse',
  'modal-merge-warehouse-from': 'From',
  'modal-merge-warehouse-into': 'Into',
  'force-status-update': 'Force status change',
  'create-status-dispatcher-information-confirmation': 'Are you sure you want to change the order',
  'create-status-dispatcher-information-confirmation-error': 'Could not updated order status',
  'create-status-dispatcher-information-loaded': 'Status is loading...',
  'create-status-dispatcher-information-to-status': 'to status ',
  'create-status-dispatcher_order_status': 'Order Status',
  'create-status-dispatcher_position': 'Position Driver',
  'create-status-dispatcher_pop_signature': 'POC Picture Signature',
  'create-status-dispatcher_pod_signature': 'POD Picture Signature',
  'create-status-dispatcher_pop_picture_generic': 'POC Picture',
  'create-status-dispatcher_pod_picture_generic': 'POD Picture',
  'create-status-dispatcher_text': 'Signatory Name',
  pod_barcode: 'Barcode',
  pod_picture_generic: 'Picture',
  pod_signature: 'Signature',
  pod_text: 'Text',
  poc_picture_generic: 'Picture',
  poc_signature: 'Signature',
  poc_text: 'Text',
  'position-picker-button_cancel': 'Cancel',
  'position-picker-button_select-this-position': 'Select this position',
  'position-picker-dialog_select-location': 'Select a location',
  'position-picker-form-floating-label_address-line1': 'Address *',
  'position-picker-form-floating-label_address-line2': 'Address number *',
  'position-picker-form-floating-label_search-location': 'Search location',
  'position-picker-form-hint_address-line1': 'Address',
  'position-picker-form-hint_address-line2': 'Address number',
  'position-picker-form-hint_search-location': 'Search location',
  p_started: 'P. STARTED',
  p_arrived: 'P. ARRIVED',
  p_done: 'P. DONE',
  d_started: 'D. STARTED',
  d_arrived: 'D. ARRIVED',
  d_done: 'D. DONE',
  'pre-orders-title': 'Pre Orders',
  Rejected: 'Rejected',
  'route-queue-th_id': 'Order ID',
  'route-queue-th_remaining-time': 'Time to start',
  'routes-assign': 'Manual assign',
  'routes-cancelled': 'Cancelled',
  'routes-completed': 'Completed',
  'routes-completed-th-completed-delivered': 'Completed/total',
  'routes-completed-th-driver': 'Driver',
  'routes-completed-th-not-delivered': 'Orders not del.',
  'routes-completed-th-partial-delivered': 'Partial del.',
  'routes-completed-th-pdr': 'PDR',
  'routes-completed-th-removed-parcels': 'Removed P.',
  'routes-completed-th-time-window': 'Time Window',
  'routes-completed-th-total-time': 'Total time',
  'routes-completed-th-warehouse': 'Warehouse',
  'routes-completed-th_route-id': 'Route ID',
  'route-change-order-popup-title': 'Change routes order',
  'historical-routes-time': 'Time',
  'routes-completed-th-creation-date': 'Creation date',
  'historical-routes-id': 'ID',
  'historical-routes-plus_route': '+ Routes',
  'historical-routes-driver': 'Driver',
  'historical-routes-attempts': 'Attempts',
  'historical-routes-route': 'Route',
  'routes-drivers': 'Drivers',
  'routes-on-route': 'OOR',
  'routes-picking': 'UTR',
  'routes-picking-orders': 'Orders',
  'routes-picking-parcels': 'Parcels',
  'routes-pickup-th-alerts': 'Alerts',
  'routes-pickup-th-driver': 'Driver',
  'routes-pickup-th-progress': 'Progress',
  'routes-pickup-th-remaining-time': 'Time to start',
  'routes-pickup-th-removed': 'Removed',
  'routes-pickup-th-warehouse': 'Warehouse',
  'routes-pickup-th_route-id': 'Route ID',
  'routes-queue': 'Queue',
  'routes-road-th-alerts': 'Partial',
  'routes-road-th-driver': 'Driver',
  'routes-road-th-progress': 'Progress',
  'routes-road-th-removed': 'Not delivered',
  'routes-road-th-time-window': 'Time window',
  'routes-road-th-warehouse': 'Warehouse',
  'routes-road-th_route-id': 'Route ID',
  'routes-title_routes': 'Routes',
  routes_tabs_cancelled: 'Cancelled',
  routes_tabs_completed: 'Completed',
  'routes_tabs_driver-assignment': 'Driver Assignment',
  routes_tabs_drivers: 'Drivers',
  'routes_tabs_on-queue': 'Order On Queue',
  routes_tabs_oor: 'OOR(Out On Route)',
  routes_tabs_utr: 'UTR(Under the Roof )',
  'Scheduled now at': 'Scheduled now at',
  'route-driver-assign-modal-assigning': 'Assigning...',
  'route-driver-assign-modal-assign': 'Assign',
  'route-detail_th_type': 'Type',
  'route-detail_th_status': 'Status',
  'route-detail_th_address': 'Address',
  'route-detail_th_notes': 'Notes',
  'route-detail_th_parcels': 'Parcels',
  'route-detail_th_distance': 'Distance',
  'route-detail_action_saving': 'Saving...',
  'route-detail_action_saved': 'Saved successfully',
  'route-detail_action_save': 'Save',
  'route-detail_action_cancel': 'Cancel',
  'route-detail_action_edit_run': 'Edit run',
  'route-detail_stop_start': 'Start',
  'route-detail_stop_end': 'End',
  search_search: 'Search',
  'setting-mw_send_status_delivery_arrived-description':
    'Send notification to Middleware when status changes to Delivery Arrived',
  'setting-mw_send_status_delivery_arrived-title': 'MW Send Status Delivery Arrived',
  'settings-broadcast_radius-choose': ' ',
  'settings-broadcast_radius-description':
    'Radius (in meters) to find available drivers when in broadcast selection mode',
  'settings-broadcast_radius-title': 'Broadcast radius',
  'settings-cost_per_drop_distance_cost-choose': ' ',
  'settings-cost_per_drop_distance_cost-description':
    'Cost per distance to add to the fixed cost per drop when exceeding the fixed max distance (in distance units and currency units of the company)',
  'settings-cost_per_drop_distance_cost-title': 'Cost per drop distance cost',
  'settings-cost_per_drop_fixed_cost-choose': ' ',
  'settings-cost_per_drop_fixed_cost-description': 'Fixed cost per drop (in cents of the company currency)',
  'settings-cost_per_drop_fixed_cost-title': 'Cost per drop',
  'settings-cost_per_drop_fixed_max_distance-choose': ' ',
  'settings-cost_per_drop_fixed_max_distance-description':
    'Max distance included in the fixed cost per drop (in distance units of the company)',
  'settings-cost_per_drop_fixed_max_distance-title': 'Cost per drop max distance',
  'settings-currency_symbol-choose': ' ',
  'settings-currency_symbol-description': 'To be displayed in web and app',
  'settings-currency_symbol-title': 'Currency symbol',
  'settings-driver_assignment_strategy-description': 'How to assign a driver to an order',
  'settings-driver_assignment_strategy-option-direct': 'Direct',
  'settings-driver_assignment_strategy-option-invite': 'Invite',
  'settings-driver_assignment_strategy-title': 'Driver assignment strategy',
  'settings-driver_auto_delivery_arrived_radius-choose': ' ',
  'settings-driver_auto_delivery_arrived_radius-description': 'Meters',
  'settings-driver_auto_delivery_arrived_radius-title': 'Auto delivery-arrived radius',
  'settings-driver_auto_geo_event_threshold-choose': ' ',
  'settings-driver_auto_geo_event_threshold-description': 'Minutes',
  'settings-driver_auto_geo_event_threshold-title': 'Automatic status change threshold (minutes)',
  'settings-driver_auto_pickup_arrived_radius-choose': '  ',
  'settings-driver_auto_pickup_arrived_radius-description': 'Meters',
  'settings-driver_auto_pickup_arrived_radius-title': 'Auto pickup-arrived radius',
  'settings-driver_selection_strategy-choose': ' ',
  'settings-driver_selection_strategy-description': 'Driver selection strategy',
  'settings-driver_selection_strategy-option-radius_broadcast': 'radius_broadcast',
  'settings-driver_selection_strategy-option-v1_ranking': 'v1_ranking',
  'settings-driver_selection_strategy-option-v2_ranking': 'v2_ranking',
  'settings-driver_selection_strategy-option-way_point_broadcast': 'way_point_broadcast',
  'settings-driver_selection_strategy-title': 'How to select the available drivers to execute an order',
  'settings-fe_delivery_eta_type-option-deadline': 'Deadline',
  'settings-fe_delivery_eta_type-option-time_window': 'Time Window',
  'settings-fe_delivery_eta_type-title': 'Delivery ETA type',
  'settings-fe_distance_unit-description': 'Distance unit to be displayed in web and mobile applications',
  'settings-fe_distance_unit-option-meters': 'Meters',
  'settings-fe_distance_unit-option-miles': 'Miles',
  'settings-fe_distance_unit-title': 'Distance unit',
  'settings-fe_show_preorders_general-description':
    'New option in the main menu where the controllers can view the existing preorders in the system ',
  'settings-fe_show_preorders_general-title': 'Show preorders',
  'settings-fe_warehouse-description':
    'New option in the main menu where the controllers can view warehouses and routes',
  'settings-fe_warehouse-title': 'Show warehouses and routes',
  'delete-warehouse': 'Delete warehouse',
  'delete-warehouse-information': 'Are you sure you want to delete the warehouse',
  'settings-invite_timeout-choose': ' ',
  'settings-invite_timeout-description': 'Time (in seconds) for an invite to expire',
  'settings-invite_timeout-title': 'Invite timeout',
  'settings-max_dispatch_iterations-choose': ' ',
  'settings-max_dispatch_iterations-description': 'Maximum amount of automatic dispatch iterations',
  'settings-max_dispatch_iterations-title': 'Max dispatch iterations',
  'settings-max_invites-choose': ' ',
  'settings-max_invites-description': 'Maximum amount of automatic invites',
  'settings-max_invites-title': 'Max invites',
  'settings-max_orders_per_driver-choose': ' ',
  'settings-max_orders_per_driver-description': 'Max orders per driver',
  'settings-max_orders_per_driver-title': 'Max orders per driver',
  'settings-mock_positions_delivery_arrived-description': 'Mock positions on delivery arrived',
  'settings-mock_positions_delivery_arrived-title': 'Mock positions on delivery arrived',
  'settings-mock_positions_delivery_done-description': 'Mock positions on delivery done',
  'settings-mock_positions_delivery_done-title': 'Mock positions on delivery done',
  'settings-mock_positions_delivery_started-description': 'Mock positions on delivery started',
  'settings-mock_positions_delivery_started-title': 'Mock positions on delivery started',
  'settings-mock_positions_pickup_arrived-description': 'Mock positions on pickup arrived',
  'settings-mock_positions_pickup_arrived-title': 'Mock positions on pickup arrived',
  'settings-mock_positions_pickup_done-description': 'Mock positions on delivery done',
  'settings-mock_positions_pickup_done-title': 'Mock positions on pickup done',
  'settings-mock_position_time-choose': ' ',
  'settings-mock_position_time-description': 'In seconds',
  'settings-mock_position_time-title': 'Time to keep the mocked position',
  'settings-mw_send_eta_updates-description': 'Send notification to Middleware when ETAs got updated',
  'settings-mw_send_eta_updates-title': 'MW Send ETA Updates',
  'settings-mw_send_pob-title': 'MW send POD',
  'settings-mw_send_pod-description': 'Send POD within the notification message to Middleware',
  'settings-mw_send_pod-title': 'MW Send POD',
  'settings-mw_send_status_delivery_arrived-description':
    'Send notification to Middleware when status changes to Delivery Arrived\n',
  'settings-mw_send_status_delivery_arrived-title': 'MW send status delivery arrived',
  'settings-mw_send_status_delivery_done-description':
    'Send notification to Middleware when status changes to Delivery Done',
  'settings-mw_send_status_delivery_done-title': 'MW Send Status Delivery Done',
  'settings-mw_send_status_delivery_started-description':
    'Send notification to Middleware when status changes to Delivery Started',
  'settings-mw_send_status_delivery_started-title': 'MW Send Status Delivery Started',
  'settings-mw_send_status_order_assigned-description':
    'Send notification to Middleware when status changes to Order Assigned',
  'settings-mw_send_status_order_assigned-title': 'MW Send Status Order Assigned',
  'settings-mw_send_status_order_cancelled-description':
    'Send notification to Middleware when status changes to Order Cancelled',
  'settings-mw_send_status_order_cancelled-title': 'MW Send Status Order Cancelled',
  'settings-mw_send_status_pickup_arrived-descripton':
    'Send notification to Middleware when status changes to Pickup Arrived',
  'settings-mw_send_status_pickup_arrived-title': 'MW Send Status Pickup Arrived',
  'settings-mw_send_status_pickup_done-description':
    'Send notification to Middleware when status changes to Pickup Done',
  'settings-mw_send_status_pickup_done-title': 'MW Send Status Pickup Done',
  'settings-mw_send_status_pickup_started-description':
    'Send notification to Middleware when status changes to Pickup Started',
  'settings-mw_send_status_pickup_started-title': 'MW Send Status Pickup Started',
  'settings-no': 'No',
  'settings-yes': 'Yes',
  'settings-option_mandatory': 'Mandatory',
  'settings-option_none': 'None',
  'settings-option_optional': 'Optional',
  'settings-order_periodic_eta_updates-choose': ' ',
  'settings-order_periodic_eta_updates-description':
    'In addition to usual ETA updates on order status changes, perform additional ETA updates every few minutes for each order (value in minutes)',
  'settings-order_periodic_eta_updates-title': 'Periodic ETA updates',
  'settings-order_status_change_geofencing-description': 'Geofence limit for status changes',
  'settings-order_status_change_geofencing-option-disabled': 'Disabled',
  'settings-order_status_change_geofencing-option-mandatory': 'Mandatory',
  'settings-order_status_change_geofencing-option-warning': 'Warning',
  'settings-order_status_change_geofencing-title': 'Geofence limit for status changes',
  'settings-order_status_change_geofencing_radius-choose': ' ',
  'settings-order_status_change_geofencing_radius-description': 'Geofence radius for status changes',
  'settings-order_status_change_geofencing_radius-title': 'Geofence radius for status changes',
  'settings-pod_barcode-description': 'Proof of Delivery Barcode',
  'settings-pod_barcode-option-disabled': 'Disabled',
  'settings-pod_barcode-option-mandatory': 'Mandatory',
  'settings-pod_barcode-option-optional': 'Optional',
  'settings-pod_barcode-title': 'POD Barcode',
  'settings-pod_collect_money-option-disabled': 'Disabled',
  'settings-pod_collect_money-option-mandatory': 'Mandatory',
  'settings-pod_collect_money-option-optional': 'Optional',
  'settings-pod_collect_money-title': 'POD Collect Money',
  'settings-pod_picture_generic-description': 'Proof of Delivery Picture Generic',
  'settings-pod_picture_generic-option-disabled': 'Disabled',
  'settings-pod_picture_generic-option-mandatory': 'Mandatory',
  'settings-pod_picture_generic-option-optional': 'Optional',
  'settings-pod_picture_generic-title': 'POD Picture',
  'settings-pod_signature-description': 'Proof of Delivery Signature',
  'settings-pod_signature-option-disabled': 'Disabled',
  'settings-pod_signature-option-mandatory': 'Mandatory',
  'settings-pod_signature-option-optional': 'Optional',
  'settings-pod_signature-title': 'POD Signature',
  'settings-pod_text-description': 'Proof of Delivery Text',
  'settings-pod_text-option-disabled': 'Disabled',
  'settings-pod_text-option-mandatory': 'Mandatory',
  'settings-pod_text-option-optional': 'Optional',
  'settings-pod_text-title': 'POD Text',
  'settings-poc_barcode-description': 'Proof of Pickup Barcode',
  'settings-poc_barcode-option-disabled': 'Disabled',
  'settings-poc_barcode-option-mandatory': 'Mandatory',
  'settings-poc_barcode-option-optional': 'Optional',
  'settings-poc_barcode-title': 'POB Barcode',
  'settings-poc_picture_generic-description': 'Proof of Pickup Picture Generic',
  'settings-poc_picture_generic-option-disabled': 'disabled',
  'settings-poc_picture_generic-option-mandatory': 'mandatory',
  'settings-poc_picture_generic-option-optional': 'optional',
  'settings-poc_picture_generic-title': 'POB - Generic picture',
  'settings-poc_signature-description': 'Proof of Pickup Signature',
  'settings-poc_signature-option-disabled': 'Disabled',
  'settings-poc_signature-option-mandatory': 'Mandatory',
  'settings-poc_signature-option-optional': 'optional',
  'settings-poc_signature-title': 'POB Signature',
  'settings-poc_text-description': 'Proof of Pickup Text',
  'settings-poc_text-option-disabled': 'Disabled',
  'settings-poc_text-option-mandatory': 'Mandatory',
  'settings-poc_text-option-optional': 'Optional',
  'settings-poc_text-title': 'POB Text',
  'settings-profile_edit': 'Edit profile',
  'settings-profile_your-email': 'Your email',
  'settings-profile_your-name': 'Your name',
  'settings-profile_your-phone': 'Your phone',
  'settings-profile_your-role': 'Your role',
  'settings-support_phone_number-choose': ' ',
  'settings-support_phone_number-description': 'Support phone number',
  'settings-support_phone_number-title': 'Support Phone Number',
  'settings-tab-general': 'Service Provider Settings',
  'settings-tab-profile': 'Settings tab profile',
  'settings-tab-terms-conditions': 'Terms and conditions',
  'settings-title_choose-language': 'Choose a language',
  'settings-title_currency': 'Currency',
  'settings-title_current-currency': 'Current currency',
  'settings-title_current-language': 'Current language',
  'settings-title_delivery-type': 'Type of delivery',
  'settings-title_dispatching-policy': 'Dispatching policy',
  'settings-title_distance': 'Distance',
  'settings-title_distance-type': 'Distance type',
  'settings-title_invitation-time': 'Invitation time',
  'settings-title_invitation-time-text': 'How many seconds have drivers to accept an order?',
  'settings-title_language': 'Language',
  'settings-title_profile': 'Your profile',
  'settings-title_proof-of-collection': 'Proof of collection',
  'settings-title_proof-of-delivery': 'Proof of delivery',
  'settings-title_settings': 'Settings',
  'settings-title_time': 'Time',
  'settings_currency-dollars': 'Dollars',
  'settings_currency-euros': 'Euros',
  'settings_currency-pounds': 'Pounds',
  'settings_dispatching-multi': 'Multi',
  'settings_dispatching-single': 'Single',
  'settings_distance-km': 'Km',
  'settings_distance-miles': 'Miles',
  settings_photo: 'Photo',
  settings_signature: 'Signature',
  'shifts-action_delete-shift': 'Delete Shift',
  'shifts-action_edit-shift': 'Edit Shift',
  'shifts-button_search-driver': 'Search Driver',
  'shifts-form-floating-label_end-date': 'End date *',
  'shifts-form-floating-label_start-date': 'Start date *',
  'shifts-form-floating-label_time': 'Time *',
  'shifts-form-floating-label_assigned': 'Assigned to',
  'shifts-form-floating-label_change_wp': 'Change WP',
  'shifts-form-hint_driver-name': 'Driver name',
  'shifts-form-hint_end-date': 'End date',
  'shifts-form-hint_start-date': 'Start date',
  'shifts-form-hint_time': 'Time',
  'shifts-message_no-drivers-assigned': 'No drivers assigned to this area',
  'shifts-switch_current': 'Current',
  'shifts-th_assigned': 'Assigned',
  'shifts-th_clock': 'Status',
  'shifts-th_location': 'Location',
  'shifts-th_clock-in-out': 'Shift Clock in/out',
  'shifts-th_transport_type': 'Type',
  'shifts-th_current': 'Current',
  'shifts-th_distance': 'Distance',
  'shifts-th_driver': 'Driver',
  'shifts-th_drops': 'Drops',
  'shifts-th_end': 'End',
  'shifts-th_gps': 'GPS',
  'shifts-th_queued': 'Queued',
  'shifts-th_shift': 'Shift',
  'shifts-th_shift-accomplished': 'Shift accomplished',
  'shifts-th_shift_status': 'Shift status',
  'shifts-th_start': 'Start',
  'shifts-th_status': 'Status',
  'shifts-th_transport': 'Transport',
  'shifts-th_actions': 'Actions',
  'shifts-title_new-shift': 'Create new shift',
  'shifts-title_shifts': 'Shifts',
  'shifts-title_update-shift': 'Update shift',
  shifts_off: 'OFF',
  shifts_on: 'ON',
  'shifts-clock-status_in': 'IN',
  'shifts-clock-status_out': 'OUT',
  'tickets-button_attend': 'Attend',
  'tickets-button_view-order': 'View Order',
  'tickets-tab_all-tickets': 'All Tickets',
  'tickets-tab_messages': 'Messages',
  'tickets-tab_my-tickets': 'My Tickets',
  'tickets-tab_orders-on-risk': 'Orders at risk',
  'tickets-th_assigned-to': 'Assigned to',
  'tickets-th_created-at': 'Created at',
  'tickets-th_description': 'Description',
  'tickets_already-assigned': 'Already assigned',
  'user-header_title': 'Users Detail',
  'user-form-floating-label_address-line1': 'Address line 1',
  'user-form-floating-label_address-line2': 'Additional address information (flat, door number…)',
  'user-role_admin': 'Admin',
  'user-role_controller': 'Controller',
  'user-role_manager': 'Manager',
  'user-role_observer': 'Observer',
  'users-button_assign': 'Assign',
  'users-form-floating-label_address-line1': 'Address line 1',
  'users-form-floating-label_address-line2': 'Additional address information (flat, door number…)',
  'users-form-floating-label_country': 'Country',
  'users-form-floating-label_email': 'Email',
  'users-form-floating-label_from': 'From',
  'users-form-floating-label_to': 'To',
  'users-form-floating-label_first-name': 'First Name',
  'users-form-floating-label_last-name': 'Last Name',
  'users-form-floating-label_mobile': 'Mobile number',
  'users-form-floating-label_password': 'Password',
  'users-form-floating-label_password-confirmation': 'Password confirmation *',
  'users-form-floating-label_phone-number': 'Phone number',
  'users-form-floating-label_postal-code': 'Postal code',
  'users-form-floating-label_search-city': 'Search city',
  'users-form-floating-label_service-area': 'Service area',
  'users-form-floating-label_user-id': 'User ID',
  'users-form-floating-label_user-role': 'User role *',
  'users-form-hint_country': 'Country',
  'users-form-hint_email': 'Email',
  'users-form-hint_first-name': 'First Name',
  'users-form-hint_last-name': 'Last Name',
  'users-form-hint_mobile': 'Mobile number',
  'users-form-hint_password': 'Password',
  'users-form-hint_password-confirmation': 'Password confirmation',
  'users-form-hint_phone-number': 'Phone number',
  'users-form-hint_postal-code': 'Postal code',
  'users-form-hint_search-city': 'Search city',
  'users-form-hint_service-area': 'Service area',
  'users-form-hint_user-type': 'User type',
  'users-form-label_is-admin': 'Is admin',
  'users-form-result_updated': 'User updated',
  'users-form-result_update_failed': 'User update failed',
  'users-message_exceptions': 'Exceptions',
  'users-message_exceptions-groups': 'Exceptions Groups',
  'users-message_no-exception-assigned': 'No exceptions assigned to this user',
  'users-message_no-exception-groups-assigned': 'No exception groups assigned to this user',
  'users-message_no-provided-today-exceptions': 'No provided info about current exceptions',
  'users-message_no-service-area-assigned': 'Not assigned to a service area',
  'users-message_no-shifts': 'No shifts',
  'users-message_no-way-point-assigned': 'Not assigned to a way point',
  'users-message_today-exceptions': 'Today exceptions',
  'users-tabs_actions': 'Actions',
  'users-tabs_area': 'Service Area',
  'users-tabs_area-city': 'City',
  'users-tabs_area-current': 'This user is currently assigned to',
  'users-tabs_area-current-selected': 'Current',
  'users-tabs_area_shifts': 'Shifts',
  'users-tabs_companies': 'Companies',
  'users-tabs_roles': 'Roles',
  'users-tabs_delete_permission': 'Delete permission?',
  'cities_create-waypoint': 'Create Waypoint',
  'users-tabs_role': 'Role',
  'users-tabs_company': 'Company',
  'city-wp-form-floating-label_location': 'Waypoint location',
  'users-tabs_edit_role': 'Edit user role:',
  'users-tabs_update_role': 'Update role',
  'users-tabs_create_role': 'Create user role:',
  'users-tabs_assign_role': 'Assign role',
  'users-tabs_add_new_role': 'Add new role',
  'users-tabs_controller': 'Controller',
  'users-tabs_exception-groups': 'Exc Groups',
  'users-tabs_exceptions': 'Exceptions',
  'users-tabs_info': 'Info',
  'users-tabs_manager': 'Manager',
  'users-tabs_password': 'Password',
  'users-tabs_shifts': 'Shifts',
  'users-tabs_today': 'Today',
  'users-tabs_access-log': 'Access Log',
  'users-tabs_warnings': 'Warnings',
  'users-tabs_activity': 'Activity',
  'user-tabs_activity_order_status': 'Order Status',
  'user-tabs_activity_time_left': 'Time left',
  'user-tabs_activity_device': 'Device',
  'user-tabs_activity_no_device': 'No device data',
  'user-tabs_activity_device_model': 'Device Model',
  'user-tabs_activity_device_os_type': 'OS Type',
  'user-tabs_activity_device_os_version': 'OS Version',
  'user-tabs_activity_device_app_version': 'App Version',
  'users-th_admin': 'Admin',
  'users-th_clock': 'Clock Status',
  'users-th_clock-status': 'On Shift',
  'users-th_email': 'Email',
  'users-th_name': 'Name',
  'users-th_service-area': 'Service area',
  'users-th_driver_without_service_area': 'Driver is not assigned to a service area.',
  'users-th_restrict_service_area': 'Restrict access to:',
  'users-th-assign-service-area': 'Assign Service Area',
  'users-th_way-point': 'Waypoint',
  'users-title_address': 'Address',
  'users-title_admin-access': 'Admin access',
  'users-title_edit-password': 'Edit password',
  'users-title_edit-user': 'Edit User',
  'users-title_exceptions': 'Exceptions',
  'users-title_location': 'Location',
  'users-title_new-user': 'Create New User',
  'users-title_password': 'Password',
  'users-title_phone-numbers': 'Phone numbers',
  'users-title_service-area': 'Service Area',
  'users-title_service-area-controller': 'Service Area controller',
  'users-title_service-area-manager': 'Service Area manager',
  'users-title_user-contact-information': 'User contact information',
  'users-title_user-information': 'User information',
  'users-title_user-personal-information': 'User personal information',
  'users-user-status': 'Status',
  users_admin: 'Admin',
  view_waypoint: 'View Waypoint',
  no_waypoint_data: 'No waypoint data',
  'warehouse-th_contact-info': 'Contact info',
  waypoint_modal_header_activity: 'Activity',
  'warehouse-th_company-name': 'Company name',
  'warehouse-th_email': 'Email',
  'warehouse-th_rest-tw': 'Rest of the week TW',
  'warehouse-th_today-tw': 'Today TW',
  'warehouse-th_tomorrow-tw': 'Tomorrow TW',
  'warehouse-th_warehouse-info': 'Warehouse Info',
  'warehouses-form-floating-label_address-line1': 'Address line 1',
  'warehouses-form-floating-label_address-line2': 'Additional address info (flat, door number)',
  'warehouses-form-floating-label_contact_name': 'Contact name',
  'warehouses-form-floating-label_email': 'Email',
  'warehouses-form-floating-label_external_id': 'External ID',
  'warehouses-form-floating-label_mobile': 'Mobile number',
  'warehouses-form-floating-label_name': 'Warehouse name',
  'warehouses-form-floating-label_place_name': 'Place name',
  'warehouses-form-floating-label_phone-number': 'Phone number',
  'warehouses-form-floating-label_pick-address': 'Pick address',
  'warehouses-form-floating-label_pick_address': 'Pick address',
  'warehouses-form-floating-label_postal-code': 'Postal code',
  'warehouses-form-floating-label_number-runs': 'Total number of runs',
  'warehouses-form-link_place': 'Visit linked site',
  'warehouse-result-updated': 'Warehouse updated',
  'warehouse-result-update_error': 'Error on update warehouse',
  'warehouse-result-deleted': 'Warehouse deleted',
  'warehouse-result-delete_error': 'Error on delete warehouse',
  'warehouses-th_address': 'Warehouse',
  'warehouses-th_cancelled': 'Canceled',
  'warehouses-th_completed': 'Completed',
  'warehouses-th_manual-assignment': 'Manual assignment',
  'warehouses-th_n-1st-delivery': '1st del.',
  'warehouses-th_n-last-delivery': 'Last del.',
  'warehouses-th_n-order': 'Orders',
  'warehouses-th_n-parcels': 'Parcels',
  'warehouses-th_n-time-on-the-road': 'Total time',
  'warehouses-th_name': 'Warehouse',
  'warehouses-th_otr': 'OTR',
  'warehouses-th_packed': 'Packed',
  'warehouses-th_parcels': 'Parcels',
  'warehouses-th_preordrers': 'Today preorders',
  'warehouses-th_route-id': 'Route ID',
  'warehouses-th_route-policy': 'Policy',
  'warehouses-th_utr': 'UTR',
  'warehouses-th_warehouse': 'Warehouse',
  'warehouses-title_edit-warehouse': 'Edit Warehouse',
  'warehouses-title_new-warehouse': 'Create New Warehouse',
  'warehouses-title_new-warehouse_instruction':
    'To create a warehouse please create a site, then convert it into a warehouse.',
  'warehouses-title_new-warehouse_click': 'Click',
  'warehouses-title_new-warehouse_click_action': 'here to browse sites',
  'warehouses-title_warehouse-contact-information': 'Contact Info',
  'warehouses-title_warehouse-information': 'Warehouse Info',
  'warehouses-title_warehouses': 'Warehouses',
  'warehouses-select_company': 'Select Company',
  'warehouses-select_company_all': 'All',
  warehouses_tabs_info: 'Info',
  warehouses_tabs_paning: 'Warehouse Planing',
  warehouses_tabs_settings: 'Settings',
  warning_proof_of_collection_required: 'Proof of collection required',
  warning_proof_of_delivery_required: 'Proof of delivery required',
  share_tracking: 'Share tracker',
  view_basket: 'View Basket',
  hide_basket: 'Hide Basket',
  time_arrival: 'Estimated Time arrival',
  time_zone: 'Time zone',
  'map-filter-title_companies': 'Companies',
  'map-filter-title_areas': 'Areas',
  'map-filter-title_contents': 'Contents',
  'map-filter-placeholder_companies': 'All Companies',
  'map-filter-placeholder_service_areas': 'All Service Areas',
  'map-filter-placeholder_waypoints': 'All Waypoints',
  'map-filter-map_legend': 'map legend',
  map_modal_title_map_legend: 'Map Legend',
  map_modal_drivers: 'Drivers',
  map_modal_orders: 'Orders',
  map_view_driver_clocked_in: 'Clocked in',
  map_view_driver_clocked_out: 'Clocked out',
  map_view_driver_available: 'Available',
  map_view_driver_busy: 'Busy',
  map_view_order_on_risk: 'On Risk',
  map_view_order_on_hold: 'On Hold',
  map_view_order_open: 'Open',
  map_view_order_on_course: 'On Course',
  'reports-title': 'Reporting',
  'reports-not_found': 'Sorry, you do not have reports to display.',
  'reports-not_ready': 'Sorry, the report is not ready yet.',
  'browser-not_compatible': 'Sorry, your browser is not compatible with this section.',
  dashboard_tabs_pickup: 'Pickup',
  dashboard_tabs_delivery: 'Delivery',
  dashboard_tabs_drivers: 'Drivers',
  dashboard_tabs_intra_day: 'Intra-day tracking',
  dashboard_tabs_performance: 'Route performance',
  dashboard_tabs_comparison: 'Metrics comparison',
  electric_vehicle: 'Electric Vehicle',
  electric_vehicle_requested: 'Electric Vehicle Requested',
  unsorted_options: 'Unsorted options',
  show_run_orders: 'Show run orders?',
  marketplace: 'Marketplace',
  edit_route_alias: 'Edit Route Alias',
  route_id: 'Route ID',
  alias: 'Alias',
  change_alias: 'Change Alias',
  'order-resolutions-title_order_resolutions': 'Order resolutions',
  'order-resolutions-title_update_order_resolutions': 'Update order resolution',
  'order-resolutions-default_resolution': 'Default',
  'order-resolutions-shared_resolution': 'Shared',
  'order-resolutions-owned_resolution': 'Owned',
  'order-resolutions-no_items': 'No items',
  'order-resolutions-title_new-order_resolution': 'Create New Order Resolution',
  'order-resolutions-form-floating-label_reason': 'Reason',
  'order-resolutions-form-floating-label_type': 'Resolution type',
  'order-resolutions-form-floating-label_default': 'Mark as default',
  'order-resolutions-create-success_message': 'New order resolution created',
  'order-resolutions-create-error_message': 'Error creating new order resolution',
  'order-resolutions-tab-info': 'Info',
  'order-resolutions-tab-companies': 'Companies',
  'order-resolutions-update-success_message': 'Order resolution updated',
  'order-resolutions-update-error_message': 'Error updating order resolution',
  'order-resolutions-delete-success_message': 'Order resolution deleted',
  'order-resolutions-delete-error_message': 'Error deleting order resolution',
  'order-resolutions-th_reason': 'Reason',
  'order-resolutions-th_type': 'Type',
  'order-resolutions-th_owned_by': 'Owned by',
  'order-resolutions-th_default': 'Default',
  places_tabs_info: 'Info',
  places_tabs_qr_code: 'QRCode',
  places_tabs_map: 'Map',
  places_tabs_settings: 'Settings',
  places_tabs_warehouse: 'Warehouse',
  orders_links_live_orders: 'Live orders',
  orders_links_pre_orders: 'Pre orders',
  orders_links_historic_orders: 'Historic',
  routes_links_pre_orders: 'Pre-orders',
  routes_links_assign: 'Assign',
  routes_links_routes: 'Routes',
  intro_tooltip_orders_empty: 'No orders yet',
  intro_tooltip_routes_empty: 'No routes yet',
  intro_tooltip_orders_help_link: 'What to see here?',
  intro_tooltip_routes_help_link: 'What to see here?',
  intro_tooltip_shifts_empty: 'Select a waypoint',
  intro_tooltip_shifts_help_link: 'Need help to create a shift?',
  intro_shifts_modal_way_point: 'Way Point',
  intro_shifts_modal_time_vehicle: 'Time and vehicle',
  intro_tooltip_shifts_title_no_drivers: 'No available drivers',
  intro_tooltip_shifts_title_no_shifts: 'No driver shifts',
  intro_tooltip_shifts_title_no_waypoints: 'No waypoints',
  intro_tooltip_shifts_text_no_drivers: "There's no working drivers at the time",
  intro_tooltip_shifts_action_create_shift: 'Create a shift now',
  intro_tooltip_shifts_action_create_user: 'Create a driver now',
  intro_tooltip_show_all: 'Show all',
  intro_tooltip_hide: 'Hide',
  row_no_items: 'No items',
  user_title: 'User',
  'modal-jobs-orders-type': 'Type:',
  'modal-jobs-orders-subtype': 'Subtype:',
  'modal-jobs-orders-time': 'Time:',
  'modal-jobs-orders-date': 'Date:',
  'modal-jobs-orders-order': 'ORDER',
  'modal-jobs-orders-job': 'JOB',
  'modal-jobs-orders-eta': 'ETA',
  'modal-jobs-orders-idx': 'IDX',
  'modal-jobs-orders-status': 'STATUS',
  'modal-jobs-orders-on-course': 'On Course',
  'modal-jobs-orders-assigned': 'Assigned',
  'modal-jobs-orders-pending': 'Pending',
  'modal-jobs-orders-pickup-arrived': 'Pickup Arrived',
  'close-order-screen': 'Close order screen',
  'side-map': 'Side map',
  'shareable-tracking': 'Shareable tracking',
  'edit-pickup-address': 'Edit pickup address',
  'edit-delivery-address': 'Edit delivery address',
  'proof-of-collection': 'Proof of collection',
  'proof-of-delivery': 'Proof of delivery',
  'waypoint-actions-wipe-app': 'Wipe app',
  'waypoint-actions-clock-out': 'Clock out',
  'waypoint-actions-force-remote-data-wipe': 'Force remote data wipe',
  'waypoint-actions-force-remote-clock-out': 'Force remote clock-out',
  'create-order-modal-stop-create': 'Create Stop',
  'create-order-modal-stop-created': 'New stop created',
  'create-order-modal-order-create': 'Create Order',
  'create-order-modal-order-created': 'New order created',
  'header-button-add-order': 'Add order',
  'header-button-add-route': 'Add route',
  'header-button-add-stop': 'Add stop',
  'device-os_type_ios': 'iOS',
  'device-os_type_android': 'Android',
  'warehouse-dropdown_select': 'Select warehouse',
  'warehouse-dropdown_select_no_items': 'No warehouses',
  generic_error: 'Error',
  'modal-edit-job-note_title': 'Edit note',
  'modal-edit-job-pickup_title': 'Edit pickup',
  'modal-edit-job-delivery_title': 'Edit delivery',
  button_apply: 'Apply',
  'modal-edit-job-note_label': 'Note text',
  'modal-edit-job-note_note_updated': 'Note updated',
  'modal-edit-job-time_time_updated': 'Time window updated',
  'modal-edit-job-time_time_update_failed': 'Time window update updated',
  'modal-edit-job-note_note_update_failed': 'Note update failed',
  'edit-note_add_note': 'Add note',
  'job-detail_note': 'Note',
  'job-detail_eta': 'ETA',
  'delete-parcel-modal_finalise_routes': 'Finalise routes',
  'delete-parcel-modal_cancel_route': 'Cancel Route',
  'delete-parcel-modal_choose_reason': 'Choose a reason to cancel the Route',
  'delete-parcel-modal_do_with_orders': 'What do you want to do with the orders inside this route',
  'delete-parcel-modal_add_additional_info': 'Add additional information',
  'delete-parcel-modal_applying_changes': 'Applying changes',
  'delete-parcel-modal_action_route_canceled': 'Route cancelled',
  'delete-parcel-modal_action_route': 'Route',
  'delete-parcel-modal_action_cancelled': 'cancelled',
  'delete-parcel-modal_action_error': 'Error updating route',
  'delete-parcel-modal_select_reason': 'Select a reason',
  'delete-parcel-modal_click_to_write': 'Click here to write',
  'delete-parcel-modal_orders_leave_open': 'Leave orders open',
  'delete-parcel-modal_orders_mark_complete': 'Mark orders as complete',
  'delete-parcel-modal_orders_cancel': 'Cancel orders',
  'create-order-steps_items': 'Items:',
  'create-order-steps_price': 'Cash:',
  'create-order-custom-reference': 'Custom reference:',
  'create-order-steps_company': 'Company:',
  'create-order-steps_back_button': 'Back',
  'create-order-steps_submit_button': 'Submit',
  'create-order-steps_next_button': 'Next',
  'create-order-steps_link_create_new': 'Create new',
  'create-order-steps_add_new_product_button': 'Add new product +',
  'create-order-steps_select_contact': 'Select contact',
  'create-order-steps_request_pricing_title': 'Pricing',
  'create-order-steps_request_pricing_button': 'Request',
  'create-order-steps_request_company_pricing_price': 'Calculated price(Company):',
  'create-order-steps_request_driver_pricing_price': 'Calculated price(Driver):',
  'create-order-steps_request_transport_type': 'Transport type:',
  'create-order-steps_request_reset_button': 'New request',
  'create-order-steps_modal_title': 'Create Order',
  'create-order-steps_modal_order_created': 'Order created',
  'create-order-steps_modal_order_creation_failed': 'Order creation failed',
  'create-order-steps_modal_pricing_request_failed': 'Cannot display the requested price',
  'create-order-steps_transport_type': 'Transport type:',
  'order-update-message_completed': 'Order completed',
  'order-update-message_update_error': 'Order update error',
  'order-update-message_cancelled': 'Order cancelled',
  'create-stop-step_summary_address': 'Address:',
  'create-stop-step_summary_contact': 'Contact:',
  'create-stop-step_summary_notes': 'Notes:',
  'create-stop-step_summary_time': 'Time:',
  'in-risk-button_title': 'In Risk',
  'modal-marketplace-title': 'Order Marketplace',
  'modal-marketplace-text_enable': 'Add order to marketplace?',
  'modal-marketplace-text_disable': 'Remove order from marketplace?',
  'modal-marketplace-button_add': 'Add',
  'modal-marketplace-button_remove': 'Remove',
  'modal-marketplace-result_enabled': 'Marketplace enabled',
  'modal-marketplace-result_disabled': 'Marketplace disabled',
  'modal-marketplace-result_error': 'Error while changing marketplace',
  'order-history_results': 'Results',
  'marketplace-tooltip_enable': 'Enable marketplace',
  'marketplace-tooltip_disable': 'Disable marketplace',
  'driver-tip_view_profile': 'View profile',
  'driver-tip_solo_button_enabled': 'Solo',
  'driver-tip_solo_button_disabled': 'Exit Solo',
  'driver-tip_job_orders': 'Orders',
  'driver-tip_runs': 'Runs',
  'driver-tip_job_marketplace': 'Marketplace',
  'order-tip_view_order': 'View order',
  'map-view_drivers': 'Drivers',
  'map-view_loader': 'Loading...',
  'place-tip_view_details': 'View details',
  'find-driver_no_driver_position': 'No driver location',
  'job-constraints-modal_view_pod': 'View POD',
  'job-constraints-modal_no_constraints': 'No job constraints available',
  'order-info_change_driver': 'Change driver',
  'login-drivers_restriction': 'Drivers are not allowed to access the application',
  'login-invalid_credentials': 'Invalid login or password',
  'order-not_paid': 'Not paid',
  'order-paid_on': 'Paid on'
}
