import { combineReducers } from 'redux';

import countries from 'Modules/Countries/reducers';
import warehouses from 'Modules/Warehouses/reducers';
import locations from 'Modules/Locations/reducers';

import mapFilterReducer from 'Pages/Admin/MapView/data/reducers';

import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  countries,
  form: formReducer,
  locations,
  mapFilterReducer,
  warehouses,
});

export default rootReducer;
