import React from 'react';
//@ts-ignore
import CircularProgress from 'material-ui/CircularProgress';

interface ILoadingProps {
  isShown?: boolean;
  size?:  number,
  thickness?:  number,
  style?: {[x:string]:any},
  
}

const Loading: React.FC<ILoadingProps> = ({ isShown = true, size = 80, thickness = 5, style ={} , ...props}: ILoadingProps) => {
 
  const prop = {size, thickness, style, ...props}
  return isShown && <CircularProgress {...prop} />;
};



export default Loading;
