const defaultSpacing = {
  unit: 8
}

const defaultZIndex = {
  mobileStepper: 1000,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
}

const defaultShape = {
  borderRadius: 4
}

const breakpointsKeys = ['xs', 'sm', 'md', 'lg', 'xl']

const defaultShadows = [
  'none',
  '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
  '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
  '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
  '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
  '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
  '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
  '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
  '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
  '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
  '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
  '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
  '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
  '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
  '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
]

const transitionEasings = {
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
}

const transitionDurations = {
  shortest: 150,
  shorter: 200,
  short: 250,
  standard: 300,
  complex: 375,
  enteringScreen: 225,
  leavingScreen: 195
}

const formatMs = (milliseconds: number) => `${Math.round(milliseconds)}ms`

const transitions = {
  easing: transitionEasings,
  duration: transitionDurations,
  create: (props = ['all'], options: { delay?: 0; easing?: string; duration?: number }) => {
    const {
      delay = 0,
      easing: easingOption = transitionEasings.easeInOut,
      duration: durationOption = transitionDurations.standard
    } = options

    return (Array.isArray(props) ? props : [props])
      .map(
        (animatedProp) =>
          `${animatedProp} ${
            typeof durationOption === 'string' ? durationOption : formatMs(durationOption)
          } ${easingOption} ${typeof delay === 'string' ? delay : formatMs(delay)}`
      )
      .join(',')
  },
  getAutoHeightDuration(height: number) {
    if (!height) {
      return 0
    }

    const constant = height / 36

    return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10)
  }
}

function createPalette(palette: { [key: string]: { [key: string]: string | number } }) {
  const {
    common = {
      black: '#000',
      white: '#fff'
    },
    primary = {
      light: '#72c05d',
      main: '#72c05d',
      dark: '#72c05d',
      contrastText: '#fff'
    },
    secondary = {
      light: '#535859',
      main: '#535859',
      dark: '#535859',
      contrastText: '#fff'
    },
    success = {
      light: '#3eb398',
      main: '#3eb398',
      dark: '#3eb398',
      contrastText: '#fff'
    },
    info = {
      light: '#2d495f',
      main: '#2d495f',
      dark: '#2d495f',
      contrastText: '#fff'
    },
    warning = {
      light: '#f7a22e',
      main: '#f7a22e',
      dark: '#f7a22e',
      contrastText: '#fff'
    },
    danger = {
      light: '#e55454',
      main: '#e55454',
      dark: '#e55454',
      contrastText: '#fff'
    },
    text = {
      primary: 'rgba(0, 0, 0, 0.87)', // #535859
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    action = {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    },
    background = {
      paper: '#fff',
      default: '#f6f6f6'
    },
    border = {
      main: '#DEE0E5'
    },
    hover = {
      dropdown: '#b2d4fd'
    },
    ...other
  } = palette

  return {
    common,
    primary,
    secondary,
    success,
    info,
    warning,
    danger,
    text,
    action,
    background,
    border,
    hover,
    ...other
  }
}

function createBreakpoints(breakpoints: {
  [x: string]: any
  values?: { xs: number; sm: number; md: number; lg: number; xl: number }
  unit?: 'px'
  step?: 5
}) {
  const {
    values = {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    },
    unit = 'px',
    step = 5,
    ...other
  } = breakpoints

  const up = (key: string) => {
    const value = typeof values[key] === 'number' ? values[key] : key
    return `@media (min-width:${value}${unit})`
  }

  const down = (key: string) => {
    const endIndex = breakpointsKeys.indexOf(key) + 1
    const upperbound = values[breakpointsKeys[endIndex]]

    if (endIndex === breakpointsKeys.length) {
      // xl down applies to all sizes
      return up('xs')
    }

    const value: any = typeof upperbound === 'number' && endIndex > 0 ? upperbound : key

    return `@media (max-width:${value - step / 100}${unit})`
  }

  const between = (start: string, end: string) => {
    const endIndex = breakpointsKeys.indexOf(end) + 1

    if (endIndex === breakpointsKeys.length) {
      return up(start)
    }

    return (
      `@media (min-width:${values[start]}${unit}) and ` +
      `(max-width:${values[breakpointsKeys[endIndex]] - step / 100}${unit})`
    )
  }

  const only = (key: string) => between(key, key)

  const width = (key: string | number) => values[key]

  return {
    keys: breakpointsKeys,
    values,
    up,
    down,
    between,
    only,
    width,
    ...other
  }
}

function createTypography(
  palette: {
    [key: string]: { [key: string]: string | number }
  },
  typography: {
    [key: string]: { [key: string]: string | number }
  }
) {
  const {
    fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize = 14,
    fontWeightLight = 300,
    fontWeightRegular = 400,
    fontWeightMedium = 500,
    ...other
  } = typography

  return {
    fontFamily,
    fontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    ...other
  }
}

export default (options: { [x: string]: { [key: string]: string | number | any } }) => {
  const {
    palette: paletteInput = {},
    typography: typographyInput = {},
    breakpoints: breakpointsInput = {},
    shadows = defaultShadows,
    shape: shapeInput = {},
    zIndex: zIndexInput = {},
    spacing: spacingInput = {},
    ...other
  } = options

  const palette = createPalette(paletteInput)
  const breakpoints = createBreakpoints(breakpointsInput)
  const typography = createTypography(palette, typographyInput)
  const shape = { ...defaultShape, ...shapeInput }
  const zIndex = { ...defaultZIndex, ...zIndexInput }
  const spacing = { ...defaultSpacing, ...spacingInput }

  return {
    palette,
    typography,
    breakpoints,
    shadows,
    shape,
    zIndex,
    spacing,
    transitions,
    ...other
  }
}
