export const updateObject = (
  obj1: { username: string; email?: string },
  obj2: {
    [x: number]: any;
    shouldRefresh?: any;
    searchResults?: any;
    searching?: boolean;
    items?: any;
    showMap?: boolean;
    loading?: boolean;
    error?: any;
    pagination?: any;
    currentItem?: any;
    wpItems?: any;
    isAddMode?: boolean;
    isEditMode?: boolean;
    isViewMode?: boolean;
    showFormErrors?: boolean;
    deleting?: boolean;
    updating?: boolean;
    searchLocations?: any;
    searchText?: any;
    password?: string;
    email?: string;
  }
) => ({
  ...obj1,
  ...obj2,
});

export default updateObject;
