import axios, { AxiosPromise } from 'axios'

import config from 'config'

import { recursive } from 'lib/recursive'
import { ICompany } from 'types/interfaces'
import { ICreatePlace, ICreateUser, JobParams } from './types'

export const manualFlushRoutes = ({
  warehouseId,
  order_ids,
  num_of_runs,
  max_tour_size,
  vehicle_max_distance_mi,
  vehicle_capacity,
  cutoff_time
}) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/warehouses/${warehouseId}/manual_flush`, {
    order_ids,
    num_of_runs,
    max_tour_size,
    vehicle_max_distance_mi,
    vehicle_capacity,
    cutoff_time
  })

export const completePreOrders = ({
  warehouseId,
  order_ids,
  cutoff_time,
  order_resolution_id,
  resolution_additional_info
}) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/warehouses/${warehouseId}/manual_complete`, {
    order_ids,
    cutoff_time,
    order_resolution_id,
    resolution_additional_info
  })

export const cancelPreOrders = ({
  warehouseId,
  order_ids,
  cutoff_time,
  order_resolution_id,
  resolution_additional_info
}) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/warehouses/${warehouseId}/manual_cancel`, {
    order_ids,
    cutoff_time,
    order_resolution_id,
    resolution_additional_info
  })

export const createCompany = (company: { dispatcher_policy_default: { key: any } }) => {
  company.dispatcher_policy_default = (
    typeof company.dispatcher_policy_default === 'string'
      ? company.dispatcher_policy_default
      : company.dispatcher_policy_default.key
  ).toLowerCase()

  return axios.post(`${config.API_URL}${config.VERSION_PATH}/companies`, { company })
}

export const updateCompany = (company: { dispatcher_policy_default: { key: any }; id: any }) => {
  company.dispatcher_policy_default = (
    typeof company.dispatcher_policy_default === 'string'
      ? company.dispatcher_policy_default
      : company.dispatcher_policy_default.key
  ).toLowerCase()

  return axios.put(`${config.API_URL}${config.VERSION_PATH}/companies/${company.id}`, { company })
}

export const deleteCompany = (id: string) => axios.delete(`${config.API_URL}${config.VERSION_PATH}/companies/${id}`)

export const sendEventsToAnOrder = ({ order_id, order_status, job_id }) =>
  axios.post(`${config.API_URL}/api/mobile/v1/orders/${order_id}/events`, {
    event: {
      origin: 'web',
      event_type: 'status_change',
      order_status,
      job_id
    }
  })

export const sendForseInjectStatus = ({ order_id, status }) =>
  axios.post(`${config.API_URL}/api/v2/orders/${order_id}/force_inject_status`, {
    status
  })

export const updatePictureToJobConstrains = (job_constraints: FormData, token: string) =>
  axios.put(`${config.API_URL}/api/mobile/v1/job_constraints`, job_constraints, {
    headers: {
      Authorization: 'Token token=' + token,
      ContentType: 'multipart/form-data'
    }
  })

export const updateJob = (jobId: string, jobParams: JobParams) =>
  axios.put(`${config.API_URL}/api/v2/jobs/${jobId}`, jobParams)

export const createPlace = (place: ICreatePlace): Promise<any> => {
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/places`, { place })
}

export const updatePlace = (place: { dispatcher_policy: { key: any }; id: any }) => {
  place.dispatcher_policy = (
    typeof place.dispatcher_policy === 'string' ? place.dispatcher_policy : place.dispatcher_policy.key
  ).toLowerCase()

  return axios.put(`${config.API_URL}${config.VERSION_PATH}/places/${place.id}`, { place })
}

export const deletePlace = (id: string) => axios.delete(`${config.API_URL}${config.VERSION_PATH}/places/${id}`)

export const enableQRCode = (id: string) =>
  recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/places/${id}/enable_qr_codes`))

export const disableQRCode = (id: string) =>
  recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/places/${id}/disable_qr_codes`))

export const shortURL = async (id: string) => {
  return await axios.post(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/generate_short_url`)
}

export const createCustomer = (customer: { _payment_mode: any; payment_mode: { key: any } }) => {
  customer._payment_mode = (
    typeof customer.payment_mode === 'string' ? customer.payment_mode : customer.payment_mode.key
  ).toLowerCase()

  return axios.post(`${config.API_URL}${config.VERSION_PATH}/customers`, { customer })
}

export const updateCustomer = (customer: { id: any }) => {
  // customer._payment_mode = (typeof customer.payment_mode === 'string'
  //   ? customer.payment_mode
  //   : customer.payment_mode.key
  // ).toLowerCase()

  return axios.put(`${config.API_URL}${config.VERSION_PATH}/customers/${customer.id}`, { customer })
}

export const deleteCustomer = (id: string) => axios.delete(`${config.API_URL}${config.VERSION_PATH}/customers/${id}`)

export const createShift = (shift: {
  user_id: any
  way_point_id: any
  transport_type: any
  starts_at: string
  ends_at: string
  electric_vehicle: any
}) => axios.post(`${config.API_URL}${config.VERSION_PATH}/shifts`, { shift })

export const updateShift = (shift: { _id: any; id: any }) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/shifts/${shift._id || shift.id}`, { shift })

export const deleteShift = (id: string) => axios.delete(`${config.API_URL}${config.VERSION_PATH}/shifts/${id}`)

export const authMe = () => axios.get(`${config.API_URL}${config.VERSION_PATH}/me`)

export const authLogin = (data: any) => axios.post(`${config.API_URL}${config.VERSION_PATH}/user_sessions`, data)

export const restorePassword = (password_recovery: any) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/user_sessions/any/password_recovery`, { password_recovery })

export const resetPassword = (password_reset: any, token: string) =>
  axios.put(
    `${config.API_URL}${config.VERSION_PATH}/user_sessions/any/password_reset`,
    { password_reset },
    {
      headers: {
        Authorization: 'Token token=' + token
      }
    }
  )

export const orderChangeWaypoint = (id: any, way_point_id: string) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/way_point`, { order: { way_point_id } })

export const orderForceManual = (id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/force_manual`)

export const orderForceAutomatic = (id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/force_auto`)

export const orderForceQueued = (id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/force_queued`)

export const orderAssignDriver = (id: any, user_id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}`, {
    order: {
      user_id
    },
    force: true
  })

export const runChangeWaypoint = (run_id: any, way_point_id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/runs/${run_id}/way_point`, { run: { way_point_id } })

export const runAssignDriver = (id: any, user_id: any) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/runs/${id}/invite`, {
    user_id
  })

export const runCancel = (id: any) => axios.put(`${config.API_URL}${config.VERSION_PATH}/runs/${id}/cancel`)

export const runUpdate = (id: any, routeAlias: string) =>
  axios.put(`${config.API_URL}/api/runs/${id}/alias`, {
    route_alias: routeAlias
  })

export const runRemoveOrder = (id: any, orderId: any) =>
  axios.delete(`${config.API_URL}/api/v3/runs/${id}/orders/${orderId}`)

export const runUpdateParcel = (runId: any, reason: any, cancelAction: any, additionalInfo: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/runs/${runId}/cancel`, {
    reason: reason,
    cancel_action: cancelAction,
    additional_info: additionalInfo
  })

export const cancelMultipleRuns = (runIds: any, reason: any, cancelAction: any, additionalInfo: any) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/runs/batch_cancel`, {
    run_ids: runIds,
    reason: reason,
    cancel_action: cancelAction,
    additional_info: additionalInfo
  })

export const companies = (params: any = {}) => {
  params = {
    page: 1,
    per_page: 25,
    keyword: '',
    ...params
  }

  params.keyword = params.keyword !== '' ? params.keyword : null

  return recursive(() => axios.get(`${config.API_URL}/api/v3/companies`, { params }))
}

export const dispatchPreOrder = (id: any) => axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/dispatch`)

export const cancelPreOrder = (id: any, resolution_id?: undefined, additional_info?: undefined) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/cancel`, {
    order_resolution_id: resolution_id,
    resolution_additional_info: additional_info
  })

export const rejectPreOrder = (id: number, resolution_id: any, additional_info: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/reject`, {
    order: { order_resolution_id: resolution_id, resolution_additional_info: additional_info }
  })

export const createUser = (user: ICreateUser, role?: string) => {
  if (role) user.user_type = role
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/users`, { user })
}

export const createRun = (place_id: any, destination_policy: any, pop_route_creation: boolean) => {
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/runs`, {
    place_id,
    destination_policy,
    pop_route_creation
  })
}

export const duplicateOrder = (id: string, source: string, assign_policy: string) => {
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/duplicate`, {
    source,
    assign_policy
  })
}

export const createOrder = ({ companyId }: any, order: any) => {
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/orders`, { company_id: companyId, order })
}

export const updateOrder = (id,
  {price_for_company,
  price_for_driver,
  user_id
  }) => {
  console.log(price_for_company,price_for_driver,user_id, id)
  return axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}`, {price_for_company,
    price_for_driver,
    user_id })
}

export const createStop = ({ runId }: any, order: any) => {
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/stops`, { run_id: runId, order })
}

export const updateUser = (user: { status: string; id: any }) => {
  user.status = typeof user.status === 'string' ? user.status.toLowerCase() : user.status ? 'active' : 'disabled'

  return axios.put(`${config.API_URL}${config.VERSION_PATH}/users/${user.id}`, { user })
}

export const deleteUser = (id: string): AxiosPromise<any> =>
  axios.delete(`${config.API_URL}${config.VERSION_PATH}/users/${id}`)

export const fixRecalculationRoute = (userId: string): AxiosPromise<any> =>
  axios.get(`${config.API_URL}${config.VERSION_PATH}/users/${userId}/reset_route_recalculation`)

export const RemoteDateWipe = (userId: string): AxiosPromise<void> => {
  const data = {
    id: userId
  }
  return axios.get(`${config.API_URL}${config.VERSION_PATH}/users/${userId}/remote_wipe`, { data })
}

export const confirmationReq = (userId: string): AxiosPromise<any> => {
  const data = {
    id: userId
  }
  return axios.get(`${config.API_URL}${config.VERSION_PATH}/users/${userId}/remote_clock_out`, { data })
}

export const runsByUserId = (userId: string) => {
  const params = {
    page: 1,
    per_page: 99999
  }

  return axios
    .all([
      recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/users/${userId}/orders`, { params })),
      recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/users/${userId}/jobs`, { params }))
    ])
    .then((response) => response.map((res) => (res.data.results ? res.data.results : res.data.map(({ _id }) => _id))))
    .then(axios.spread((orders, jobs) => ({ orders, jobs })))
}

export const allTerms = (): Promise<any> =>
  recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/terms_statements`))

export const fetchOneTerm = (id: string): Promise<any> =>
  recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/terms_statements/${id}`))

export const createTerm = (terms_statement: any) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/terms_statements`, { terms_statement })

export const getCompanySettings = (id: any) =>
  recursive(() => axios.get(`${config.API_URL}${config.VERSION_PATH}/companies/${id}/object_settings`))

export const updateSetting = (id: string, value: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/object_settings/${id}`, { object_setting: { value } })

export const updateCityWayPoint = (
  companyId: string,
  values: {
    position?: any[]
    company?: ICompany
    company_id?: string
    deleted_at?: any
    description?: string
    id: any
    name?: string
    _id?: any
  }
) =>
  axios.put(
    `${config.API_URL}${config.VERSION_PATH}/companies/${companyId}/way_points/${values._id || values.id}`,
    values
  )

export const deleteCityWayPoint = (companyId: any, wayPointId: any) =>
  axios.delete(`${config.API_URL}${config.VERSION_PATH}/companies/${companyId}/way_points/${wayPointId}`)

export const createOrderResolution = (company_id: any, order_resolution: { type: { key: any } }) => {
  order_resolution.type = (
    typeof order_resolution.type === 'string' ? order_resolution.type : order_resolution.type.key
  ).toLowerCase()

  return axios.post(`${config.API_URL}${config.VERSION_PATH}/companies/${company_id}/order_resolutions`, {
    order_resolution
  })
}

export const updateOrderResolution = (company_id: any, order_resolution: { type: { key: any }; id: any }) => {
  order_resolution.type = (
    typeof order_resolution.type === 'string' ? order_resolution.type : order_resolution.type.key
  ).toLowerCase()

  return axios.put(
    `${config.API_URL}${config.VERSION_PATH}/companies/${company_id}/order_resolutions/${order_resolution.id}`,
    {
      order_resolution
    }
  )
}

export const deleteOrderResolution = (company_id: any, id: any) =>
  axios.delete(`${config.API_URL}${config.VERSION_PATH}/companies/${company_id}/order_resolutions/${id}`)

export const bambooConflictsAccept = (id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/bamboo/import_conflicts/${id}/accept`)

export const bambooConflictsReject = (id: any) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/bamboo/import_conflicts/${id}/reject`)

export const completeMultipleOrders = (
  data: { id: any; order_resolution_id: any; resolution_additional_info: any }[]
) => axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/any/batch_complete`, { orders: data })

export const cancelMultipleOrders = (data: { id: any; order_resolution_id: any; resolution_additional_info: any }[]) =>
  axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/any/batch_cancel`, { orders: data })

export const createWayPoint = async (companyId: any, values: { name: string; position: any[] }) => {
  const { data } = await axios.post(`${config.API_URL}${config.VERSION_PATH}/companies/${companyId}/way_points`, {
    way_point: values
  })

  return data
}
export const getSupersetToken = () => axios.get(`${config.API_URL}/api/superset/jwt`)

export const warehousesMerge = (currentWarehous: string, targetWarehouse: boolean, deleteWarehouse: boolean) =>
  axios.post(`${config.API_URL}${config.VERSION_PATH}/warehouses/merge`, {
    original_warehouse_id: currentWarehous,
    target_warehouse_id: targetWarehouse,
    delete_original: deleteWarehouse
  })

export const addPermissionToUser = (userId: any, role: any, companies: any[]) => {
  return axios.post(`${config.API_URL}${config.VERSION_PATH}/user_permissions`, {
    user_id: userId,
    role,
    companies
  })
}

export const removePermissionFromUser = (permissionId: any) => {
  return axios.delete(`${config.API_URL}${config.VERSION_PATH}/user_permissions/${permissionId}`)
}

export const enableCustomSettings = (id: any) =>
  axios.get(`${config.API_URL}${config.VERSION_PATH}/places/${id}/enable_custom_settings`)

export const disableCustomSettings = (id: string) =>
  axios.get(`${config.API_URL}${config.VERSION_PATH}/places/${id}/disable_custom_settings`)


export const enableOrderMarketPlace = (id: string) =>
axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/enable_marketplace`)

export const disableOrderMarketPlace = (id: string) =>
axios.put(`${config.API_URL}${config.VERSION_PATH}/orders/${id}/disable_marketplace`)