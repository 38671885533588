import React, { useEffect, useRef } from 'react';
import GoogleAnalytics from 'react-ga';
import config from 'config';

const getTrackingId = (env = config.REACT_APP_ENV) => {
  switch (env) {
    case 'production':
      return 'UA-105100582-5';
    case 'pre':
      return 'UA-105100582-7';
    default:
      return 'UA-105100582-4';
  }
};

interface IProps {
  history: { [key: string]: any };
  location: {
    pathname: string;
    search: string;
    hash: string;
    state: any;
    key: string;
  };
  match: {
    path: string;
    url: string;
    isExact: boolean;
    params: { [key: string]: any };
  };
  staticContext: any;
  [key: string]: any;
}

GoogleAnalytics.initialize(getTrackingId());

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page: string) => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  const HOC = (props: IProps): JSX.Element => {
    const prevProps = useRef(props);

    useEffect(() => {
      const page = props.location.pathname + props.location.search;
      trackPage(page);
    }, []);

    useEffect(() => {
      const currentPage = prevProps.current.location.pathname + prevProps.current.location.search;
      const nextPage = props.location.pathname + props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }, [props, prevProps]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
