import { MapFilterTypes, OrderGroupKeys } from './types'

const initialState = {
  company: null,
  serviceArea: null,
  wayPoint: null,
  drivers: [],
  orders: [],
  selectedOrderGroupNames: [OrderGroupKeys.OPEN],
  activeOrderGroups: [],
  driversFilter: true
}

type ActionType = {
  type: MapFilterTypes
  [key: string]: any
}

const mapFilterReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case MapFilterTypes.SET_COMPANY:
      return {
        ...state,
        company: action.companyId
      }
    case MapFilterTypes.SET_WAY_POINT:
      return {
        ...state,
        wayPoint: action.wayPointId
      }
    case MapFilterTypes.SET_DRIVERS:
      return {
        ...state,
        drivers: action.drivers
      }
    case MapFilterTypes.SET_ORDERS:
      return {
        ...state,
        orders: action.orders
      }
    case MapFilterTypes.SET_ACTIVE_ORDER_GROUP_NAMES:
      return {
        ...state,
        selectedOrderGroupNames: action.selectedOrderGroupNames
      }
    case MapFilterTypes.SET_ACTIVE_ORDER_GROUPS:
      return {
        ...state,
        activeOrderGroups: action.activeOrderGroups
      }
    case MapFilterTypes.SET_DRIVERS_FILTER:
      return {
        ...state,
        driversFilter: action.driversFilter
      }
    default:
      return state
  }
}

export default mapFilterReducer
