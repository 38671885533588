import Analytics from 'analytics';
import fullStoryPlugin from '@analytics/fullstory';

export const analytics = Analytics({
  app: 'delivery-mates-app',
  plugins: [
    fullStoryPlugin({
      org: 'delivery-mates',
    }),
  ],
});
