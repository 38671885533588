import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

import { BrowserRouter, Route } from 'react-router-dom';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider as MuiThemeProviderNew, createMuiTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';

import App from './App/App';
import store from './store';
import { client } from './App/ApolloClient';
import muiTheme from './config/material';

import { AuthContextProvider } from 'hooks/auth';
import { SettingsContextProvider } from 'hooks/settings';
import 'antd/dist/antd.css';
import themePre from './theme/staging';
import themeProd from './theme/staging';
import themeStaging from './theme/staging';
import withTracker from './lib/analytics';
import GoogleTagManager from './lib/tabManager';
import './styles.scss';

GoogleTagManager();

const muiThemeNew = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: cyan[800],
      main: cyan[900],
      dark: teal[900],
    },
    secondary: teal,
  },
});

let theme;

if (process.env.REACT_APP_DISPATCH_API_URL === 'https://api-devel.dmates.io') {
  require('./index-devel.css');
  theme = themeStaging;
} else if (process.env.REACT_APP_DISPATCH_API_URL === 'https://api-pre.dmates.io') {
  require('./index-pre.css');
  theme = themePre;
} else {
  require('./index.css');
  theme = themeProd;
}

const GlobalStyle = createGlobalStyle`
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

ReactDOM.render(
  <ApolloProvider client={client}>
    {/* @ts-ignore */}
    <Provider store={store()}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <MuiThemeProviderNew theme={muiThemeNew}>
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyle />

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <BrowserRouter>
                  <AuthContextProvider>
                    <SettingsContextProvider>
                      <Route
                        path="/"
                        component={withTracker(App, {
                          /* Other params */
                        })}
                      />
                    </SettingsContextProvider>
                  </AuthContextProvider>
                </BrowserRouter>
              </MuiPickersUtilsProvider>
            </>
          </ThemeProvider>
        </MuiThemeProviderNew>
      </MuiThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// registerServiceWorker()
