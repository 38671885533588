import { useEffect } from 'react';

import Rollbar from 'rollbar';

import config from 'config';

import { useAuthContext } from 'hooks/auth';

// import LogRocket from 'logrocket'
// import setupLogRocketReact from 'logrocket-react'

const Tracking = () => {
  const { me } = useAuthContext();

  useEffect(() => {
    if (me && config.REACT_APP_ENV === 'production') {
      // LogRocket.init(config.LOGROCKET_PROJECT)
      // LogRocket.identify(me.email, {
      //   name: `${me.first_name} ${me.last_name}`,
      //   email: me.email,
      // })
      // setupLogRocketReact(LogRocket)

      Rollbar.init({
        accessToken: config.ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: config.ROLLBAR_ENV,
        },
      });

      // RB.configure({
      //   transform: obj => ({
      //     ...obj,
      //     sessionURL: LogRocket.sessionURL,
      //   }),
      // })
    }
  }, []);

  return null;
};

export default Tracking;
